import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import AccountTree from '../../../utils/AccountTree';
import LoaderGraphic from '../../../utils/LoaderGraphic';
import { GrowthRateIputKeys, TotalRevenueAndMonthlyRevPercents, PnlValuesWithBlend, BalanceSheetValuesWithBlend, COGSConverter, PnlValues, BalanceSheetValues, CashFlowValues, Sum, Ratios, AverageMonthVals, PnlValuesForChart } from './ForecastHelpers'
import { monthlyTotalRevenueGRCalc } from '../../report_components/MoneyHelpers';
import MonthlyForecastSheetMaker from './MonthlyForecastSheetMaker';
import ForecastSlider from './ForecastSlider';
import ForecastGrowthRateInput from './ForecastGrowthRateInput';
import ForecastScenario from './ForecastScenario';
import MonthlyForecastCashFlow from './MonthlyForecastCashFlow';
import MonthlyForecastLineChart from './MonthlyForecastLineChart';
import MonthlyForecastKFITable from '../MonthlyForecastKFITable';
import SurveyResponses from '../../SurveyResponses';
import './sticky_div';
import ahoy from 'ahoy.js';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSlidersH, faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons'

const monthsObjBuilder = (fiscalYearStartMonth = 1, reportPeriod = null, startMonth = 0, year = null) => {
  const monthsObj = {
    1: 'JAN',
    2: 'FEB',
    3: 'MAR',
    4: 'APR',
    5: 'MAY',
    6: 'JUN',
    7: 'JUL',
    8: 'AUG',
    9: 'SEP',
    10: 'OCT',
    11: 'NOV',
    12: 'DEC'
  }
  let months = Object.values(monthsObj)

  if (reportPeriod === 'Rolling 12' && fiscalYearStartMonth == 1) {
    let startMonths = months.splice(startMonth)
    let nextMonth = 1

    startMonths.forEach((f, i) => {
      monthsObj[i + 1] = f + ' ' + Number(year)
      nextMonth++
    })

    months.forEach((f, i) => {
      monthsObj[nextMonth] = f + ' ' + Number(year + 1)
      nextMonth++
    })

  } else {
    // Handle Different Fiscal Year Ends
    if (fiscalYearStartMonth !== 1) {

      if (reportPeriod === 'Rolling 12') {
        let startMonths = months.splice(startMonth)
        let nextMonth = 1

        startMonths.forEach((f, i) => {
          monthsObj[i + 1] = f + ' ' + 'FY' + Number(year + 1)
          nextMonth++
        })

        months.forEach((f, i) => {
          monthsObj[nextMonth] = f + ' ' + 'FY' + Number(year + 2)
          nextMonth++
        })

      } else {
        let startMonths = months.splice(fiscalYearStartMonth - 1)
        let fiscalMonths = [...startMonths, ...months]
        fiscalMonths.forEach((f, i) => {
          monthsObj[i + 1] = f
        })
      }
    }
  }

  return monthsObj
}

const MonthlyForecast = (props) => {
  let growthRateSetter = 0;
  let growthRateSetter2 = 0;
  let growthRateSetter3 = 0;
  const [hideChart, setHideChart] = useState(false);
  const [youDataForChart, setYouDataForChart] = useState(props.monthlyRevYou ? Object.values(monthlyTotalRevenueGRCalc({ total_revenue: props.monthlyRevYou })) : [])
  const [peerDataForChart, setPeerDataForChart] = useState(props.monthlyRevPeers ? Object.values(monthlyTotalRevenueGRCalc({ total_revenue: props.monthlyRevPeers })) : [])
  const [loading, isLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [state, setState] = useState({
    baseForecast: props.forecast_year1[0] || {},
    forecastSet: props.forecast_year1 || [],
    month: props.isLiveData ? props.blendMonth - 1 : 0,
    companyCalcId: props.forecast_year1[0].company_calculation_id,
    scenarioOptions: props.scenarios || [],
    growthRateInputs1: props.forecast_year1[0].growth_rate_inputs || {},
    growthRateInputs2: props.forecast_year2[0].growth_rate_inputs || {},
    growthRateInputs3: props.forecast_year3[0].growth_rate_inputs || {},
    growthRate: { value: growthRateSetter, touched: false },
    growthRate2: { value: growthRateSetter2, touched: false },
    growthRate3: { value: growthRateSetter3, touched: false },
    growthRateDisplay: (growthRateSetter * 100).toFixed(2),
    growthRateDisplay2: (growthRateSetter2 * 100).toFixed(2),
    growthRateDisplay3: (growthRateSetter3 * 100).toFixed(2),
    slidersYear1Touched: false,
    slidersYear2Touched: false,
    slidersYear3Touched: false,
    syncLiveDataTouched: false,
    rolling12Touched: false,
    monthsObjY1: monthsObjBuilder(props.fiscalYearStartMonth),
    monthsObjY2: monthsObjBuilder(props.fiscalYearStartMonth),
    monthsObjY3: monthsObjBuilder(props.fiscalYearStartMonth),
    isOpen: true,
    displayPeerValues: true
  });
  const [inputsDisplay, setInputsDisplay] = useState('sliders');
  const [editMultipleMonths, setEditMultipleMonths] = useState(true);
  const [selectedYear, setSelectedYear] = useState('year1')
  const [forecasts, setForecastSets] = useState({
    year1: _.cloneDeep(props.forecast_year1),
    year2: _.cloneDeep(props.forecast_year2),
    year3: _.cloneDeep(props.forecast_year3),
    year1Sum: {},
    year2Sum: {}
  } || {})

  const [orginalForecasts, setOriginalForecasts] = useState({
    year1: _.cloneDeep(props.forecast_year1),
    year2: _.cloneDeep(props.forecast_year2),
    year3: _.cloneDeep(props.forecast_year3),
    year1Sum: {},
    year2Sum: {}
  } || {})

  const [straightline, setStraightlineSets] = useState({
    year1: {},
    year2: {},
    year3: {},
    year1Sum: {},
    year2Sum: {}
  } || {})
  const [applyActions, setApplyActions] = useState(false)
  const [actions, setActions] = useState([])

  let month1Year2BalSheet = {}
  let month1Year3BalSheet = {}
  let balanceSheetSet1 = []
  let balanceSheetSet2 = []
  let balanceSheetSet3 = []
  const monthLabels = props.calendarYearEnd ? ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'] : ['Month 1', 'Month 2', 'Month 3', 'Month 4', 'Month 5', 'Month 6', 'Month 7', 'Month 8', 'Month 9', 'Month 10', 'Month 11', 'Month 12']

  useEffect(() => {
    if (props.forecast_year1[0] && props.forecast_year1.length >= 1) {
      // If Straightline has no starting growth rate. Just use total_revenue_growth_rate from company calc of the previous year.
      growthRateSetter = props.forecast_year1[0].starting_growth_rate !== null ? props.forecast_year1[0].starting_growth_rate : props.companyCalc.total_revenue_growth_rate
      growthRateSetter2 = props.forecast_year2[0].starting_growth_rate !== null ? props.forecast_year2[0].starting_growth_rate : props.companyCalc.total_revenue_growth_rate
      growthRateSetter3 = props.forecast_year3[0].starting_growth_rate !== null ? props.forecast_year3[0].starting_growth_rate : props.companyCalc.total_revenue_growth_rate
      let grKeys1 = props.forecast_year1[0].growth_rates ? props.forecast_year1[0].growth_rates : GrowthRateIputKeys(props.forecast_year1[0]['state']['pnl'], growthRateSetter)
      let grKeys2 = props.forecast_year2[0].growth_rates ? props.forecast_year2[0].growth_rates : GrowthRateIputKeys(props.forecast_year1[0]['state']['pnl'], growthRateSetter2)
      let grKeys3 = props.forecast_year3[0].growth_rates ? props.forecast_year3[0].growth_rates : GrowthRateIputKeys(props.forecast_year1[0]['state']['pnl'], growthRateSetter3)

      let ogForecast1 = _.cloneDeep(props.forecast_year1)
      let ogForecast2 = _.cloneDeep(props.forecast_year2)
      let ogForecast3 = _.cloneDeep(props.forecast_year3)
      let forecast1 = _.cloneDeep(props.forecast_year1)
      let forecast2 = _.cloneDeep(props.forecast_year2)
      let forecast3 = _.cloneDeep(props.forecast_year3)
      let finalForecasts
      let forecastStateObj
      let forecastState2Obj
      let forecastState3Obj

      if (actions.length > 0 && applyActions) {
        finalForecasts = actionsApplier(forecast1, forecast2, forecast3, grKeys1, grKeys2, grKeys3, props.blendMonth)
        forecast1 = finalForecasts.year1
        forecast2 = finalForecasts.year2
        forecast3 = finalForecasts.year3
        grKeys1 = finalForecasts.gr1 ? finalForecasts.gr1 : grKeys1
        grKeys2 = finalForecasts.gr2 ? finalForecasts.gr2 : grKeys2
        grKeys3 = finalForecasts.gr3 ? finalForecasts.gr3 : grKeys3
      }

      setState((prevState) => {
        return {
          baseForecast: props.forecast_year1[0] || {},
          month: props.isLiveData ? props.blendMonth - 1 : 0,
          forecastSet: props.forecast_year1,
          companyCalcId: props.forecast_year1[0].company_calculation_id,
          scenarioOptions: props.scenarios || [],
          growthRateInputs1: grKeys1,
          growthRateInputs2: grKeys2,
          growthRateInputs3: grKeys3,
          growthRate: { value: growthRateSetter, touched: false },
          growthRate2: { value: growthRateSetter2, touched: false },
          growthRate3: { value: growthRateSetter3, touched: false },
          growthRateDisplay: (growthRateSetter * 100).toFixed(2),
          growthRateDisplay2: (growthRateSetter2 * 100).toFixed(2),
          growthRateDisplay3: (growthRateSetter3 * 100).toFixed(2),
          slidersYear1Touched: false,
          slidersYear2Touched: false,
          slidersYear3Touched: false,
          syncLiveDataTouched: props.isLiveData,
          rolling12Touched: props.isLiveData ? false : prevState.rolling12Touched,
          monthsObjY1: monthsObjBuilder(props.fiscalYearStartMonth),
          monthsObjY2: monthsObjBuilder(props.fiscalYearStartMonth),
          monthsObjY3: monthsObjBuilder(props.fiscalYearStartMonth),
          isOpen: true,
          displayPeerValues: props.peer_avg_calcs ? true : false
        }
      })


      if (props.scenarioName === 'Straightline') {

        setForecastSets(() => {
          if (props.isLiveData && !applyActions) {
            forecastStateObj = new AverageMonthVals(forecast1, growthRateSetter, props.companyCalc, props.blendMonth)
            for (let i = props.blendMonth; i < 13; i++) {
              if (forecast1[i - 1].scenario_name === 'Straightline') {
                forecast1[i - 1].state['pnl'] = forecastStateObj.forecast['pnl']
              }
            }

            for (let i = 0; i < props.blendMonth - 1; i++) {
              forecast1[i].state.pnl = new COGSConverter(forecast1[i].state.pnl)
            }

            forecastState2Obj = new AverageMonthVals(forecast1, props.companyCalc.total_revenue_growth_rate, forecastStateObj.liveSum)
            for (let forecast of forecast2) {
              if (forecast.scenario_name === 'Straightline') {
                forecast.state = forecastState2Obj.forecast
              }
            }

            forecastState3Obj = new AverageMonthVals(forecast2, props.companyCalc.total_revenue_growth_rate, forecastState2Obj.sum)
            for (let forecast of forecast3) {
              if (forecast.scenario_name === 'Straightline') {
                forecast.state = forecastState3Obj.forecast
              }
            }

            return {
              year1: forecast1,
              year2: forecast2,
              year3: forecast3,
              year1Sum: forecastStateObj.liveSum,
              year2Sum: forecastState2Obj.sum
            }
          } else if (!props.isLiveData && !applyActions) {
            forecastStateObj = new AverageMonthVals(forecast1, growthRateSetter, props.companyCalc)
  
            for (let forecast of forecast1) {
              forecast.state = forecastStateObj.forecast
            }
            forecastState2Obj = new AverageMonthVals(forecast1, props.companyCalc.total_revenue_growth_rate, forecastStateObj.sum)
  
            for (let forecast of forecast2) {
              forecast.state = forecastState2Obj.forecast
            }
            forecastState3Obj = new AverageMonthVals(forecast2, props.companyCalc.total_revenue_growth_rate, forecastState2Obj.sum)
  
            for (let forecast of forecast3) {
              forecast.state = forecastState3Obj.forecast
            }

            return {
              year1: forecast1,
              year2: forecast2,
              year3: forecast3,
              year1Sum: forecastStateObj.sum ,
              year2Sum: forecastState2Obj.sum
            }

          } else {
            let year1Sum = forecastStateObj ? forecastStateObj.sum : {}
            let year2Sum = forecastState2Obj ? forecastState2Obj.sum : {}

            return {
              year1: forecast1,
              year2: forecast2,
              year3: forecast3,
              year1Sum: year1Sum,
              year2Sum: year2Sum
            }
          }
        })

        if (props.isLiveData && !applyActions) {
          setOriginalForecasts(() => {
            let year1Sum = forecastStateObj ? forecastStateObj.liveSum : {}
            let year2Sum = forecastState2Obj ? forecastState2Obj.sum : {}

            return {
              year1: forecast1,
              year2: forecast2,
              year3: forecast3,
              year1Sum: year1Sum,
              year2Sum: year2Sum
            }
          })
        } else {
          setOriginalForecasts(() => {
            let year1Sum = forecastStateObj ? forecastStateObj.sum : {}
            let year2Sum = forecastState2Obj ? forecastState2Obj.sum : {}

            return {
              year1: ogForecast1,
              year2: ogForecast2,
              year3: ogForecast3,
              year1Sum: year1Sum,
              year2Sum: year2Sum
            }
          })

          setStraightlineSets(() => {
            let year1Sum = forecastStateObj ? forecastStateObj.sum : {}
            let year2Sum = forecastState2Obj ? forecastState2Obj.sum : {}

            return {
              year1: ogForecast1,
              year2: ogForecast2,
              year3: ogForecast3,
              year1Sum: year1Sum,
              year2Sum: year2Sum
            }
          })
        }

      } else {

        setForecastSets(() => {
          if (props.isLiveData && !applyActions) {
            forecastStateObj = new AverageMonthVals(forecast1, growthRateSetter, props.companyCalc, props.blendMonth, true)
            for (let i = props.blendMonth; i < 13; i++) {
              if (forecast1[i - 1].scenario_name === 'Straightline') {
                forecast1[i - 1].state = forecastStateObj.forecast
              }
            }

            for (let i = 0; i < props.blendMonth - 1; i++) {
              forecast1[i].state.pnl = new COGSConverter(forecast1[i].state.pnl)
            }

            forecastState2Obj = new AverageMonthVals(forecast1, forecast2[0].starting_growth_rate, forecastStateObj.liveSum)
            for (let forecast of forecast2) {
              forecast.state = forecastState2Obj.forecast
            }

            forecastState3Obj = new AverageMonthVals(forecast2, forecast3[0].starting_growth_rate, forecastState2Obj.sum)
            for (let forecast of forecast3) {
              forecast.state = forecastState3Obj.forecast
            }

            return {
              year1: forecast1,
              year2: forecast2,
              year3: forecast3,
              year1Sum: forecastStateObj.liveSum,
              year2Sum: forecastState2Obj.sum
            }
          } else {
            return {
              year1: forecast1,
              year2: forecast2,
              year3: forecast3,
              year1Sum: {},
              year2Sum: {}
            }
          }
        })

        if (props.isLiveData && !applyActions) {
          setOriginalForecasts(() => {
            let year1Sum = forecastStateObj ? forecastStateObj.liveSum : {}
            let year2Sum = forecastState2Obj ? forecastState2Obj.sum : {}

            return {
              year1: ogForecast1,
              year2: ogForecast2,
              year3: ogForecast3,
              year1Sum: year1Sum,
              year2Sum: year2Sum
            }
          })
        } else {
          setOriginalForecasts(() => {
            return {
              year1: ogForecast1,
              year2: ogForecast2,
              year3: ogForecast3,
              year1Sum: {},
              year2Sum: {}
            }
          })
        }
      }

      isLoading(false)
    } else {
      isLoading(true)
    }
  }, [props.forecast_year1])

  const handleInputsTab = (value) => {
    setInputsDisplay(value)
  }

  $('.slider-inputs').followTo(1000);
  $(`[data-toggle='tooltip']`).tooltip()

  let copy = new AccountTree({
    page: 'forecast',
    tree: { children: props.tree }
  }).copy();

  let pnl = new AccountTree({
    page: 'forecast',
    tree: props.tree['pnl']
  })

  let balance = new AccountTree({
    page: 'forecast',
    tree: props.tree['balance_sheet']
  })

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const closeSurveyModal = () => {
    props.setSurveyModalOpen(false);
  };

  const handleSurveyTab = () => {
    !props.surveyTaken ? props.setSurveyModalOpen(true) : props.setSurveyModalOpen(false)
  }

  const getCurrentState = (forecastSet) => {
    return forecastSet[state.month].state;
  }

  const getCurrentBalanceSheet = () => {
    if (selectedYear === 'year1') {
      return balanceSheetSet1[state.month]
    } else if (selectedYear === 'year2') {
      return balanceSheetSet2[state.month]
    } else {
      return balanceSheetSet3[state.month]
    }
  }

  const setMonth = (month) => {
    setState(prevState => {
      return { ...prevState, month: month }
    });
  }

  //######## SLIDER & INPUTS #####################################################
  const opexParentKeys = ['total_people_costs', 'total_customer_costs', 'total_operations', 'total_operating_expenses_general_& administrative', 'total_operating_expenses_marketing', 'total_operating_expenses_product_development', 'total_operating_expenses_sales']

  const sliderFor = (forecastSet, sheet, growthRate = 0) => {
    let parents = getCurrentState(forecastSet)[sheet].parents
    let treeRows = []
    let valuesTogether = {}
    let parentKeys = Object.keys(parents);

    parentKeys.forEach((p) => {
      if (p !== 'materials_costs') {
        valuesTogether[p] = ['parent', parents[p]]
        let children = getCurrentState(forecastSet)[sheet].children[p]
        let childKeys = Object.keys(children)

        childKeys.forEach((c) => {
          if (c !== p) {
            if (!children[c] && p === 'total_costs') {
              valuesTogether[c] = ['child', 0, p, true]
            } else if (!children[c]) {
              valuesTogether[c] = ['child', 0, p]
            } else if (p === 'total_costs') {
              valuesTogether[c] = ['child', children[c], p, true]
            } else {
              valuesTogether[c] = ['child', children[c], p]
            }
          }
        })
      }
    })

    if (sheet === 'pnl') {
      pnl.forEach((row) => {
        if (inputsDisplay === 'growthRateInputs') {
          if (row.money && row.key === 'total_revenue' || row.money && opexParentKeys.includes(row.parentKey)) {
            let key = row.key
            row.inputType = 'growthRate'
            row.type = valuesTogether[key][0]
            row.cogs = false

            if (valuesTogether[key][0] === 'child') {
              row.parent = valuesTogether[key][2]
            }

            if (selectedYear === 'year1') {
              row.value = state.growthRateInputs1[row.key]
            } else if (selectedYear === 'year2') {
              row.value = state.growthRateInputs2[row.key]
            } else if (selectedYear === 'year3') {
              row.value = state.growthRateInputs3[row.key]
            }

          } else {
            row.type = 'blank'
          }
        } else {
          if (row.money) {
            let key = row.key
            if (valuesTogether[key] && row.copy !== 'Total Cost of Goods Sold') {
              row.type = valuesTogether[key][0]
              row.value = valuesTogether[key][1]

              if (valuesTogether[key][0] === 'child') {
                row.parent = valuesTogether[key][2]
              }
              if (valuesTogether[key][3] || row.copy === 'Cost of Goods Sold') {
                row.cogs = true
              } else {
                row.cogs = false
              }
            } else {
              row.type = 'blank'
            }
          }
        }
        treeRows.push(row)
      })
    }

    if (sheet === 'balance_sheet') {
      balance.forEach((row) => {
        if (row.money || inputsDisplay !== 'growthRateInputs') {
          let key = row.key
          if (valuesTogether[key]) {
            row.type = valuesTogether[key][0]
            row.value = valuesTogether[key][1]
            if (valuesTogether[key][0] === 'child') {
              row.parent = valuesTogether[key][2]
            }
            if (key === 'cash' || key === 'operating_expenses_net_income' || key === 'retained_earnings' || key === 'net_worth') {
              row.type = 'blank'
            }
          } else {
            row.type = 'blank'
          }
          treeRows.push(row)
        }
      })
    }

    return treeRows.map((item, i) => {
      let key = item.key
      let val
      let newVal
      let saasChildTotals = ['Compensation - CX', 'Taxes & Benefits - CX', 'Compensation - G&A', 'Taxes & Benefits - G&A', 'Compensation - Dev', 'Taxes & Benefits - Dev', 'Compensation - Sales', 'Taxes & Benefits - Sales', 'Compensation - Marketing', 'Taxes & Benefits - Marketing']

      if (item.inputType === 'growthRate') {
        return renderGrowthRateInput(sheet, item, i);
      }

      if (item.type === 'blank') {
        return renderBlank(sheet, item, i);
      }

      if (item.type === 'parent') {
        val = key != 'total_costs' ? Math.round(parents[key]) : (parents[key] * 10) / 10;
        newVal = key != 'total_costs' ? (val) * (1 + growthRate) : val
        return renderParentSlider(forecastSet, item, newVal, sheet, i);
      }
      if (item.type === 'child') {
        let val = item.value
        if (key !== 'total_revenue' && key !== 'long_term_debt') {
          let value
          if (item.cogs) {
            value = sheet == 'balance_sheet' ? val : (((val * 10) / 10) * (1 + growthRate));
          } else {
            value = sheet == 'balance_sheet' ? Math.round(val) : Math.round((((val * 10) / 10) * (1 + growthRate)));
          }
          return renderChildSlider(key, value, sheet, item.parent, item.cogs, item, i)
        }
      }
    })
  }

  const renderGrowthRateInput = (sheet, item, i) => {
    let key = item.key
    let value = item.value

    let inputClassNames = item.type === 'parent' ? `slider-wrapper pnl-wrapper` : `child-slider-container pnl-child-container`
    let styles = item.type === 'parent' ? { lineHeight: '1.4' } : {}
    if (item.topline && item.copy !== 'Operating Expenses:') {
      styles['borderTop'] = '1px solid #000000'
    }

    if (item.underline) {
      styles['borderBottom'] = '1px solid #000000'
    }

    if (item.boldedUnderline) {
      styles['borderBottom'] = '3px solid #000000'
    }

    if (item.doubleUnderline) {
      styles['borderBottom'] = '3px double #000000'
    }

    return (
      <div style={styles} key={i} className={inputClassNames}>
        <ForecastGrowthRateInput
          value={item.value}
          title={item.copy}
          padding={item.padding}
          inputsDisplay={inputsDisplay}
          onSubmit={(value) => {
            handleGrowthInputs({
              key, value, item
            })
          }} />
      </div>
    )
  }

  const renderBlank = (sheet, item, i) => {
    let divStyles = { lineHeight: '1.4' }
    let fontStyles = {}
    if (item.bold) {
      fontStyles['fontWeight'] = 'bold'
    } else {
      fontStyles['fontWeight'] = 'normal'
    }
    if (item.topline && item.copy !== 'Operating Expenses:') {
      divStyles['borderTop'] = '1px solid #000000'
    }

    if (item.underline) {
      divStyles['borderBottom'] = '1px solid #000000'
    }

    if (item.boldedUnderline) {
      divStyles['borderBottom'] = '1px solid #000000'
    }

    if (item.doubleUnderline) {
      divStyles['borderBottom'] = '1px double #000000'
    }
    return (
      <div style={divStyles} key={i} className={`slider-wrapper ${sheet}-wrapper`}>
        <h3 style={fontStyles}>{item.padding}{item.copy}</h3>
      </div>
    )
  }

  const renderParentSlider = (forecastSet, item, value, sheet, i) => {
    let key = item.key
    const cogs = key == 'total_costs';
    const category = cogs ? 'pnlParent' : 'balance_sheetParent';
    const balanceSheet = getCurrentBalanceSheet();
    const output = sheet == 'balance_sheet' ? balanceSheet[key].toFixed(0) : null;
    const maxSetter = balanceSheet['total_assets'] / 4
    const config = sheet == 'balance_sheet' ? {
      min: (v) => v > 0 ? v * -1 : 0,
      max: (v) => v == 0 ? Math.round(maxSetter / 1000) * 1000 : Math.abs(v) * 2,
    } : null;

    let styles = { lineHeight: '1.4' }
    if (item.topline && key !== 'long_term_debt') {
      styles['borderTop'] = '1px solid #000000'
    }

    if (item.underline) {
      styles['borderBottom'] = '1px solid #000000'
    }

    if (item.boldedUnderline) {
      styles['borderBottom'] = '3px solid #000000'
    }

    if (item.doubleUnderline) {
      styles['borderBottom'] = '3px double #000000'
    }

    return (
      <div style={styles} key={i} className={`slider-wrapper ${sheet}-wrapper`}>
        <ForecastSlider
          sheet={sheet}
          value={value}
          title={item.copy}
          buc={!item.cogs}
          category={category}
          output={output}
          config={config}
          padding={item.padding}
          inputsDisplay={inputsDisplay}
          onChange={(value) => {
            handleStateChange({
              value, sheet, key
            })
          }} />
      </div>
    )
  }

  // ########### CHILD CODE ################################################

  const renderChildSlider = (key, value, sheet, parent, cog, item, i) => {
    const cogs = cog;
    const category = cogs ? 'pnlChild' : 'balance_sheetChild';
    const balanceSheet = getCurrentBalanceSheet();
    const output = sheet == 'balance_sheet' ? balanceSheet[key] : null;
    const maxSetter = balanceSheet['total_assets'] / 10
    const config = sheet == 'balance_sheet' ? {
      min: (v) => v > 0 ? v * -1 : 0,
      max: (v) => v == 0 ? Math.round(maxSetter / 1000) * 1000 : Math.abs(v) * 2,
    } : null;

    if (copy[key] !== undefined) {
      return (
        <div key={i}>
          <div className={`child-slider-container ${sheet}-child-container`}>
            <ForecastSlider
              sheet={sheet}
              value={value}
              parent={parent}
              buc={!cogs}
              output={output}
              config={config}
              category={category}
              title={copy[key]}
              padding={item.padding}
              inputsDisplay={inputsDisplay}
              onChange={(value) => {
                handleStateChange({
                  value, sheet, key, parent
                })
              }}
            />
          </div>
        </div>
      )
    }
  }

  // ################### STATE HANDLERS ###########################################

  const getBalanceSheetVals = (selectedYear, selectedMonth, f1, f2, f3) => {
    const pnlValueSet3 = [];
    const balValueSet = [];
    const balValueSet2 = []
    const balValueSet3 = [];
    let forecast1 = _.cloneDeep(f1)
    let forecast2 = _.cloneDeep(f2)
    let forecast3 = _.cloneDeep(f3)
    let originalDepreciation
    let balanceSheetBase
    let pnlVals
    let balanceSheetSet1, balanceSheetSet2, balanceSheetSet3

    const y1BalanceSheet = () => {
      balanceSheetBase = new BalanceSheetValues(
        forecast1[0].state.balance_sheet,
        forecast1[0].state.balance_sheet.children.net_worth.operating_expenses_net_income,
        forecast1[0].state.pnl.children.total_other_costs.depreciation_and_amortization
      );

      originalDepreciation = forecast1[0].state.balance_sheet.children.total_fixed_assets.less_accumulated_depreciation

      forecast1.forEach((f, i) => {
        pnlVals = new PnlValues(f, 0, null, false, false)

        const balVals = new BalanceSheetValues(
          forecast1.state.balance_sheet,
          pnlVals.operating_expenses_net_income,
          pnlVals.depreciation_and_amortization,
          balanceSheetBase,
          forecast1.month,
          originalDepreciation,
          null,
          'year1'
        );
        balValueSet.push(balVals);
        balanceSheetBase = balVals;
        balanceSheetSet1 = balValueSet;
      })
    }

    const y2BalanceSheet = () => {
      forecast2.forEach((f, i) => {
        const pnlVals2 = new PnlValues(f, 0, null, false, false);

        const balVals2 = new BalanceSheetValues(
          f.state.balance_sheet,
          pnlVals2.operating_expenses_net_income,
          pnlVals2.depreciation_and_amortization,
          balanceSheetBase,
          f.month,
          originalDepreciation
        );
        balValueSet2.push(balVals2);
        balanceSheetBase = balVals2;
        balanceSheetSet2 = balValueSet2;
      })
    }

    const y3BalanceSheet = () => {
      forecast3.forEach((f, i) => {
        const pnlVals3 = new PnlValues(f, 0, null, false, false);

        const balVals3 = new BalanceSheetValues(
          f.state.balance_sheet,
          pnlVals3.operating_expenses_net_income,
          pnlVals3.depreciation_and_amortization,
          balanceSheetBase,
          f.month,
          originalDepreciation
        );
        balValueSet3.push(balVals3);
        balanceSheetBase = balVals3;
        balanceSheetSet3 = balValueSet3;
      })
    }

    if (selectedYear === 'year1') {
      y1BalanceSheet()
      return balanceSheetSet1[selectedMonth]
    } else if (selectedYear === 'year2') {
      y1BalanceSheet()
      y2BalanceSheet()
      return balanceSheetSet2[selectedMonth]
    } else {
      y1BalanceSheet()
      y2BalanceSheet()
      y3BalanceSheet()
      return balanceSheetSet3[selectedMonth]
    }
  }

  const forecastChanger = (callback, args, f1, f2, f3, g1, g2, g3, blendMonth = 0) => {
    let forecast1 = _.cloneDeep(f1)
    let forecast2 = _.cloneDeep(f2)
    let forecast3 = _.cloneDeep(f3)
    let newForecastsData = null
    let selectedYear = args.selectedYear
    let gr2 = Number(g2['total_revenue']) / 100
    let gr3 = Number(g3['total_revenue']) / 100
    let editMultipleMonths = args.editMultipleMonths
    let startEditMonth = args.selectedMonth

    if (selectedYear === 'year1') {
      const selectedMonth = forecast1[startEditMonth].state
      const balanceSheet = args.sheet === 'pnl' ? null : getBalanceSheetVals(selectedYear, startEditMonth, forecast1, forecast2, forecast3);
      const result = callback(selectedMonth, balanceSheet);
      let forecastStateObj
      let forecastState2Obj
      let forecastState3Obj

      if (editMultipleMonths) {
        startEditMonth = blendMonth && blendMonth - 1 >= startEditMonth ? blendMonth - 1 : startEditMonth
        for (let i = startEditMonth; i < 12; i++) {
          let newState = _.cloneDeep(result)
          newState['pnl']['parents']['total_revenue'] = forecast1[i].state['pnl']['parents']['total_revenue']
          newState['pnl']['children']['total_revenue']['total_revenue'] = forecast1[i].state['pnl']['parents']['total_revenue']
          forecast1[i].state = newState
        }
      } else {
        if (blendMonth - 1 <= startEditMonth) {
          let newState = _.cloneDeep(result)
          newState['pnl']['parents']['total_revenue'] = forecast1[startEditMonth].state['pnl']['parents']['total_revenue']
          newState['pnl']['children']['total_revenue']['total_revenue'] = forecast1[startEditMonth].state['pnl']['parents']['total_revenue']
          forecast1[startEditMonth].state = newState
        }
      }

      forecastStateObj = new AverageMonthVals(forecast1)
      forecastState2Obj = new AverageMonthVals(forecast1, gr2, forecastStateObj.sum)

      for (let forecast of forecast2) {
        let newState = _.cloneDeep(forecastState2Obj.forecast)
        newState['pnl']['parents']['total_revenue'] = forecast.state['pnl']['parents']['total_revenue']
        newState['pnl']['children']['total_revenue']['total_revenue'] = forecast.state['pnl']['parents']['total_revenue']
        forecast.state = newState
      }
      forecastState3Obj = new AverageMonthVals(forecast2, gr3, forecastState2Obj.sum)
      for (let forecast of forecast3) {
        let newState = _.cloneDeep(forecastState3Obj.forecast)
        newState['pnl']['parents']['total_revenue'] = forecast.state['pnl']['parents']['total_revenue']
        newState['pnl']['children']['total_revenue']['total_revenue'] = forecast.state['pnl']['parents']['total_revenue']
        forecast.state = newState
      }

      newForecastsData = {
        year1: forecast1,
        year2: forecast2,
        year3: forecast3
      }
    }

    if (selectedYear === 'year2') {
      const selectedMonth = forecast2[startEditMonth].state
      const balanceSheet = args.sheet === 'pnl' ? null : getBalanceSheetVals(selectedYear, startEditMonth, forecast1, forecast2, forecast3);
      const result = callback(selectedMonth, balanceSheet);
      let forecastState2Obj
      let forecastState3Obj
      let forecast3

      if (editMultipleMonths) {
        for (let i = startEditMonth; i < 12; i++) {
          forecast2[i].state = result
        }
      } else {
        forecast2[startEditMonth].state = result
      }

      forecastState2Obj = new AverageMonthVals(forecast2)
      forecastState3Obj = new AverageMonthVals(forecast2, gr3, forecastState2Obj.sum)

      for (let forecast of forecast3) {
        forecast.state = forecastState3Obj.forecast
      }

      newForecastsData = {
        year1: forecast1,
        year2: forecast2,
        year3: forecast3
      }
    }

    if (selectedYear === 'year3') {
      const selectedMonth = forecast3[startEditMonth].state
      const balanceSheet = args.sheet === 'pnl' ? null : getBalanceSheetVals(selectedYear, startEditMonth, forecast1, forecast2, forecast3);
      const result = callback(selectedMonth, balanceSheet);
      if (editMultipleMonths) {
        for (let i = startEditMonth; i < 12; i++) {
          forecast3[i].state = result
        }
      } else {
        forecast3[startEditMonth].state = result
      }

      newForecastsData = {
        year1: forecast1,
        year2: forecast2,
        year3: forecast3,
      }
    }

    return newForecastsData
  }

  const childMetricChanges = (args, f1, f2, f3, g1, g2, g3, blendMonth = 0) => {
    let details = args.details
    return forecastChanger((prevForecast) => {
      const oldForecast = _.cloneDeep(prevForecast)
      const forecast = oldForecast[args.sheet];

      let oldChildVal = parseFloat(forecast['children'][details.parent][details.key]);
      if (!oldChildVal) {
        oldChildVal = 0.0
      }
      const newChildVal = parseFloat(details.value);
      const newParentVal = parseFloat(forecast.parents[details.parent]) + (newChildVal - oldChildVal);
      forecast.children[details.parent][details.key] = newChildVal;
      forecast.parents[details.parent] = newParentVal;
      return oldForecast;
    }, args, f1, f2, f3, g1, g2, g3, blendMonth)
  }

  const pnlParentMetricChanges = (args, f1, f2, f3, g1, g2, g3, blendMonth = 0) => {
    let details = args.details
    return forecastChanger((prevForecast) => {
      const oldForecast = _.cloneDeep(prevForecast)
      const forecast = oldForecast[args.sheet];
      let value = parseFloat(details.value);
      if (value == 0) value = value + 1;

      const oldParentVal = forecast.parents[details.key];
      forecast.parents[details.key] = value; // after this step props.forecasts changes BAD!!!!!
      let children = forecast.children[details.key] // children = {total_revenue: 96568.2089}
      Object.keys(children || {}).forEach((key) => {
        const ratio = children[key] / oldParentVal;
        forecast.children[details.key][key] = value * ratio;
      })
      return oldForecast;
    }, args, f1, f2, f3, g1, g2, g3, blendMonth)
  }

  const balParentMetricChanges = (args, f1, f2, f3, g1, g2, g3, blendMonth = 0) => {
    let details = args.details
    return forecastChanger((prevForecast, balanceSheet) => {
      const oldForecast = _.cloneDeep(prevForecast)
      const forecast = oldForecast[args.sheet];
      let value = parseFloat(details.value);
      if (value == 0) value = 1;

      forecast.parents[details.key] = value;
      let children = forecast.children[details.key]
      Object.keys(children || {}).forEach((key) => {
        const denominator = balanceSheet[details.key] == 0 ? 1 : balanceSheet[details.key]
        const ratio = balanceSheet[key] / denominator;
        if (key == 'long_term_debt') {
          forecast.children[details.key][key] = value
        } else {
          forecast.children[details.key][key] = value * ratio;
        }
      })
      return oldForecast;
    }, args, f1, f2, f3, g1, g2, g3, blendMonth)
  }

  const applyDollarAndSliderChanges = (args, f1, f2, f3, g1, g2, g3, blendMonth = 0) => {
    let finalResult = null
    if (args.action === 'childChange') {
      finalResult = childMetricChanges(args, f1, f2, f3, g1, g2, g3, blendMonth);
    } else if ((args.action !== 'childChange')) {
      if (args.sheet == 'pnl') {
        finalResult = pnlParentMetricChanges(args, f1, f2, f3, g1, g2, g3, blendMonth);
      } else {
        finalResult = balParentMetricChanges(args, f1, f2, f3, g1, g2, g3, blendMonth);
      }
    }

    return finalResult
  }

  const actionsApplier = (f1, f2, f3, g1, g2, g3, blendMonth = 0) => {
    let finalResult = {}
    let changesResult = null
    let yearPriority = ['year1', 'year2', 'year3']
    let year1Actions = actions.filter(a => a.selectedYear === 'year1')
    let year2Actions = actions.filter(a => a.selectedYear === 'year2')
    let year3Actions = actions.filter(a => a.selectedYear === 'year3')
    let yearsOrderedArr = [...year1Actions, ...year2Actions, ...year3Actions]
    let forecast1 = _.cloneDeep(f1)
    let forecast2 = _.cloneDeep(f2)
    let forecast3 = _.cloneDeep(f3)
    let gr1 = _.cloneDeep(g1)
    let gr2 = _.cloneDeep(g2)
    let gr3 = _.cloneDeep(g3)

    yearsOrderedArr.forEach(a => {
      switch (a.action) {
        case 'totalRevenueGRChange':
        case 'opexGRChange':
          let grKey = a.details.key
          let grVal = a.details.value
          if (a.selectedYear === 'year1') {
            gr1[grKey] = grVal
          }
          if (a.selectedYear === 'year2') {
            gr2[grKey] = grVal
          }
          if (a.selectedYear === 'year3') {
            gr3[grKey] = grVal
          }
          // Save growth rate changes and their affects on the forecasts
          changesResult = applyGrowthRateChanges(a, forecast1, forecast2, forecast3, gr1, gr2, gr3, blendMonth)
          finalResult = { ...finalResult, ...changesResult }
          finalResult.gr1 = gr1
          finalResult.gr2 = gr2
          finalResult.gr3 = gr3
          forecast1 = changesResult.year1
          forecast2 = changesResult.year2
          forecast3 = changesResult.year3
          break;
        case 'childChange':
        case 'parentChange':
          changesResult = applyDollarAndSliderChanges(a, forecast1, forecast2, forecast3, gr1, gr2, gr3, blendMonth)
          finalResult = { ...finalResult, ...changesResult }
          forecast1 = changesResult.year1
          forecast2 = changesResult.year2
          forecast3 = changesResult.year3
          break;
        default:
          break;
      }
    })
    return finalResult
  }

  const rolling12MonthShift = (forecastYear1, forecastYear2) => {
    let startMonth = props.blendMonth - 1
    let nextYear = false

    return forecastYear1.map((m) => {
      m.state = nextYear ? forecastYear2[startMonth].state : forecastYear1[startMonth].state
      startMonth++

      if (startMonth > 11) {
        nextYear = true
        startMonth = 0
      }

      return m
    })
  }

  const rolling12Months = () => {
    if (props.blendMonth) {
      props.turnOffLiveData()

      setState((prevState) => {
        return {
          ...prevState,
          month: 0,
          monthsObjY1: monthsObjBuilder(props.fiscalYearStartMonth, 'Rolling 12', props.blendMonth - 1, props.year + 1),
          rolling12Touched: true
        }
      })

      setForecastSets((prevState) => {
        let forecast1 = _.cloneDeep(forecasts.year1)
        let forecast2 = _.cloneDeep(forecasts.year2)
        let newForecast1 = rolling12MonthShift(forecast1, forecast2)
        let forecastStateObj = new AverageMonthVals(newForecast1)

        return {
          ...prevState,
          year1: newForecast1,
          year1Sum: forecastStateObj.sum,
        }
      })

      setActions((prevState) => {
        return [...prevState, { action: 'rolling12Clicked' }]
      })
    }
  }

  const forecastStatesWithTotalRevenueGR = (forecasts, finalRevenue, monthlyRevenuePercents, blendMonth = false) => {
    let yearValues = []

    let newForecasts = forecasts.map((f, i) => {
      const fState = _.cloneDeep(f.state)
      const newPnl = fState['pnl']
      let value = finalRevenue * monthlyRevenuePercents[i]
      const oldParentVal = newPnl.parents['total_revenue']
      newPnl.parents['total_revenue'] = value

      let children = newPnl.children['total_revenue']
      Object.keys(children || {}).forEach((key) => {
        const ratio = children[key] / oldParentVal;
        newPnl.children['total_revenue'][key] = value * ratio;
      })

      f.state['pnl'] = newPnl

      let pnlVals = blendMonth ? PnlValuesWithBlend(f, blendMonth) : new PnlValues(f)
      yearValues.push(pnlVals)

      return f
    })

    let sum = new Sum(yearValues)

    return { newForecasts, sum }
  }

  const handleTotalRevenueGR = (value) => {
    let forecast1 = _.cloneDeep(forecasts.year1)
    let forecast2 = _.cloneDeep(forecasts.year2)
    let forecast3 = _.cloneDeep(forecasts.year3)
    let year1Revenue = 0
    let year2Revenue = 0
    let year3Revenue = 0
    let year1MonthlyRevPercents = null
    let year2MonthlyRevPercents = null
    let year3MonthlyRevPercents = null

    if (selectedYear === 'year1') {
      let revVals = TotalRevenueAndMonthlyRevPercents(props.companyCalc.total_revenue, forecast1, value, props.blendMonth)
      year1Revenue = revVals.finalRevenue
      year1MonthlyRevPercents = revVals.monthlyRevPercents

      let revVals2 = TotalRevenueAndMonthlyRevPercents(year1Revenue, forecast2, state.growthRateInputs2['total_revenue'])
      year2Revenue = revVals2.finalRevenue
      year2MonthlyRevPercents = revVals2.monthlyRevPercents

      let revVals3 = TotalRevenueAndMonthlyRevPercents(year2Revenue, forecast3, state.growthRateInputs3['total_revenue'])
      year3Revenue = revVals3.finalRevenue
      year3MonthlyRevPercents = revVals3.monthlyRevPercents

      const pnlObj1 = forecastStatesWithTotalRevenueGR(forecast1, year1Revenue, year1MonthlyRevPercents, props.blendMonth)
      const pnlObj2 = forecastStatesWithTotalRevenueGR(forecast2, year2Revenue, year2MonthlyRevPercents)
      const pnlObj3 = forecastStatesWithTotalRevenueGR(forecast3, year3Revenue, year3MonthlyRevPercents)

      setForecastSets((prevState) => {
        return {
          year1: pnlObj1.newForecasts,
          year2: pnlObj2.newForecasts,
          year3: pnlObj3.newForecasts,
          year1Sum: pnlObj1.sum,
          year2Sum: pnlObj2.sum,
        }
      })
    }

    if (selectedYear === 'year2') {
      year1Revenue = forecasts.year1Sum['total_revenue']
      let revVals2 = TotalRevenueAndMonthlyRevPercents(year1Revenue, forecast2, value)
      year2Revenue = revVals2.finalRevenue
      year2MonthlyRevPercents = revVals2.monthlyRevPercents

      let revVals3 = TotalRevenueAndMonthlyRevPercents(year2Revenue, forecast3, state.growthRateInputs3['total_revenue'])
      year3Revenue = revVals3.finalRevenue
      year3MonthlyRevPercents = revVals3.monthlyRevPercents
      const pnlObj2 = forecastStatesWithTotalRevenueGR(forecast2, year2Revenue, year2MonthlyRevPercents)
      const pnlObj3 = forecastStatesWithTotalRevenueGR(forecast3, year3Revenue, year3MonthlyRevPercents)

      setForecastSets((prevState) => {
        return {
          year1: prevState.year1,
          year2: pnlObj2.newForecasts,
          year3: pnlObj3.newForecasts,
          year1Sum: prevState.year1Sum,
          year2Sum: pnlObj2.sum,
        }
      })
    }

    if (selectedYear === 'year3') {
      year2Revenue = forecasts.year2Sum['total_revenue']

      let revVals3 = TotalRevenueAndMonthlyRevPercents(year2Revenue, forecast3, value)
      year3Revenue = revVals3.finalRevenue
      year3MonthlyRevPercents = revVals3.monthlyRevPercents
      const pnlObj3 = forecastStatesWithTotalRevenueGR(forecast3, year3Revenue, year3MonthlyRevPercents)

      setForecastSets((prevState) => {
        return {
          year1: prevState.year1,
          year2: prevState.year2,
          year3: pnlObj3.newForecasts,
          year1Sum: prevState.year1Sum,
          year2Sum: prevState.year2Sum,
        }
      })
    }
  }

  const handleOpexGR = (key, grValue, inputDetails) => {
    let growthRate2 = state.growthRateInputs2['total_revenue'] ? Number(state.growthRateInputs2['total_revenue']) / 100 : state.growthRate2.value
    let growthRate3 = state.growthRateInputs3['total_revenue'] ? Number(state.growthRateInputs3['total_revenue']) / 100 : state.growthRate3.value
    let forecast1 = _.cloneDeep(forecasts.year1)
    let forecast2 = _.cloneDeep(forecasts.year2)
    let forecast3 = _.cloneDeep(forecasts.year3)
    let grVal = Number(grValue) / 100

    // Similar logic to childStateHandler but handles every month
    const updateChildStates = (forecasts, key, val, details) => {
      let startMonth = props.isLiveData ? props.blendMonth - 1 : 0

      for (let i = startMonth; i < 12; i++) {
        const newStateObj = _.cloneDeep(forecasts[i].state)
        const pnl = newStateObj['pnl']
        let oldChildVal = parseFloat(pnl.children[details.parent][key]);
        const newChildVal = parseFloat(val);
        const newParentVal = parseFloat(pnl.parents[details.parent]) + (newChildVal - oldChildVal);
        pnl.children[details.parent][key] = newChildVal;
        pnl.parents[details.parent] = newParentVal;
        newStateObj['pnl'] = pnl
        forecasts[i].state = newStateObj
      }
      return forecasts
    }

    if (selectedYear === 'year1') {
      let forecastStateObj
      let forecastState2Obj
      let forecastState3Obj

      setForecastSets((prevState) => {
        let finalYear1 = props.companyCalc[key] * (1 + grVal)
        let monthlyAvgVal = 0

        if (props.isLiveData) {
          let liveTotal = 0
          for (let i = 0; i < props.blendMonth - 1; i++) {
            liveTotal += forecast1[i].state['pnl']['children'][inputDetails.parent][key]
          }
          monthlyAvgVal = (finalYear1 - liveTotal) / (13 - props.blendMonth)
        } else {
          monthlyAvgVal = finalYear1 / 12
        }

        forecast1 = updateChildStates(forecast1, key, monthlyAvgVal, inputDetails)
        forecastStateObj = new AverageMonthVals(forecast1)
        forecastState2Obj = new AverageMonthVals(forecast1, growthRate2, forecastStateObj.sum)

        for (let forecast of forecast2) {
          forecast.state = forecastState2Obj.forecast
        }
        forecastState3Obj = new AverageMonthVals(forecast2, growthRate3, forecastState2Obj.sum)

        for (let forecast of forecast3) {
          forecast.state = forecastState3Obj.forecast
        }
        return {
          year1: forecast1,
          year2: forecast2,
          year3: forecast3,
          year1Sum: forecastStateObj.sum,
          year2Sum: forecastState2Obj.sum,
        }
      })
    }

    if (selectedYear === 'year2') {
      let forecastState2Obj
      let forecastState3Obj

      setForecastSets((prevState) => {
        let finalYear2 = prevState.year1Sum[key] * (1 + grVal)
        let monthlyAvgVal = finalYear2 / 12
        forecast2 = updateChildStates(forecast2, key, monthlyAvgVal, inputDetails)
        forecastState2Obj = new AverageMonthVals(forecast2)
        forecastState3Obj = new AverageMonthVals(forecast2, growthRate3, forecastState2Obj.sum)
        forecast3 = _.cloneDeep(forecasts.year3)

        for (let forecast of forecast3) {
          forecast.state = forecastState3Obj.forecast
        }

        return {
          year1: prevState.year1,
          year2: forecast2,
          year3: forecast3,
          year1Sum: prevState.year1Sum,
          year2Sum: forecastState2Obj.sum,
        }
      })

    }

    if (selectedYear === 'year3') {
      setForecastSets((prevState) => {
        let forecast3 = _.cloneDeep(prevState.year3)
        let finalYear3 = prevState.year2Sum[key] * (1 + grVal)
        let monthlyAvgVal = finalYear3 / 12
        forecast3 = updateChildStates(forecast3, key, monthlyAvgVal, inputDetails)

        return {
          year1: prevState.year1,
          year2: prevState.year2,
          year3: forecast3,
          year1Sum: prevState.year1Sum,
          year2Sum: prevState.year2Sum
        }
      })
    }
  }

  const totalRevenueGRChanges = (args, f1, f2, f3, g1, g2, g3, blendMonth = 0) => {
    let forecast1 = _.cloneDeep(f1)
    let forecast2 = _.cloneDeep(f2)
    let forecast3 = _.cloneDeep(f3)
    let year1Revenue = 0
    let year2Revenue = 0
    let year3Revenue = 0
    let year1MonthlyRevPercents = null
    let year2MonthlyRevPercents = null
    let year3MonthlyRevPercents = null
    let value = args.details.value

    if (args.selectedYear === 'year1') {
      let revVals = TotalRevenueAndMonthlyRevPercents(props.companyCalc.total_revenue, forecast1, value, blendMonth)
      year1Revenue = revVals.finalRevenue
      year1MonthlyRevPercents = revVals.monthlyRevPercents

      let revVals2 = TotalRevenueAndMonthlyRevPercents(year1Revenue, forecast2, g2['total_revenue'])
      year2Revenue = revVals2.finalRevenue
      year2MonthlyRevPercents = revVals2.monthlyRevPercents

      let revVals3 = TotalRevenueAndMonthlyRevPercents(year2Revenue, forecast3, g3['total_revenue'])
      year3Revenue = revVals3.finalRevenue
      year3MonthlyRevPercents = revVals3.monthlyRevPercents

      const pnlObj1 = forecastStatesWithTotalRevenueGR(forecast1, year1Revenue, year1MonthlyRevPercents, blendMonth)
      const pnlObj2 = forecastStatesWithTotalRevenueGR(forecast2, year2Revenue, year2MonthlyRevPercents)
      const pnlObj3 = forecastStatesWithTotalRevenueGR(forecast3, year3Revenue, year3MonthlyRevPercents)

      return {
        year1: pnlObj1.newForecasts,
        year2: pnlObj2.newForecasts,
        year3: pnlObj3.newForecasts
      }
    }

    if (args.selectedYear === 'year2') {
      let revVals = TotalRevenueAndMonthlyRevPercents(props.companyCalc.total_revenue, forecast1, g1['total_revenue'], blendMonth)
      year1Revenue = revVals.finalRevenue

      let revVals2 = TotalRevenueAndMonthlyRevPercents(year1Revenue, forecast2, value)
      year2Revenue = revVals2.finalRevenue
      year2MonthlyRevPercents = revVals2.monthlyRevPercents

      let revVals3 = TotalRevenueAndMonthlyRevPercents(year2Revenue, forecast3, g3['total_revenue'])
      year3Revenue = revVals3.finalRevenue
      year3MonthlyRevPercents = revVals3.monthlyRevPercents
      const pnlObj2 = forecastStatesWithTotalRevenueGR(forecast2, year2Revenue, year2MonthlyRevPercents)
      const pnlObj3 = forecastStatesWithTotalRevenueGR(forecast3, year3Revenue, year3MonthlyRevPercents)

      return {
        year1: forecast1,
        year2: pnlObj2.newForecasts,
        year3: pnlObj3.newForecasts,
        // year1Sum: prevState.year1Sum,
        // year2Sum: pnlObj2.sum,
      }
    }

    if (args.selectedYear === 'year3') {
      let revVals = TotalRevenueAndMonthlyRevPercents(props.companyCalc.total_revenue, forecast1, g1['total_revenue'], blendMonth)
      year1Revenue = revVals.finalRevenue

      let revVals2 = TotalRevenueAndMonthlyRevPercents(year1Revenue, forecast2, g2['total_revenue'])
      year2Revenue = revVals2.finalRevenue

      let revVals3 = TotalRevenueAndMonthlyRevPercents(year2Revenue, forecast3, value)
      year3Revenue = revVals3.finalRevenue
      year3MonthlyRevPercents = revVals3.monthlyRevPercents
      const pnlObj3 = forecastStatesWithTotalRevenueGR(forecast3, year3Revenue, year3MonthlyRevPercents)

      return {
        year1: forecast1,
        year2: forecast2,
        year3: pnlObj3.newForecasts,
        // year1Sum: prevState.year1Sum,
        // year2Sum: pnlObj2.sum,
      }
    }
  }

  const opexGRChanges = (args, f1, f2, f3, g1, g2, g3, blendMonth = 0) => {
    let growthRate1 = Number(g1['total_revenue']) / 100
    let growthRate2 = Number(g2['total_revenue']) / 100
    let growthRate3 = Number(g3['total_revenue']) / 100
    let forecast1 = _.cloneDeep(f1)
    let forecast2 = _.cloneDeep(f2)
    let forecast3 = _.cloneDeep(f3)
    let grVal = Number(args.details.value) / 100
    let key = args.details.key

    const childStateChange = (forecasts, key, val, details) => {
      let startMonth = props.isLiveData ? blendMonth - 1 : 0

      for (let i = startMonth; i < 12; i++) {
        const newStateObj = _.cloneDeep(forecasts[i].state)
        const pnl = newStateObj['pnl']
        let oldChildVal = parseFloat(pnl.children[details.parent][key]);
        const newChildVal = parseFloat(val);
        const newParentVal = parseFloat(pnl.parents[details.parent]) + (newChildVal - oldChildVal);
        pnl.children[details.parent][key] = newChildVal;
        pnl.parents[details.parent] = newParentVal;
        newStateObj['pnl'] = pnl
        forecasts[i].state = newStateObj
      }
      return forecasts
    }

    if (args.selectedYear === 'year1') {
      let forecastStateObj
      let forecastState2Obj
      let forecastState3Obj
      let finalYear1 = props.companyCalc[key] * (1 + grVal)
      let monthlyAvgVal = 0

      if (props.isLiveData) {
        let liveTotal = 0
        for (let i = 0; i < blendMonth - 1; i++) {
          liveTotal += forecast1[i].state['pnl']['children'][args.details.item.parent][key]
        }
        monthlyAvgVal = (finalYear1 - liveTotal) / (13 - blendMonth)
      } else {
        monthlyAvgVal = finalYear1 / 12
      }

      forecast1 = childStateChange(forecast1, key, monthlyAvgVal, args.details.item)
      forecastStateObj = new AverageMonthVals(forecast1)
      forecastState2Obj = new AverageMonthVals(forecast1, growthRate2, forecastStateObj.sum)

      for (let forecast of forecast2) {
        let newState = _.cloneDeep(forecastState2Obj.forecast)
        newState['pnl']['parents']['total_revenue'] = forecast.state['pnl']['parents']['total_revenue']
        newState['pnl']['children']['total_revenue']['total_revenue'] = forecast.state['pnl']['parents']['total_revenue']
        forecast.state = newState
      }
      forecastState3Obj = new AverageMonthVals(forecast2, growthRate3, forecastState2Obj.sum)

      for (let forecast of forecast3) {
        let newState = _.cloneDeep(forecastState3Obj.forecast)
        newState['pnl']['parents']['total_revenue'] = forecast.state['pnl']['parents']['total_revenue']
        newState['pnl']['children']['total_revenue']['total_revenue'] = forecast.state['pnl']['parents']['total_revenue']
        forecast.state = newState
      }
      return {
        year1: forecast1,
        year2: forecast2,
        year3: forecast3
      }
    }

    if (args.selectedYear === 'year2') {
      let forecastState2Obj
      let forecastState3Obj
      let yearValues = []
      forecasts1.forEach((forecast) => {
        let pnlVals = new PnlValues(forecast)
        yearValues.push(pnlVals);
      })
      let year1Sum = new Sum(yearValues)


      let finalYear2 = year1Sum[key] * (1 + grVal)
      let monthlyAvgVal = finalYear2 / 12
      forecast2 = childStateChange(forecast2, key, monthlyAvgVal, args.details.item)
      forecastState2Obj = new AverageMonthVals(forecast2)
      forecastState3Obj = new AverageMonthVals(forecast2, growthRate3, forecastState2Obj.sum)

      for (let forecast of forecast3) {
        let newState = _.cloneDeep(forecastState3Obj.forecast)
        newState['pnl']['parents']['total_revenue'] = forecast.state['pnl']['parents']['total_revenue']
        newState['pnl']['children']['total_revenue']['total_revenue'] = forecast.state['pnl']['parents']['total_revenue']
        forecast.state = newState
      }

      return {
        year1: forecast1,
        year2: forecast2,
        year3: forecast3
      }
    }

    if (args.selectedYear === 'year3') {
      let yearValues = []
      forecasts1.forEach((forecast) => {
        let pnlVals = new PnlValues(forecast)
        yearValues.push(pnlVals);
      })
      let year1Sum = new Sum(yearValues)
      let finalYear2 = year1Sum[key] * (1 + growthRate2)
      let finalYear3 = finalYear2[key] * (1 + grVal)
      let monthlyAvgVal = finalYear3 / 12
      forecast3 = childStateChange(forecast3, key, monthlyAvgVal, args.details.item)

      return {
        year1: forecast1,
        year2: forecast2,
        year3: forecast3
      }
    }
  }

  const applyGrowthRateChanges = (args, f1, f2, f3, g1, g2, g3, blendMonth = 0) => {
    let finalResult = null
    if (args.details.key === 'total_revenue') {
      finalResult = totalRevenueGRChanges(args, f1, f2, f3, g1, g2, g3, blendMonth)
    } else {
      finalResult = opexGRChanges(args, f1, f2, f3, g1, g2, g3, blendMonth)
    }
    return finalResult
  }

  const handleGrowthInputs = (input) => {
    let grKey = input.key
    let grVal = input.value
    // Save Growth Rate input values based on what forecasted year selected
    setState((prevState) => {
      if (selectedYear === 'year1') {
        let newGRs = _.cloneDeep(prevState.growthRateInputs1)
        newGRs[grKey] = grVal

        return {
          ...prevState,
          slidersYear1Touched: true,
          growthRateInputs1: newGRs
        }
      }

      if (selectedYear === 'year2') {
        let newGRs = _.cloneDeep(prevState.growthRateInputs2)
        newGRs[grKey] = grVal

        return {
          ...prevState,
          slidersYear2Touched: true,
          growthRateInputs2: newGRs
        }
      }

      if (selectedYear === 'year3') {
        let newGRs = _.cloneDeep(prevState.growthRateInputs3)
        newGRs[grKey] = grVal

        return {
          ...prevState,
          slidersYear3Touched: true,
          growthRateInputs3: newGRs
        }
      }
    })

    if (grKey === 'total_revenue') {
      handleTotalRevenueGR(grVal)

      setActions((prevState) => {
        let newAction = {
          action: 'totalRevenueGRChange', sheet: 'pnl', details: input, selectedYear: selectedYear, editMultipleMonths: editMultipleMonths, selectedMonth: state.month, scenarioName: props.scenarioName
        }
        return [...prevState, newAction]
      })
    } else {
      handleOpexGR(grKey, grVal, input.item)

      setActions((prevState) => {
        let newAction = {
          action: 'opexGRChange', sheet: 'pnl', details: input, selectedYear: selectedYear, editMultipleMonths: editMultipleMonths, selectedMonth: state.month, scenarioName: props.scenarioName
        }
        return [...prevState, newAction]
      })
    }
  }

  const setCurrentState = (callback) => {
    let growthRate2 = state.growthRateInputs2['total_revenue'] ? Number(state.growthRateInputs2['total_revenue']) / 100 : state.growthRate2.value
    let growthRate3 = state.growthRateInputs3['total_revenue'] ? Number(state.growthRateInputs3['total_revenue']) / 100 : state.growthRate3.value

    if (selectedYear === 'year1') {
      const selectedMonth = getCurrentState(forecasts.year1);
      const month = state.month
      const balanceSheet = getCurrentBalanceSheet();
      const result = callback(selectedMonth, balanceSheet);
      let forecastStateObj
      let forecastState2Obj
      let forecast2
      let forecastState3Obj
      let forecast3

      setState((prevState) => {
        let growthRateValue = prevState.growthRate.value
        return {
          ...prevState,
          slidersYear1Touched: true,
          growthRate: {
            value: growthRateValue,
            touched: false
          }
        }
      })

      setForecastSets((prevState) => {
        let forecast1 = _.cloneDeep(prevState.year1)

        if (editMultipleMonths) {
          for (let i = month; i < 12; i++) {
            forecast1[i].state = result
          }
        } else {
          forecast1[month].state = result
        }

        forecastStateObj = new AverageMonthVals(forecast1)
        forecastState2Obj = new AverageMonthVals(forecast1, growthRate2, forecastStateObj.sum)
        forecast2 = _.cloneDeep(forecasts.year2)
        for (let forecast of forecast2) {
          forecast.state = forecastState2Obj.forecast
        }
        forecastState3Obj = new AverageMonthVals(forecast2, growthRate3, forecastState2Obj.sum)
        forecast3 = _.cloneDeep(forecasts.year3)
        for (let forecast of forecast3) {
          forecast.state = forecastState3Obj.forecast
        }
        return {
          year1: forecast1,
          year2: forecast2,
          year3: forecast3,
          year1Sum: forecastStateObj.sum,
          year2Sum: forecastState2Obj.sum,
        }
      })
    }
    if (selectedYear === 'year2') {
      const selectedMonth = getCurrentState(forecasts.year2);
      const month = state.month
      const balanceSheet = getCurrentBalanceSheet();
      const result = callback(selectedMonth, balanceSheet);
      let forecastState2Obj
      let forecastState3Obj
      let forecast3
      setState((prevState) => {
        let growthRateValue = prevState.growthRate2.value
        return {
          ...prevState,
          slidersYear2Touched: true,
          growthRate2: {
            value: growthRateValue,
            touched: false
          }
        }
      })
      setForecastSets((prevState) => {
        let forecast2 = _.cloneDeep(prevState.year2)

        if (editMultipleMonths) {
          for (let i = month; i < 12; i++) {
            forecast2[i].state = result
          }
        } else {
          forecast2[month].state = result
        }

        forecastState2Obj = new AverageMonthVals(forecast2)
        forecastState3Obj = new AverageMonthVals(forecast2, growthRate3, forecastState2Obj.sum)
        forecast3 = _.cloneDeep(forecasts.year3)

        for (let forecast of forecast3) {
          forecast.state = forecastState3Obj.forecast
        }
        return {
          year1: prevState.year1,
          year2: forecast2,
          year3: forecast3,
          year1Sum: prevState.year1Sum,
          year2Sum: forecastState2Obj.sum
        }
      })
    }

    if (selectedYear === 'year3') {
      const selectedMonth = getCurrentState(forecasts.year3);
      const month = state.month
      const balanceSheet = getCurrentBalanceSheet();
      const result = callback(selectedMonth, balanceSheet);
      setState((prevState) => {
        let growthRateValue = prevState.growthRate3.value
        return {
          ...prevState,
          slidersYear3Touched: true,
          growthRate3: {
            value: growthRateValue,
            touched: false
          }
        }
      })
      setForecastSets((prevState) => {
        let forecast3 = _.cloneDeep(prevState.year3)

        if (editMultipleMonths) {
          for (let i = month; i < 12; i++) {
            forecast3[i].state = result
          }
        } else {
          forecast3[month].state = result
        }

        return {
          year1: prevState.year1,
          year2: prevState.year2,
          year3: forecast3,
          year1Sum: prevState.year1Sum,
          year2Sum: prevState.year2Sum
        }
      })

    }
  }

  const setForecastSet2 = (month1Year2BalSheet, slidersTouched) => {
    const year2ForecastArr = _.clone(forecasts.year2)

    if (slidersTouched === false) {
      year2ForecastArr.forEach((element, index) => {
        let setToState

        if (index === 1) {
          setToState = element['state']['balance_sheet']

          Object.values(setToState.children).forEach(value => {
            Object.keys(value).forEach(key => {
              value[key] = month1Year2BalSheet[key]
            })
          })
          Object.keys(setToState.parents).forEach(key => {
            setToState.parents[key] = month1Year2BalSheet[key]
          })
          year2ForecastArr[0].state.balance_sheet = setToState
        } else {
          element['state']['balance_sheet'] = year2ForecastArr[0].state.balance_sheet
        }
        return year2ForecastArr
      })
    }
  }

  const setForecastSet3 = (month1Year3BalSheet, slidersTouched) => {
    const year3ForecastArr = _.clone(forecasts.year3)

    if (slidersTouched === false) {
      year3ForecastArr.forEach((element, index) => {
        let setToState

        if (index === 1) {
          setToState = element['state']['balance_sheet']

          Object.values(setToState.children).forEach(value => {
            Object.keys(value).forEach(key => {
              value[key] = month1Year3BalSheet[key]
            })
          })
          Object.keys(setToState.parents).forEach(key => {
            setToState.parents[key] = month1Year3BalSheet[key]
          })
          year3ForecastArr[0].state.balance_sheet = setToState
        } else {
          element['state']['balance_sheet'] = year3ForecastArr[0].state.balance_sheet
        }
        return year3ForecastArr
      })
    }
  }

  const handleStateChange = (args) => {
    if (args.parent) {
      childStateHandler(args);

      setActions((prevState) => {
        let newAction = {
          action: 'childChange', sheet: args.sheet, details: args, selectedYear: selectedYear, editMultipleMonths: editMultipleMonths, selectedMonth: state.month, scenarioName: props.scenarioName
        }
        return [...prevState, newAction]
      })


    } else {
      if (args.sheet == 'pnl') {
        pnlParentStateHandler(args);
      } else {
        balParentStateHandler(args);
      }

      setActions((prevState) => {
        let newAction = {
          action: 'parentChange', sheet: args.sheet, details: args, selectedYear: selectedYear, editMultipleMonths: editMultipleMonths, selectedMonth: state.month, scenarioName: props.scenarioName
        }
        return [...prevState, newAction]
      })

    }
  }

  const childStateHandler = (args) => {
    setCurrentState(prevState => {
      const test = _.cloneDeep(prevState)
      const state = test[args.sheet];
      let oldChildVal = parseFloat(state.children[args.parent][args.key]);
      if (!oldChildVal) {
        oldChildVal = 0.0
      }
      const newChildVal = parseFloat(args.value);
      const newParentVal = parseFloat(state.parents[args.parent]) + (newChildVal - oldChildVal);
      state.children[args.parent][args.key] = newChildVal;
      state.parents[args.parent] = newParentVal;
      return test;
    })
  }

  const handleChartChange = (chart) => {
    const forecastYears = ['year1', 'year2', 'year3']
    let newForecastYear1ForChart = []
    let newForecastYear2ForChart = []
    let newForecastYear3ForChart = []
    let chartData
    if (chart.includes('You')) {
      chartData = youDataForChart
    }
    if (chart.includes('Peers')) {
      chartData = peerDataForChart
    }

    if (chart === 'Straightline') {
      props.updateScenario('Straightline');
    } else {
      forecastYears.forEach(forecastYear => {
        straightline[forecastYear].forEach((set, i) => {
          const test = _.cloneDeep(set)
          const newState = test['state']['pnl'];
          let value
          switch (forecastYear) {
            case 'year1':
              value = chartData[i] ? parseFloat(((props.companyCalc.total_revenue * state.growthRate.value) + props.companyCalc.total_revenue) * chartData[i]) : 0;
              break;
            case 'year2':
              value = chartData[i] ? parseFloat(((straightline.year1Sum.total_revenue * state.growthRate.value) + straightline.year1Sum.total_revenue) * chartData[i]) : 0;
              break;
            case 'year3':
              value = chartData[i] ? parseFloat(((straightline.year2Sum.total_revenue * state.growthRate.value) + straightline.year2Sum.total_revenue) * chartData[i]) : 0;
              break;
          }
          newState.parents['total_revenue'] = value; // after this step props.forecasts changes BAD!!!!!
          newState.children['total_revenue']['total_revenue'] = value // children = {total_revenue: 96568.2089}

          switch (forecastYear) {
            case 'year1':
              newForecastYear1ForChart.push(test)
              break;
            case 'year2':
              newForecastYear2ForChart.push(test)
              break;
            case 'year3':
              newForecastYear3ForChart.push(test)
              break;
          }
        })
      })
      handleScenarioSubmit(chart, { year1: newForecastYear1ForChart, year2: newForecastYear2ForChart, year3: newForecastYear3ForChart }, false)
    }
  }

  const pnlParentStateHandler = (args) => {
    setCurrentState((selectedMonth) => {
      const test = _.cloneDeep(selectedMonth)
      const newState = test[args.sheet];
      let value = parseFloat(args.value);
      if (value == 0) value = value + 1;

      const oldParentVal = newState.parents[args.key];
      newState.parents[args.key] = value; // after this step props.forecasts changes BAD!!!!!
      let children = newState.children[args.key] // children = {total_revenue: 96568.2089}
      Object.keys(children || {}).forEach((key) => {
        const ratio = children[key] / oldParentVal;
        newState.children[args.key][key] = value * ratio;
      })
      return test;
    })
  }

  const balParentStateHandler = (args) => {
    setCurrentState((prevState, balanceSheet) => {
      const test = _.cloneDeep(prevState)
      const state = test[args.sheet];
      let value = parseFloat(args.value);
      if (value == 0) value = 1;
      // const oldParentVal = state.parents[args.key];
      state.parents[args.key] = value;
      let children = state.children[args.key]
      Object.keys(children || {}).forEach((key) => {
        const denominator = balanceSheet[args.key] == 0 ? 1 : balanceSheet[args.key]
        const ratio = balanceSheet[key] / denominator;
        if (key == 'long_term_debt') {
          state.children[args.key][key] = value
        } else {
          state.children[args.key][key] = value * ratio;
        }
      })
      return test;
    })
  }

  const handleScenarioSubmit = async (scenario_name, chartInfo = false, create_notification = false) => {

    if (chartInfo) {
      const jsonChart = await fetch('/monthly/forecast', {
        method: 'POST',
        headers: {
          'X-CSRF-Token': window.token,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          forecast_calculation: {
            company_calculation_id: state.companyCalcId,
            scenario_name,
            growth_rates_year1: state.growthRateInputs1,
            growth_rates_year2: state.growthRateInputs2,
            growth_rates_year3: state.growthRateInputs3,
            starting_growth_rate_year1: state.growthRate.value,
            starting_growth_rate_year2: state.growthRate2.value,
            starting_growth_rate_year3: state.growthRate3.value,
            forecast_year1: JSON.stringify(chartInfo.year1),
            forecast_year2: JSON.stringify(chartInfo.year2),
            forecast_year3: JSON.stringify(chartInfo.year3),
            create_notification: create_notification
          }
        })
      });
      const res = await jsonChart.json();
      props.updateScenario(res.scenario_name);
    } else {
      const json = await fetch('/monthly/forecast', {
        method: 'POST',
        headers: {
          'X-CSRF-Token': window.token,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          forecast_calculation: {
            company_calculation_id: state.companyCalcId,
            scenario_name,
            growth_rates_year1: state.growthRateInputs1,
            growth_rates_year2: state.growthRateInputs2,
            growth_rates_year3: state.growthRateInputs3,
            starting_growth_rate_year1: state.growthRate.value,
            starting_growth_rate_year2: state.growthRate2.value,
            starting_growth_rate_year3: state.growthRate3.value,
            forecast_year1: JSON.stringify(forecasts.year1),
            forecast_year2: JSON.stringify(forecasts.year2),
            forecast_year3: JSON.stringify(forecasts.year3),
          }
        })
      });
      const res = await json.json();
      ahoy.track('Saved a monthly forecast scenario', { scenario: res.scenario_name });
      props.updateScenario(res.scenario_name);
    }
  }

  const saveScenarioChanges = async () => {
    props.handleLoading(true)
    try {
      const json = await fetch('/monthly/forecast', {
        method: 'PATCH',
        headers: {
          'X-CSRF-Token': window.token,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          forecast_calculation: {
            scenario_name: props.scenarioName,
            growth_rates_year1: state.growthRateInputs1,
            growth_rates_year2: state.growthRateInputs2,
            growth_rates_year3: state.growthRateInputs3,
            starting_growth_rate_year1: state.growthRate.value,
            starting_growth_rate_year2: state.growthRate2.value,
            starting_growth_rate_year3: state.growthRate3.value,
            forecast_year1: JSON.stringify(forecasts.year1),
            forecast_year2: JSON.stringify(forecasts.year2),
            forecast_year3: JSON.stringify(forecasts.year3),
          }
        })
      });
      const res = await json.json();

      if (!res.ok) {
        throw new Error('Network response was not ok.');
      }
      props.handleLoading(false)
    } catch (error) {
      props.handleLoading(false)
      console.log(error);
      alert('Sorry something went wrong. Changes were not saved properly.');
    }
  }

  let scenarioName = props.scenarioName

  const buildSheetData = (blendMonth) => {
    const pnlValueSet = [];
    const pnlValueSet2 = [];
    const pnlValueSet3 = [];
    const balValueSet = [];
    const balValueSet2 = []
    const balValueSet3 = []
    let forecast1 = forecasts.year1
    let forecast2 = forecasts.year2
    let forecast3 = forecasts.year3
    let year1Revenue = 0
    let year2Revenue = 0
    let year3Revenue = 0
    let year1MonthlyRevPercents = null
    let year2MonthlyRevPercents = null
    let year3MonthlyRevPercents = null
    let pnlVals

    if (blendMonth === null) {
      let balanceSheetBase = new BalanceSheetValues(
        state.baseForecast.state.balance_sheet,
        state.baseForecast.state.balance_sheet.children.net_worth.operating_expenses_net_income,
        state.baseForecast.state.pnl.children.total_other_costs.depreciation_and_amortization
      );
      let originalDepreciation = state.baseForecast.state.balance_sheet.children.total_fixed_assets.less_accumulated_depreciation

      forecast1.forEach((forecast, index) => {
        pnlVals = new PnlValues(forecast, 0, null, false, false)
        pnlValueSet.push(pnlVals);
        const balVals = new BalanceSheetValues(
          forecast.state.balance_sheet,
          pnlVals.operating_expenses_net_income,
          pnlVals.depreciation_and_amortization,
          balanceSheetBase,
          forecast.month,
          originalDepreciation,
          blendMonth,
          'year1'
        );
        balValueSet.push(balVals);
        balanceSheetBase = balVals;
        balanceSheetSet1 = balValueSet;
      });

      month1Year2BalSheet = _.clone(balanceSheetSet1[balanceSheetSet1.length - 1])
      month1Year2BalSheet.retained_earnings += month1Year2BalSheet.operating_expenses_net_income
      month1Year2BalSheet.operating_expenses_net_income = 0
      props.scenarioName === 'Straightline' ? setForecastSet2(month1Year2BalSheet, state.slidersYear2Touched) : null
      // change the balance sheet base for the fist month of the second year
      // ditto for year 3
      const pnlSum = new Sum(pnlValueSet);

      forecast2.forEach((forecast, index) => {
        const pnlVals2 = new PnlValues(forecast, 0, null, false, false);
        // remove .pnl so this will be the whole forecast state and try to set state in pnl vals
        pnlValueSet2.push(pnlVals2);

        const balVals2 = new BalanceSheetValues(
          forecast.state.balance_sheet,
          pnlVals2.operating_expenses_net_income,
          pnlVals2.depreciation_and_amortization,
          balanceSheetBase,
          forecast.month,
          originalDepreciation
        );
        balValueSet2.push(balVals2);
        balanceSheetBase = balVals2;
        balanceSheetSet2 = balValueSet2;
      });

      month1Year3BalSheet = _.clone(balanceSheetSet2[balanceSheetSet2.length - 1])
      month1Year3BalSheet.retained_earnings += month1Year3BalSheet.operating_expenses_net_income
      month1Year3BalSheet.operating_expenses_net_income = 0
      props.scenarioName === 'Straightline' ? setForecastSet3(month1Year3BalSheet, state.slidersYear3Touched) : null
      const pnlSum2 = new Sum(pnlValueSet2);

      forecast3.forEach((forecast, index) => {
        const pnlVals3 = new PnlValues(forecast, 0, null, false, false);

        pnlValueSet3.push(pnlVals3);
        const balVals3 = new BalanceSheetValues(
          forecast.state.balance_sheet,
          pnlVals3.operating_expenses_net_income,
          pnlVals3.depreciation_and_amortization,
          balanceSheetBase,
          forecast.month,
          originalDepreciation
        );
        balValueSet3.push(balVals3);
        balanceSheetBase = balVals3;
        balanceSheetSet3 = balValueSet3;
      });
      const pnlSum3 = new Sum(pnlValueSet3);
      const balSum = balValueSet[balValueSet.length - 1];
      const balSum2 = balValueSet2[balValueSet2.length - 1];
      const balSum3 = balValueSet3[balValueSet3.length - 1]
      const pnlSumRatios = new Ratios('total_revenue', pnlSum);
      const pnlSumRatios2 = new Ratios('total_revenue', pnlSum2);
      const pnlSumRatios3 = new Ratios('total_revenue', pnlSum3);
      const balSumRatios = new Ratios('total_assets', balSum);
      const balSumRatios2 = new Ratios('total_assets', balSum2);
      const balSumRatios3 = new Ratios('total_assets', balSum3)
      const cashFlowValueSet = new CashFlowValues(balValueSet, props.companyCalc);
      const cashFlowValueSet2 = new CashFlowValues(balValueSet2, pnlSum, balValueSet);
      const cashFlowValueSet3 = new CashFlowValues(balValueSet3, pnlSum2, balValueSet2);

      return {
        pnlVals: pnlValueSet,
        pnlVals2: pnlValueSet2,
        pnlVals3: pnlValueSet3,
        balVals: balValueSet,
        balVals2: balValueSet2,
        balVals3: balValueSet3,
        cashFlowVals: cashFlowValueSet,
        cashFlowVals2: cashFlowValueSet2,
        cashFlowVals3: cashFlowValueSet3,
        pnlSum,
        pnlSum2,
        pnlSum3,
        balSum,
        balSum2,
        balSum3,
        pnlSumRatios,
        pnlSumRatios2,
        pnlSumRatios3,
        balSumRatios,
        balSumRatios2,
        balSumRatios3
      };
    } else {
      let balanceSheetBase = new BalanceSheetValues(
        state.baseForecast.state.balance_sheet,
        state.baseForecast.state.balance_sheet.children.net_worth.operating_expenses_net_income,
        state.baseForecast.state.pnl.children.total_other_costs.depreciation_and_amortization
      );
      let originalDepreciation = state.baseForecast.state.balance_sheet.children.total_fixed_assets.less_accumulated_depreciation

      forecast1.forEach((forecast, index) => {
        if (forecast.month !== 0 && forecast.month < blendMonth) {
          const pnlVals = new PnlValuesWithBlend(forecast, true)
          pnlValueSet.push(pnlVals);

          const balVals = new BalanceSheetValuesWithBlend(
            forecast.state.balance_sheet,
            pnlVals.operating_expenses_net_income,
            balanceSheetBase,
            forecast.month
          );
          balValueSet.push(balVals);
          balanceSheetBase = balVals;
        } else if (forecast.month !== 0 && forecast.month >= blendMonth) {
          originalDepreciation = state.forecastSet[blendMonth - 1]['state']['balance_sheet']['children']['total_fixed_assets']['less_accumulated_depreciation']
          const pnlVals = new PnlValuesWithBlend(forecast, true);
          pnlValueSet.push(pnlVals);
          const balVals = new BalanceSheetValues(
            forecast.state.balance_sheet,
            pnlVals.operating_expenses_net_income,
            pnlValueSet[blendMonth - 1]['depreciation_and_amortization'],
            balanceSheetBase,
            forecast.month,
            originalDepreciation,
            blendMonth,
            'year1'
          );
          balValueSet.push(balVals);
          balanceSheetBase = balVals;
        }
      });
      balanceSheetSet1 = balValueSet;
      month1Year2BalSheet = _.clone(balanceSheetSet1[balanceSheetSet1.length - 1])
      month1Year2BalSheet.retained_earnings += month1Year2BalSheet.operating_expenses_net_income
      month1Year2BalSheet.operating_expenses_net_income = 0
      props.scenarioName === 'Straightline' ? setForecastSet2(month1Year2BalSheet, state.slidersYear2Touched) : null
      const pnlSum = new Sum(pnlValueSet);
      forecast2.forEach((forecast, index) => {
        const pnlVals2 = new PnlValuesWithBlend(forecast, true);
        pnlValueSet2.push(pnlVals2);
        const balVals2 = new BalanceSheetValues(
          forecast.state.balance_sheet,
          pnlVals2.operating_expenses_net_income,
          pnlVals2.depreciation_and_amortization,
          balanceSheetBase,
          forecast.month,
          originalDepreciation,
          blendMonth
        );
        balValueSet2.push(balVals2);
        balanceSheetBase = balVals2;
        balanceSheetSet2 = balValueSet2;
      });
      month1Year3BalSheet = _.clone(balanceSheetSet2[balanceSheetSet2.length - 1])
      month1Year3BalSheet.retained_earnings += month1Year3BalSheet.operating_expenses_net_income
      month1Year3BalSheet.operating_expenses_net_income = 0
      props.scenarioName === 'Straightline' ? setForecastSet3(month1Year3BalSheet, state.slidersYear3Touched) : null
      const pnlSum2 = new Sum(pnlValueSet2);
      forecast3.forEach((forecast, index) => {
        const pnlVals3 = new PnlValuesWithBlend(forecast, true);
        pnlValueSet3.push(pnlVals3);
        const balVals3 = new BalanceSheetValues(
          forecast.state.balance_sheet,
          pnlVals3.operating_expenses_net_income,
          pnlVals3.depreciation_and_amortization,
          balanceSheetBase,
          forecast.month,
          originalDepreciation
        );
        balValueSet3.push(balVals3);
        balanceSheetBase = balVals3;
        balanceSheetSet3 = balValueSet3;
      });
      const pnlSum3 = new Sum(pnlValueSet3);
      const balSum = balValueSet[balValueSet.length - 1];
      const balSum2 = balValueSet2[balValueSet2.length - 1];
      const balSum3 = balValueSet3[balValueSet3.length - 1]
      const pnlSumRatios = new Ratios('total_revenue', pnlSum);
      const pnlSumRatios2 = new Ratios('total_revenue', pnlSum2);
      const pnlSumRatios3 = new Ratios('total_revenue', pnlSum3);
      const balSumRatios = new Ratios('total_assets', balSum);
      const balSumRatios2 = new Ratios('total_assets', balSum2);
      const balSumRatios3 = new Ratios('total_assets', balSum3)
      const cashFlowValueSet = new CashFlowValues(balValueSet, props.companyCalc);
      const cashFlowValueSet2 = new CashFlowValues(balValueSet2, pnlSum, balValueSet);
      const cashFlowValueSet3 = new CashFlowValues(balValueSet3, pnlSum2, balValueSet2);

      return {
        pnlVals: pnlValueSet,
        pnlVals2: pnlValueSet2,
        pnlVals3: pnlValueSet3,
        balVals: balValueSet,
        balVals2: balValueSet2,
        balVals3: balValueSet3,
        cashFlowVals: cashFlowValueSet,
        cashFlowVals2: cashFlowValueSet2,
        cashFlowVals3: cashFlowValueSet3,
        pnlSum,
        pnlSum2,
        pnlSum3,
        balSum,
        balSum2,
        balSum3,
        pnlSumRatios,
        pnlSumRatios2,
        pnlSumRatios3,
        balSumRatios,
        balSumRatios2,
        balSumRatios3
      };
    }
  }

  let sheetData1 = buildSheetData(props.blendMonth)

  let pnlSliders1 = sliderFor(forecasts.year1, 'pnl');
  let balSliders1 = sliderFor(forecasts.year1, 'balance_sheet');

  let pnlSliders2 = sliderFor(forecasts.year2, 'pnl');
  let balSliders2 = sliderFor(forecasts.year2, 'balance_sheet');

  let pnlSliders3 = sliderFor(forecasts.year3, 'pnl');
  let balSliders3 = sliderFor(forecasts.year3, 'balance_sheet');
  // const deleteScenario = deleteScenarioLink();

  const handleSelectedYear = (year) => {
    switch (year) {
      case 'year1':
        props.setForecastInputsBtnClicked(false);
        setSelectedYear('year1');
        break;
      case 'year2':
        props.setForecastInputsBtnClicked(false);
        setSelectedYear('year2');
        break;
      case 'year3':
        props.setForecastInputsBtnClicked(false);
        setSelectedYear('year3');
        break;
    }
  }

  const firmName = props.name ? props.name.slice(0, props.name.indexOf('/') - 1) : ''

  const renderYearSelector = () => {
    let year1, year2, year3 = ''
    if (props.calendarYearEnd && !state.rolling12Touched) {
      year1 = props.year + 1
      year2 = props.year + 2
      year3 = props.year + 3
    } else if (!props.calendarYearEnd && !state.rolling12Touched) {
      year1 = `FY${props.year + 2}`
      year2 = `FY${props.year + 3}`
      year3 = `FY${props.year + 4}`
    } else if (state.rolling12Touched) {
      year1 = 'Rolling 12'
      year2 = props.calendarYearEnd ? props.year + 2 : `FY${props.year + 3}`
      year3 = props.calendarYearEnd ? props.year + 3 : `FY${props.year + 4}`
    }

    if (selectedYear === 'year1') {
      return (
        <div className='year-selector-content'>
          {state.rolling12Touched ? null : <div className='space-div'></div>}
          <span className='selected-year'>{year1}</span>
          {state.rolling12Touched ? null : <button onClick={() => handleSelectedYear('year2')}><FontAwesomeIcon icon={faArrowRight} /></button>}
        </div>
      )
    }
    if (selectedYear === 'year2') {
      return (
        <div className='year-selector-content'>
          {state.rolling12Touched ? null : <button onClick={() => handleSelectedYear('year1')}><FontAwesomeIcon icon={faArrowLeft} /></button>}
          <span className='selected-year'>{year2}</span>
          {state.rolling12Touched ? null : <button onClick={() => handleSelectedYear('year3')}><FontAwesomeIcon icon={faArrowRight} /></button>}
        </div>
      )
    }
    if (selectedYear === 'year3') {
      return (
        <div className='year-selector-content'>
          {state.rolling12Touched ? null : <button onClick={() => handleSelectedYear('year2')}><FontAwesomeIcon icon={faArrowLeft} /></button>}
          <span className='selected-year'>{year3}</span>
          <div className='space-div'></div>
        </div>
      )
    }
  }

  const pnlSliderModalCSS = props.forecastInputsOpen && props.forecastInputsBtnClicked
    ? {
      base: 'pnl-modal-animate-content hide-on-print',
      afterOpen: 'slider-modal-content_after-open hide-on-print',
      beforeClose: 'slider-modal-content_before-close hide-on-print',
    }
    : {
      base: 'pnl-modal-content hide-on-print',
      afterOpen: 'slider-modal-content_after-open hide-on-print',
      beforeClose: 'slider-modal-content_before-close hide-on-print',
    }

  const balSliderModalCSS = props.forecastInputsOpen && props.forecastInputsBtnClicked
    ? {
      base: 'bal-modal-animate-content hide-on-print',
      afterOpen: 'slider-modal-content_after-open hide-on-print',
      beforeClose: 'slider-modal-content_before-close hide-on-print',
    }
    : {
      base: 'bal-modal-content hide-on-print',
      afterOpen: 'slider-modal-content_after-open hide-on-print',
      beforeClose: 'slider-modal-content_before-close hide-on-print',
    }

  let todayDate = moment(Date.now()).format("LL")

  //  Makes a tab and it's content inactive
  function makeInactive(items) {
    const content = Object.keys(items).map((item) => {
      items[item].classList.remove("active");
    });

  }
  //  Display the selected tab.
  function activateTab(e) {
    //refers to the element whose event listener triggered the event
    const clickedTab = e.currentTarget;
    clickedTab.classList.add("active");
  }

  // Display the selected tab content.
  function activateTabContent(e) {
    // gets the element on which the event originally occurred
    const anchorReference = e.target;
    const activePaneID = anchorReference.getAttribute("href");
    const activePane = document.querySelector(activePaneID);
    activePane.classList.add("active");
  }

  const sheetYearsTabs = document.querySelectorAll("ul.nav-tabs > li > a");
  const panes = document.querySelectorAll(".tab-pane");
  const tabAction = Object.keys(sheetYearsTabs).map((tab) => {
    sheetYearsTabs[tab].addEventListener("click", (e) => {
      makeInactive(sheetYearsTabs);
      activateTab(e);
      makeInactive(panes);
      activateTabContent(e);

      e.preventDefault();
    });
  });

  const editNumOfMonthsBtns = () => {
    return (
      <div className='months-edit-btns'>
        <button className={editMultipleMonths ? 'active' : ''} onClick={() => setEditMultipleMonths(true)}>Edit Following Months</button>
        <button className={editMultipleMonths ? '' : 'active'} onClick={() => setEditMultipleMonths(false)}>Edit Single Month</button>
      </div>
    )
  }

  return (
    <div className='frontmatter monthly-forecast'>
      <div className={props.displayPages['cover_page']['checked'] ? 'hide-cover-page-print' : 'hide-all-time'}>
        <div className='portrait-view cover-page-pdf'>
          <div className='cover-page-top-section'></div>
          <div className='cover-page-info'>
            <div>
              <h1>Monthly Forecast Report</h1>
              <div className='cp-report-info'>
                <p>{props.name}</p>
                <p>{`FOR THE PERIOD: ${new Date().getFullYear()} - ${new Date().getFullYear() + 2}`}</p>
                <p>INDUSTRY: {props.industry}</p>
                <p>NAICS: {props.naicsCode}</p>
                {/* <p>{`NUMBER OF COMPANIES: ${props.n}`}</p> */}
                <p style={{ 'textTransform': 'uppercase' }}>{todayDate}</p>
              </div>
            </div>
          </div>
          <div className='cover-page-bottom-section'>
            <p>Powered by Peerview Data</p>
          </div>
        </div>
      </div>
      <MonthlyForecastLineChart
        actions={actions}
        setApplyActions={setApplyActions}
        calendarYearEnd={props.calendarYearEnd}
        clientName={props.clientName}
        displayPDF={props.displayPages['revenue_model']['checked']}
        firmName={firmName}
        hideChart={hideChart}
        months={monthLabels}
        updateScenario={props.updateScenario}
        youDataForChart={youDataForChart}
        peerDataForChart={peerDataForChart}
        handleChartChange={handleChartChange}
        scenarios={state.scenarioOptions}
        year={props.year}
      />
      {loading ? <div style={{ marginBottom: '400px' }}><LoaderGraphic /></div> : null}
      <ForecastScenario
        actions={actions}
        setApplyActions={setApplyActions}
        updateScenario={props.updateScenario}
        saveScenarioChanges={saveScenarioChanges}
        scenarios={state.scenarioOptions}
        scenario_name={scenarioName}
        handleChartChange={handleChartChange}
        handleScenarioSubmit={handleScenarioSubmit}
        fetchLiveData={props.fetchLiveData}
        liveData={props.liveData}
        selectedYear={selectedYear}
        slidersYear1Touched={state.slidersYear1Touched}
        slidersYear2Touched={state.slidersYear2Touched}
        slidersYear3Touched={state.slidersYear3Touched}
        isLiveData={props.isLiveData}
        youDataForChart={youDataForChart}
        peerDataForChart={peerDataForChart}
        hideChart={hideChart}
        rolling12Months={rolling12Months}
        rolling12Touched={state.rolling12Touched}
        setHideChart={setHideChart}
      />
      <ul id='monthly-forecast-tabs' className='nav nav-tabs hide-on-print' role='tablist'>
        <li className={'nav-item'} role='presentation' >
          <a className='active' href='#pnl' aria-controls='Profit and Loss monthly forecast' role='tab' data-toggle='tab'>
            Profit &#38; Loss
          </a>
        </li>
        <li className={'nav-item'} role='presentation' >
          <a href='#balance-sheet' aria-controls='Balance Sheet monthly forecast' role='tab' data-toggle='tab'>
            Balance Sheet
          </a>
        </li>
        <li className={'nav-item'} role='presentation' >
          <a href='#cash-flow' aria-controls='Cash Flow monthly forecast' role='tab' data-toggle='tab'>
            Cash Flow
          </a>
        </li>
        <li className={'nav-item'} role='presentation' >
          <a href='#kfis' aria-controls='Key Financial Indicators monthly forecast' role='tab' data-toggle='tab'>
            KFI's
          </a>
        </li>
        <li className={'nav-item'} role='presentation' >
          <a href='#survey' aria-controls='Future Survey' role='tab' data-toggle='tab' onClick={() => handleSurveyTab()}>
            Survey
          </a>
        </li>
        <li className='year-selector'>
          {renderYearSelector()}
        </li>
      </ul>
      <div className='tab-content'>
        <div className='tab-pane active' id='pnl' role='tabpanel'>
          <div className={selectedYear === 'year1' ? `show-section monthlyforecast-pnl-component` : 'hide-section'}>
            <Modal
              className={pnlSliderModalCSS}
              overlayClassName={{
                base: 'slider-overlay-base hide-on-print',
                afterOpen: 'slider-overlay-base_after-open hide-on-print',
                beforeClose: 'slider-overlay-base_before-close hide-on-print',
              }}
              id='year1-pnl-modal'
              ariaHideApp={false}
              shouldFocusAfterRender={false}
              isOpen={props.forecastInputsOpen}
              onRequestClose={closeModal}
              shouldCloseOnOverlayClick={false}
              closeTimeoutMS={0}
              contentLabel='Year 1 PNL Sliders'
              parentSelector={() => document.querySelector('#pnl-year1')}
            >
              <ul className='modal-tabs' role='tablist'>
                <li className={inputsDisplay === 'sliders' ? 'si-item active' : 'si-item'} role='presentation' >
                  <a className='sliderinputs' aria-controls='sliders' role='tab' data-toggle='tab' onClick={() => handleInputsTab('sliders')}>
                    Sliders
                  </a>
                </li>
                <li className={inputsDisplay === 'normalInputs' ? 'si-item active' : 'si-item'} role='presentation' >
                  <a className='sliderinputs' aria-controls='input values' role='tab' data-toggle='tab' onClick={() => handleInputsTab('normalInputs')}>
                    Inputs
                  </a>
                </li>
                <li className={inputsDisplay === 'growthRateInputs' ? 'si-item active' : 'si-item'} role='presentation' >
                  <a className='sliderinputs' aria-controls='input values' role='tab' data-toggle='tab' onClick={() => handleInputsTab('growthRateInputs')}>
                    Growth
                  </a>
                </li>
              </ul>
              {editNumOfMonthsBtns()}
              {pnlSliders1}
            </Modal>
            <div id='pnl-year1' className={props.displayPages['pnl']['checked'] ? 'col-sm-12 sheet-col monthly-forecast-pdf-year1' : 'col-sm-12 sheet-col monthly-forecast-pdf-year1 hide-on-print'}>
              <MonthlyForecastSheetMaker
                blendMonth={props.blendMonth}
                calendarYearEnd={props.calendarYearEnd}
                clientName={props.clientName}
                displayPeerValues={state.displayPeerValues}
                editMultipleMonths={editMultipleMonths}
                firmName={firmName}
                forecastedRevenue={sheetData1.pnlSum.total_revenue}
                growthRate={state.growthRate.value}
                isLiveData={props.isLiveData}
                month={state.month}
                months={state.monthsObjY1}
                peerAvgValues={props.peer_avg_calcs}
                previousRevenue={props.companyCalc.total_revenue}
                rolling12Touched={state.rolling12Touched}
                sheet={'pnl'}
                selectedYear={selectedYear}
                setMonth={setMonth}
                sum={sheetData1.pnlSum}
                sumRatios={sheetData1.pnlSumRatios}
                tree={props.tree}
                value={parseFloat(state.growthRateDisplay)}
                valueSet={sheetData1.pnlVals}
                yearNumber={1}
                year={props.year}
                yearly={props.companyCalc}
                yearlyForecast={props.companyCalc}
              />
            </div>
          </div>
          <div className={selectedYear === 'year2' ? `show-section monthlyforecast-pnl-component` : 'hide-section'}>
            <Modal
              className={pnlSliderModalCSS}
              overlayClassName={{
                base: 'slider-overlay-base',
                afterOpen: 'slider-overlay-base_after-open',
                beforeClose: 'slider-overlay-base_before-close',
              }}
              id='year2-pnl-modal'
              ariaHideApp={false}
              shouldFocusAfterRender={false}
              isOpen={props.forecastInputsOpen}
              onRequestClose={closeModal}
              shouldCloseOnOverlayClick={false}
              closeTimeoutMS={0}
              contentLabel='Year 2 PNL Sliders'
              parentSelector={() => document.querySelector('#pnl-year2')}
            >
              <ul className='modal-tabs' role='tablist'>
                <li className={inputsDisplay === 'sliders' ? 'si-item active' : 'si-item'} role='presentation' >
                  <a className='sliderinputs' aria-controls='sliders' role='tab' data-toggle='tab' onClick={() => handleInputsTab('sliders')}>
                    Sliders
                  </a>
                </li>
                <li className={inputsDisplay === 'normalInputs' ? 'si-item active' : 'si-item'} role='presentation' >
                  <a className='sliderinputs' aria-controls='input values' role='tab' data-toggle='tab' onClick={() => handleInputsTab('normalInputs')}>
                    Inputs
                  </a>
                </li>
                <li className={inputsDisplay === 'growthRateInputs' ? 'si-item active' : 'si-item'} role='presentation' >
                  <a className='sliderinputs' aria-controls='input values' role='tab' data-toggle='tab' onClick={() => handleInputsTab('growthRateInputs')}>
                    Growth
                  </a>
                </li>
              </ul>
              {editNumOfMonthsBtns()}
              {pnlSliders2}
            </Modal>
            <div id='pnl-year2' className={props.displayPages['pnl']['checked'] ? 'col-sm-12 sheet-col monthly-forecast-pdf-year2' : 'col-sm-12 sheet-col monthly-forecast-pdf-year2 hide-on-print'}>
              <MonthlyForecastSheetMaker
                baselineRatios={sheetData1.pnlSumRatios}
                blendMonth={props.blendMonth}
                calendarYearEnd={props.calendarYearEnd}
                clientName={props.clientName}
                displayPeerValues={state.displayPeerValues}
                editMultipleMonths={editMultipleMonths}
                firmName={firmName}
                forecastedRevenue={sheetData1.pnlSum2.total_revenue}
                growthRate={state.growthRate2.value}
                isLiveData={props.isLiveData}
                month={state.month}
                months={state.monthsObjY2}
                peerAvgValues={props.peer_avg_calcs}
                previousRevenue={sheetData1.pnlSum.total_revenue}
                rolling12Touched={state.rolling12Touched}
                sheet={'pnl'}
                selectedYear={selectedYear}
                setMonth={setMonth}
                sum={sheetData1.pnlSum2}
                sumRatios={sheetData1.pnlSumRatios2}
                tree={props.tree}
                twoYearsAgoRevenue={props.companyCalc.total_revenue}
                useSum={true}
                value={parseFloat(state.growthRateDisplay2)}
                valueSet={sheetData1.pnlVals2}
                yearNumber={2}
                year={props.year + 1}
                yearly={sheetData1.pnlSum}
                yearlyForecast={sheetData1.pnlSum}
              />
            </div>
          </div>
          <div className={selectedYear === 'year3' ? `show-section monthlyforecast-pnl-component` : 'hide-section'}>
            <Modal
              className={pnlSliderModalCSS}
              overlayClassName={{
                base: 'slider-overlay-base',
                afterOpen: 'slider-overlay-base_after-open',
                beforeClose: 'slider-overlay-base_before-close',
              }}
              id='year3-pnl-modal'
              ariaHideApp={false}
              shouldFocusAfterRender={false}
              isOpen={props.forecastInputsOpen}
              onRequestClose={closeModal}
              shouldCloseOnOverlayClick={false}
              closeTimeoutMS={0}
              contentLabel='Year 3 PNL Sliders'
              parentSelector={() => document.querySelector('#pnl-year3')}
            >
              <ul className='modal-tabs' role='tablist'>
                <li className={inputsDisplay === 'sliders' ? 'si-item active' : 'si-item'} role='presentation' >
                  <a className='sliderinputs' aria-controls='sliders' role='tab' data-toggle='tab' onClick={() => handleInputsTab('sliders')}>
                    Sliders
                  </a>
                </li>
                <li className={inputsDisplay === 'normalInputs' ? 'si-item active' : 'si-item'} role='presentation' >
                  <a className='sliderinputs' aria-controls='input values' role='tab' data-toggle='tab' onClick={() => handleInputsTab('normalInputs')}>
                    Inputs
                  </a>
                </li>
                <li className={inputsDisplay === 'growthRateInputs' ? 'si-item active' : 'si-item'} role='presentation' >
                  <a className='sliderinputs' aria-controls='input values' role='tab' data-toggle='tab' onClick={() => handleInputsTab('growthRateInputs')}>
                    Growth
                  </a>
                </li>
              </ul>
              {editNumOfMonthsBtns()}
              {pnlSliders3}
            </Modal>
            <div id='pnl-year3' className={props.displayPages['pnl']['checked'] ? 'col-sm-12 sheet-col monthly-forecast-pdf-year3' : 'col-sm-12 sheet-col monthly-forecast-pdf-year3 hide-on-print'}>
              <MonthlyForecastSheetMaker
                baselineRatios={sheetData1.pnlSumRatios2}
                blendMonth={props.blendMonth}
                calendarYearEnd={props.calendarYearEnd}
                clientName={props.clientName}
                displayPeerValues={state.displayPeerValues}
                editMultipleMonths={editMultipleMonths}
                firmName={firmName}
                forecastedRevenue={sheetData1.pnlSum3.total_revenue}
                growthRate={state.growthRate3.value}
                isLiveData={props.isLiveData}
                month={state.month}
                months={state.monthsObjY3}
                peerAvgValues={props.peer_avg_calcs}
                previousRevenue={sheetData1.pnlSum2.total_revenue}
                rolling12Touched={state.rolling12Touched}
                sheet={'pnl'}
                selectedYear={selectedYear}
                setMonth={setMonth}
                sum={sheetData1.pnlSum3}
                sumRatios={sheetData1.pnlSumRatios3}
                tree={props.tree}
                twoYearsAgoRevenue={sheetData1.pnlSum.total_revenue}
                useSum={true}
                value={parseFloat(state.growthRateDisplay3)}
                valueSet={sheetData1.pnlVals3}
                yearNumber={3}
                year={props.year + 2}
                yearly={sheetData1.pnlSum2}
                yearlyForecast={sheetData1.pnlSum2}
              />
            </div>
          </div>
        </div>

        <div className='tab-pane' id='balance-sheet' role='tabpanel'>
          <div className={selectedYear === 'year1' ? `show-section monthlyforecast-bal-component` : 'hide-section'}>
            <Modal
              className={balSliderModalCSS}
              overlayClassName={{
                base: 'slider-overlay-base',
                afterOpen: 'slider-overlay-base_after-open',
                beforeClose: 'slider-overlay-base_before-close',
              }}
              id='year1-bal-modal'
              ariaHideApp={false}
              shouldFocusAfterRender={false}
              isOpen={props.forecastInputsOpen}
              onRequestClose={closeModal}
              shouldCloseOnOverlayClick={false}
              closeTimeoutMS={0}
              contentLabel='Year 1 Balance Sheet Sliders'
              parentSelector={() => document.querySelector('#bal-year1')}
            >
              <ul className='modal-tabs' role='tablist'>
                <li className={inputsDisplay === 'sliders' ? 'si-item active' : 'si-item'} role='presentation' >
                  <a className='sliderinputs' aria-controls='sliders' role='tab' data-toggle='tab' onClick={() => handleInputsTab('sliders')}>
                    Sliders
                  </a>
                </li>
                <li className={inputsDisplay === 'normalInputs' ? 'si-item active' : 'si-item'} role='presentation' >
                  <a className='sliderinputs' aria-controls='input values' role='tab' data-toggle='tab' onClick={() => handleInputsTab('normalInputs')}>
                    Inputs
                  </a>
                </li>
                <li className={inputsDisplay === 'growthRateInputs' ? 'si-item active' : 'si-item'} role='presentation' >
                  <a className='sliderinputs' aria-controls='input values' role='tab' data-toggle='tab' onClick={() => handleInputsTab('growthRateInputs')}>
                    Growth
                  </a>
                </li>
              </ul>
              {editNumOfMonthsBtns()}
              {balSliders1}
            </Modal>
            <div id='bal-year1' className={props.displayPages['balance_sheet']['checked'] ? 'col-sm-12 sheet-col monthly-forecast-pdf-year1' : 'col-sm-12 sheet-col monthly-forecast-pdf-year1 hide-on-print'}>
              <MonthlyForecastSheetMaker
                blendMonth={props.blendMonth}
                calendarYearEnd={props.calendarYearEnd}
                clientName={props.clientName}
                displayPeerValues={state.displayPeerValues}
                editMultipleMonths={editMultipleMonths}
                firmName={firmName}
                growthRate={state.growthRate.value}
                isLiveData={props.isLiveData}
                month={state.month}
                months={state.monthsObjY1}
                peerAvgValues={props.peer_avg_calcs}
                rolling12Touched={state.rolling12Touched}
                sheet={'balance_sheet'}
                selectedYear={selectedYear}
                setMonth={setMonth}
                sumRatios={sheetData1.balSumRatios}
                tree={props.tree}
                valueSet={sheetData1.balVals}
                yearNumber={1}
                year={props.year}
                yearly={props.companyCalc}
              />
            </div>
          </div>
          <div className={selectedYear === 'year2' ? `show-section monthlyforecast-bal-component` : 'hide-section'}>
            <Modal
              className={balSliderModalCSS}
              overlayClassName={{
                base: 'slider-overlay-base',
                afterOpen: 'slider-overlay-base_after-open',
                beforeClose: 'slider-overlay-base_before-close',
              }}
              id='year2-bal-modal'
              ariaHideApp={false}
              shouldFocusAfterRender={false}
              isOpen={props.forecastInputsOpen}
              onRequestClose={closeModal}
              shouldCloseOnOverlayClick={false}
              closeTimeoutMS={0}
              contentLabel='Year 2 Balance Sheet Sliders'
              parentSelector={() => document.querySelector('#bal-year2')}
            >
              <ul className='modal-tabs' role='tablist'>
                <li className={inputsDisplay === 'sliders' ? 'si-item active' : 'si-item'} role='presentation' >
                  <a className='sliderinputs' aria-controls='sliders' role='tab' data-toggle='tab' onClick={() => handleInputsTab('sliders')}>
                    Sliders
                  </a>
                </li>
                <li className={inputsDisplay === 'normalInputs' ? 'si-item active' : 'si-item'} role='presentation' >
                  <a className='sliderinputs' aria-controls='input values' role='tab' data-toggle='tab' onClick={() => handleInputsTab('normalInputs')}>
                    Inputs
                  </a>
                </li>
                <li className={inputsDisplay === 'growthRateInputs' ? 'si-item active' : 'si-item'} role='presentation' >
                  <a className='sliderinputs' aria-controls='input values' role='tab' data-toggle='tab' onClick={() => handleInputsTab('growthRateInputs')}>
                    Growth
                  </a>
                </li>
              </ul>
              {editNumOfMonthsBtns()}
              {balSliders2}
            </Modal>
            <div id='bal-year2' className={props.displayPages['balance_sheet']['checked'] ? 'col-sm-12 sheet-col monthly-forecast-pdf-year2' : 'col-sm-12 sheet-col monthly-forecast-pdf-year2 hide-on-print'}>
              <MonthlyForecastSheetMaker
                baselineRatios={sheetData1.balSumRatios}
                blendMonth={props.blendMonth}
                calendarYearEnd={props.calendarYearEnd}
                clientName={props.clientName}
                displayPeerValues={state.displayPeerValues}
                editMultipleMonths={editMultipleMonths}
                firmName={firmName}
                growthRate={state.growthRate2.value}
                isLiveData={props.isLiveData}
                month={state.month}
                months={state.monthsObjY2}
                peerAvgValues={props.peer_avg_calcs}
                rolling12Touched={state.rolling12Touched}
                sheet={'balance_sheet'}
                selectedYear={selectedYear}
                setMonth={setMonth}
                sumRatios={sheetData1.balSumRatios2}
                tree={props.tree}
                useSum={true}
                valueSet={sheetData1.balVals2}
                yearNumber={2}
                year={props.year + 1}
                yearly={balanceSheetSet1[balanceSheetSet1.length - 1]}
              />
            </div>
          </div>
          <div className={selectedYear === 'year3' ? `show-section monthlyforecast-bal-component` : 'hide-section'}>
            <Modal
              className={balSliderModalCSS}
              overlayClassName={{
                base: 'slider-overlay-base',
                afterOpen: 'slider-overlay-base_after-open',
                beforeClose: 'slider-overlay-base_before-close',
              }}
              id='year3-bal-modal'
              ariaHideApp={false}
              shouldFocusAfterRender={false}
              isOpen={props.forecastInputsOpen}
              onRequestClose={closeModal}
              shouldCloseOnOverlayClick={false}
              closeTimeoutMS={0}
              contentLabel='Year 3 Balance Sheet Sliders'
              parentSelector={() => document.querySelector('#bal-year3')}
            >
              <ul className='modal-tabs' role='tablist'>
                <li className={inputsDisplay === 'sliders' ? 'si-item active' : 'si-item'} role='presentation' >
                  <a className='sliderinputs' aria-controls='sliders' role='tab' data-toggle='tab' onClick={() => handleInputsTab('sliders')}>
                    Sliders
                  </a>
                </li>
                <li className={inputsDisplay === 'normalInputs' ? 'si-item active' : 'si-item'} role='presentation' >
                  <a className='sliderinputs' aria-controls='input values' role='tab' data-toggle='tab' onClick={() => handleInputsTab('normalInputs')}>
                    Inputs
                  </a>
                </li>
                <li className={inputsDisplay === 'growthRateInputs' ? 'si-item active' : 'si-item'} role='presentation' >
                  <a className='sliderinputs' aria-controls='input values' role='tab' data-toggle='tab' onClick={() => handleInputsTab('growthRateInputs')}>
                    Growth
                  </a>
                </li>
              </ul>
              {editNumOfMonthsBtns()}
              {balSliders3}
            </Modal>
            <div id='bal-year3' className={props.displayPages['balance_sheet']['checked'] ? 'col-sm-12 sheet-col monthly-forecast-pdf-year3' : 'col-sm-12 sheet-col monthly-forecast-pdf-year3 hide-on-print'}>
              <MonthlyForecastSheetMaker
                baselineRatios={sheetData1.balSumRatios2}
                blendMonth={props.blendMonth}
                calendarYearEnd={props.calendarYearEnd}
                clientName={props.clientName}
                displayPeerValues={state.displayPeerValues}
                editMultipleMonths={editMultipleMonths}
                firmName={firmName}
                growthRate={state.growthRate3.value}
                isLiveData={props.isLiveData}
                month={state.month}
                months={state.monthsObjY3}
                peerAvgValues={props.peer_avg_calcs}
                rolling12Touched={state.rolling12Touched}
                sheet={'balance_sheet'}
                selectedYear={selectedYear}
                setMonth={setMonth}
                sumRatios={sheetData1.balSumRatios3}
                tree={props.tree}
                useSum={true}
                valueSet={sheetData1.balVals3}
                yearNumber={3}
                year={props.year + 2}
                yearly={balanceSheetSet2[balanceSheetSet2.length - 1]}
              />
            </div>
          </div>
        </div>

        <div className='tab-pane' id='cash-flow' role='tabpanel'>
          <div className={selectedYear === 'year1' ? `show-section monthlyforecast-cashflow-component` : 'hide-section'}>
            <div id='cashflow-year1' className={props.displayPages['cash_flow']['checked'] ? 'col-sm-12 sheet-col monthly-forecast-pdf-year1' : 'col-sm-12 sheet-col monthly-forecast-pdf-year1 hide-on-print'}>
              <MonthlyForecastCashFlow
                clientName={props.clientName}
                editMultipleMonths={editMultipleMonths}
                firmName={firmName}
                selectedYear={selectedYear}
                pnlValueSet={sheetData1.pnlVals}
                cashFlowSet={sheetData1.cashFlowVals}
                bvs={sheetData1.balVals}
                year={props.year + 1}
                months={state.monthsObjY1}
                tree={props.tree}
                startingCash={props.companyCalc}
                month={state.month}
                setMonth={setMonth}
                growthRate={state.growthRate.value}
                isLiveData={props.isLiveData}
                blendMonth={props.blendMonth}
              />
            </div>
          </div>
          <div className={selectedYear === 'year2' ? `show-section monthlyforecast-cashflow-component` : 'hide-section'}>
            <div id='cashflow-year2' className={props.displayPages['cash_flow']['checked'] ? 'col-sm-12 sheet-col monthly-forecast-pdf-year2' : 'col-sm-12 sheet-col monthly-forecast-pdf-year2 hide-on-print'}>
              <MonthlyForecastCashFlow
                clientName={props.clientName}
                editMultipleMonths={editMultipleMonths}
                firmName={firmName}
                selectedYear={selectedYear}
                pnlValueSet={sheetData1.pnlVals2}
                cashFlowSet={sheetData1.cashFlowVals2}
                bvs={sheetData1.balVals2}
                year={props.year + 2}
                months={state.monthsObjY2}
                tree={props.tree}
                startingCash={sheetData1.balVals[11]}
                month={state.month}
                setMonth={setMonth}
                growthRate={state.growthRate2.value}
                isLiveData={props.isLiveData}
                blendMonth={props.blendMonth}
              />
            </div>
          </div>
          <div className={selectedYear === 'year3' ? `show-section monthlyforecast-cashflow-component` : 'hide-section'}>
            <div id='cashflow-year3' className={props.displayPages['cash_flow']['checked'] ? 'col-sm-12 sheet-col monthly-forecast-pdf-year3' : 'col-sm-12 sheet-col monthly-forecast-pdf-year3 hide-on-print'}>
              <MonthlyForecastCashFlow
                clientName={props.clientName}
                editMultipleMonths={editMultipleMonths}
                firmName={firmName}
                year={props.year + 3}
                months={state.monthsObjY3}
                selectedYear={selectedYear}
                pnlValueSet={sheetData1.pnlVals3}
                cashFlowSet={sheetData1.cashFlowVals3}
                bvs={sheetData1.balVals3}
                tree={props.tree}
                startingCash={sheetData1.balVals2[11]}
                month={state.month}
                setMonth={setMonth}
                growthRate={state.growthRate3.value}
                isLiveData={props.isLiveData}
                blendMonth={props.blendMonth}
              />
            </div>
          </div>
        </div>

        <div className='tab-pane' id='kfis' role='tabpanel'>
          <div className={selectedYear === 'year1' ? `show-section monthlyforecast-kfis-component` : 'hide-section'}>
            <div id='kfis-year1' className={props.displayPages['kfis']['checked'] ? 'col-sm-12 sheet-col monthly-forecast-pdf-year1' : 'col-sm-12 sheet-col monthly-forecast-pdf-year1 hide-on-print'}>
              <MonthlyForecastKFITable
                clientName={props.clientName}
                editMultipleMonths={editMultipleMonths}
                firmName={firmName}
                year={props.year + 1}
                months={state.monthsObjY1}
                futureYear={false}
                selectedYear={selectedYear}
                pnlValueSet={sheetData1.pnlVals}
                balValueSet={sheetData1.balVals}
                page={props.page}
                month={state.month}
                setMonth={setMonth}
                isLiveData={props.isLiveData}
                blendMonth={props.blendMonth}
              />
            </div>
          </div>
          <div className={selectedYear === 'year2' ? `show-section monthlyforecast-kfis-component` : 'hide-section'}>
            <div id='kfis-year2' className={props.displayPages['kfis']['checked'] ? 'col-sm-12 sheet-col monthly-forecast-pdf-year2' : 'col-sm-12 sheet-col monthly-forecast-pdf-year2 hide-on-print'}>
              <MonthlyForecastKFITable
                clientName={props.clientName}
                editMultipleMonths={editMultipleMonths}
                firmName={firmName}
                months={state.monthsObjY2}
                futureYear={true}
                selectedYear={selectedYear}
                year={props.year + 2}
                previousYearPnlSet={sheetData1.pnlVals}
                previousYearBalSet={sheetData1.balVals}
                pnlValueSet={sheetData1.pnlVals2}
                balValueSet={sheetData1.balVals2}
                page={props.page}
                month={state.month}
                setMonth={setMonth}
                isLiveData={props.isLiveData}
                blendMonth={props.blendMonth}
              />
            </div>
          </div>
          <div className={selectedYear === 'year3' ? `show-section monthlyforecast-kfis-component` : 'hide-section'}>
            <div id='kfis-year3' className={props.displayPages['kfis']['checked'] ? 'col-sm-12 sheet-col monthly-forecast-pdf-year3' : 'col-sm-12 sheet-col monthly-forecast-pdf-year3 hide-on-print'}>
              <MonthlyForecastKFITable
                clientName={props.clientName}
                editMultipleMonths={editMultipleMonths}
                firmName={firmName}
                year={props.year + 3}
                months={state.monthsObjY3}
                futureYear={true}
                selectedYear={selectedYear}
                previousYearPnlSet={sheetData1.pnlVals2}
                previousYearBalSet={sheetData1.balVals2}
                pnlValueSet={sheetData1.pnlVals3}
                balValueSet={sheetData1.balVals3}
                page={props.page}
                month={state.month}
                setMonth={setMonth}
                isLiveData={props.isLiveData}
                blendMonth={props.blendMonth}
              />
            </div>
          </div>
        </div>
        <div className='container-fluid tab-pane hide-on-print' id='survey' role='tabpanel'>
          <SurveyResponses
            chartColors={props.surveyData.chartColors}
            surveyCharts={props.surveyData.surveyCharts}
            surveyTaken={props.surveyTaken}
            companyReportSurvey={props.companyReportSurvey}
            surveyResults={props.surveyResults}
            pageTitle={props.pageTitle}
            year={props.year}
          />
        </div>
      </div>
    </div>
  )
}

export default MonthlyForecast;