import React from "react";
import AccountTree from "../utils/AccountTree";
import { parseMoney } from "../utils";

const MappingSheets = ({ user_type, naics_code, sheet, values, tree }) => {

  const buildRow = ({ i, key, copy, padding, map, bold, header, header_is_total, total, padRow }) => {
    if (header && !header_is_total) return <tr key={i} id={i} ><td>{padding + copy}</td>{nTds()}</tr>;
    if (padRow) return <tr key={i + 100} id={i + 100} className="empty-row"><td>{'\u00A0'}</td>{nTds()}</tr>;

    const rows = [];

    rows.push(
      <tr key={i} id={i} >
        <td className={bold ? 'bold' : ''}>{padding + copy}</td>
        {Object.keys(values).map((year, ii) => {
          let cashWarning6212 = false
          if (user_type === 'Admin' && naics_code.startsWith('6212') && key === 'cash') {
            cashWarning6212 = values[year][key] >= 500000
          }
          return (
            <td key={ii} style={ cashWarning6212 ? { 'backgroundColor': '#feb9b9' } : {} } className={`mapping-balance-sheet-data ${bold ? 'bold' : ''}`}>{parseMoney(values[year][key])}</td>
          )
        })}
      </tr>
    );
    return rows;
  }

  const buildRemainderRow = () => {
    return (
      <tr>
        <td className='bold'>Remainder (Out of Balance By):</td>
        {Object.keys(values).map((year, ii) => {
          if (values[year]['remainder']) {
            return (
              <td key={`${ii}-remainder`} className='mapping-balance-sheet-data' style={{ 'color': 'red' }}>{parseMoney(values[year]['remainder'])}</td>
            )
          } else {
            <td key={`${ii}-remainder`}></td>
          }
        })}
      </tr>
    )
  }

  const nTds = () => {
    const tds = [];
    for (let i = 0; i < Object.keys(values).length; i++) {
      tds.push(<td key={i}></td>);
    }
    return tds;
  }

  const buildRows = () => {
    const accountTree = new AccountTree({
      tree: tree[sheet],
      pad: true,
    });

    const rows = [];
    accountTree.forEach((row) => rows.push(buildRow(row)));
    return rows;
  }

  return (
    <table className="table table-striped table-bordered table-hover balance-sheet">
      <thead className='mapper-sheet-header'>
        <tr>
          <th></th>
          {Object.keys(values).map((year, i) =>
            <th key={i} className="year-header">{year}</th>
          )}
        </tr>
      </thead>
      <tbody>
        {buildRows()}
        {sheet === 'balance_sheet' ? buildRemainderRow() : null}
      </tbody>
    </table>
  )
}

export default MappingSheets;
