import React, { useContext } from 'react';
import { Data, Filters } from '../contexts';
import { parseMoney } from '../../utils';
import { AnnualCashFlowValues } from './MoneyHelpers';

const compareWithRowKeys = {
    'None': null,
    'Practice': 'practice_avg',
    'Peers': 'avg',
    'NSCHBC': 'nschbc'
}

const MoneyCashFlow = (props) => {
    const { calcs } = useContext(Data)
    const allFilters = useContext(Filters);
    const compareWith = allFilters.currentNewFilters.compare_with;
    const calcsAvgType = compareWithRowKeys[compareWith]

    const buildRows = () => {
        let cashFlowRows = [];
        props.rows.forEach((row) => {
            let rowValues = AnnualCashFlowValues(calcs, calcsAvgType, props.yearRange, row.key)
            let newRow = { ...row, ...rowValues }
            cashFlowRows.push(newRow)
        })
        return cashFlowRows.map((row, i) => {
            let borderTopStyle = row.topline ? { borderTop: 'solid 1px', minHeight: '17px' } : { borderTop: 'none' }
            let paddingStyle = row.pad ? { paddingTop: '3px', paddingBottom: '20px' } : { paddingTop: '3px' }
            if (props.pdf && paddingStyle.paddingBottom) {
                paddingStyle.paddingTop = '1px'
                paddingStyle.paddingBottom = '8px'
            } else if (props.pdf) {
                paddingStyle.paddingTop = '1px'
                paddingStyle.paddingBottom = '1px'
            }
            let pdfFont = props.pdf ? '11px' : '12px'
            let fontStyle = { textAlign: 'left', fontSize: pdfFont, minWidth: '224px', fontStyle: row.fontStyle }
            let metricNameStyle = { ...fontStyle, ...paddingStyle }
            let hideRow = '';
            let zeroYears = [];
            props.yearRange.forEach(y => { row[y] === 0 && Math.abs(row['you' + y]).toFixed(1) === '0.0' && Math.abs(row['avg' + y]).toFixed(1) === '0.0' ? zeroYears.push(y) : null })
            zeroYears.length === props.yearRange.length ? hideRow = row.copy : null
            let rowChecked = row.displayChart && row.checked && props.editMetrics ? <p style={{ 'margin': 0 }}>&#10004;</p> : ''

            return (
                <tr style={hideRow === row.copy ? { display: 'none' } : borderTopStyle} key={i} onClick={() => props.editMetrics ? props.handleSelectAndLayout('money', 'cash_flow', row.relatedCheckedKey) : null}>
                    {props.pdf ? null : <td>{rowChecked}</td>}
                    <td className='table-copy' style={metricNameStyle}>{row.padding + row.copy}</td>
                    {props.yearRange.map((year, ii) => {
                        let arrowCellClass = 'common-size-arrow';
                        let backgroundStyle = {}
                        let commonSizeValCellClass = 'common-size-cell';
                        let valueCellClass = '';
                        if (ii % 2 === 0 && (!props.pdf || (props.pdf && props.yearRange.length !== 2))) {
                            arrowCellClass += ' money-table-cell';
                            backgroundStyle['backgroundColor'] = '#BFE0AE';
                            commonSizeValCellClass += ' money-table-cell';
                            valueCellClass = 'money-table-cell';
                        }
                        let allStyles = { ...paddingStyle, ...backgroundStyle };
                        try {
                            if (Object.keys(row).length > 3) {
                                let arrowColumn = ''
                                let yearValue = parseMoney(row[year])
                                let youPercentage = _.isNumber(row['you' + year]) ? (row['you' + year] * 100).toFixed(1) + '%' : '-';
                                let avgPercentage = _.isNumber(row['avg' + year]) ? (row['avg' + year] * 100).toFixed(1) + '%' : '-';

                                if (row['status' + year] === 'worse' || row['status' + year] === 'worst') {
                                    arrowColumn = <p className='common-size-arrow-red' style={{ margin: 0, color: 'red' }}>&#9660;</p>
                                } else if (row['status' + year] === 'better' || row['status' + year] === 'best') {
                                    arrowColumn = <p className='common-size-arrow-green' style={{ margin: 0, color: 'green' }}>&#9650;</p>
                                }

                                if (youPercentage === '-' && avgPercentage === '-') {
                                    youPercentage = ''
                                    avgPercentage = ''
                                }

                                if (row.key === 'capital_expenditure' || row.key === 'operating_cash_flow') {
                                    yearValue = ''
                                }

                                return (
                                    <React.Fragment key={Math.random(ii)}>
                                        <td className={valueCellClass} style={allStyles}>
                                            {yearValue}
                                        </td>
                                        <td className={commonSizeValCellClass} style={allStyles}>
                                            {youPercentage}
                                        </td>
                                        {compareWith === 'None'
                                            ? null
                                            : <td className={arrowCellClass} style={allStyles}>
                                                {arrowColumn}
                                            </td>}
                                        {compareWith === 'None'
                                            ? null
                                            : <td className={commonSizeValCellClass} style={allStyles}>
                                                {avgPercentage}
                                            </td>}
                                        <td className='space-cell'></td>
                                    </React.Fragment>
                                )
                            } else {
                                return (
                                    <React.Fragment key={Math.random(ii)}>
                                        <td className={valueCellClass} style={allStyles}></td>
                                        <td className={commonSizeValCellClass} style={allStyles}></td>
                                        {compareWith === 'None' ? null : <td className={arrowCellClass} style={allStyles}></td>}
                                        {compareWith === 'None' ? null : <td className={commonSizeValCellClass} style={allStyles}></td>}
                                        <td className='space-cell'></td>
                                    </React.Fragment>
                                )
                            }
                        } catch { return <td key={ii}></td>; }
                    })}
                    {props.yearRange.length === 2 ? addDeltaColumn(row) : null}
                </tr>
            )
        })
    }

    const addDeltaColumn = (row) => {
        let classNames = props.pdf ? 'common-size-cell' : 'common-size-cell money-table-cell'
        let cellStyle = props.pdf ? {} : { 'backgroundColor': '#BFE0AE' }
        let paddingStyle = row.pad ? { paddingTop: '3px', paddingBottom: '20px' } : { paddingTop: '3px' }
        cellStyle = { ...cellStyle, ...paddingStyle }

        let numberAvailable = _.isNumber(row[props.yearRange[1]]) || _.isNumber(row[props.yearRange[1]])
        if (numberAvailable && !['capital_expenditure', 'operating_cash_flow'].includes(row.key)) {
            return <td className={classNames} style={cellStyle}>{parseMoney(row[props.yearRange[1]] - row[props.yearRange[0]])}</td>
        } else return <td className={classNames} style={cellStyle}></td>
    }

    const renderYearHeaderColumns = (years) => {
        const currentYear = new Date().getFullYear();
        let youPeersRow = [];
        let formatedYearsRow = [];
        let classNames = props.pdf ? 'common-size-cell' : 'common-size-cell money-table-cell'
        let cellStyle = props.pdf ? {} : { 'backgroundColor': '#BFE0AE' }

        years.forEach((y, i) => {
            let arrowCellClass = 'common-size-arrow';
            let backgroundStyle = {}
            let commonSizeValCellClass = 'common-size-cell';
            let valueCellClass = '';
            let youDate, compareDate = '';

            if (y === currentYear) {
                youDate = props.companyYTDDate;
                compareDate = props.aggregateYTDDate;
            } else {
                youDate = props.calendarYearEnd ? `${props.fiscalYearEnd}/${y}` : props.fiscalYearEnd ? `${props.fiscalYearEnd}/${y + 1}` : `12/31/${y}`;
                compareDate = `12/31/${y}`;
            }

            if (i % 2 === 0 && (!props.pdf || (props.pdf && props.yearRange.length !== 2))) {
                arrowCellClass += ' money-table-cell';
                backgroundStyle['backgroundColor'] = '#BFE0AE';
                commonSizeValCellClass += ' money-table-cell';
                valueCellClass = 'money-table-cell'
            }

            youPeersRow.push(
                <React.Fragment key={Math.random(i)}>
                    <td colSpan='2' className={commonSizeValCellClass} style={backgroundStyle}>You</td>
                    {compareWith === 'None' ? null : <td className={arrowCellClass} style={backgroundStyle}></td>}
                    {compareWith === 'None' ? null : <td className={commonSizeValCellClass} style={backgroundStyle}>{compareWith}</td>}
                    <td className='space-cell'></td>
                </React.Fragment>
            )

            formatedYearsRow.push(
                <React.Fragment key={Math.random(i)}>
                    <td colSpan='2' className={commonSizeValCellClass} style={backgroundStyle}>{youDate}</td>
                    {compareWith === 'None' ? null : <td className={arrowCellClass} style={backgroundStyle}></td>}
                    {compareWith === 'None' ? null : <td className={commonSizeValCellClass} style={backgroundStyle}>{compareDate}</td>}
                    <td className='space-cell'></td>
                </React.Fragment>
            )
        })

        return (
            <thead>
                <tr key={Math.random(20)}>
                    {props.pdf ? null : <td className='edit-metrics-cell' onClick={() => props.handleEditMetrics()}>{props.editMetrics ? <i className='fa fa-save hide-on-print' style={{ 'fontSize': '2em' }}></i> : <i className='fa fa-pencil edit-icon hide-on-print' style={{ 'fontSize': '2em' }}></i>}</td>}
                    <td></td>
                    {youPeersRow}
                    {props.yearRange.length === 2 ?
                        <td className={classNames} style={cellStyle}>Δ Delta</td> : null}
                </tr>
                <tr key={Math.random(20)}>
                    {props.pdf ? null : <td></td>}
                    <td></td>
                    {formatedYearsRow}
                    {props.yearRange.length === 2 ?
                        <td className={classNames} style={cellStyle}></td> : null}
                </tr>
            </thead>
        )
    }

    const renderCashFlow = () => {
        if (props.pdf) {
            let pageViewClass = props.yearRange.length === 2 ? 'portrait-view' : 'landscape-view'
            let printViewClass = props.yearRange.length === 2 ? 'money-pdf-portrait' : 'report-pdf-landscape'
            let clientNames = props.realName ? `${props.realName} / ${props.clientName}` : props.clientName
            let yearRangeText = `${props.yearRange[0]} - ${props.yearRange[props.yearRange.length - 1]}`

            return (
                <div className='cashflow-pdf' className={pageViewClass}>
                    <div className={printViewClass}>
                        <div>
                            {props.yearRange.length === 2
                                ? (
                                    <div>
                                        <p className='report-annual-title'>Cash Flow Benchmark Report</p>
                                        <p className='report-company-title'>{clientNames}</p>
                                        <p className='report-year-range-title'>{yearRangeText}</p>
                                    </div>
                                )
                                : <p className='report-annual-title'>{`Cash Flow Benchmark Report, ${clientNames}, ${yearRangeText}`}</p>
                            }
                            <div className='money-pdf-table-div'>
                                <table className='money-pdf-table' id='annual-money-cash-flow-pdf'>
                                    {renderYearHeaderColumns(props.yearRange)}
                                    <tbody>
                                        {buildRows()}
                                        <tr><td className='footer-cell'><i>n = {props.n ? props.n : 0}</i></td></tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <p className='pdf-disclaimer-text'>{`These financial statements have been prepared by ${props.firmName}, and have not been subjected to an audit or review or compilation engagement, and no assurance is provided on them. These financial statements are intended for management’s internal use only. Substantially all disclosures omitted.`}</p>
                    </div>
                </div>
            )
        } else {
            return (
                <div className='money-report-card'>
                    <div className='money-report-container'>
                        <table className='report-table' id='annual-money-cash-flow'>
                            {renderYearHeaderColumns(props.yearRange)}
                            <tbody>
                                {buildRows()}
                            </tbody>
                            <tfoot>
                                <tr><td><i>n = {props.n ? props.n : 0}</i></td></tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            )
        }
    }

    return renderCashFlow()
}

export default MoneyCashFlow;