import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import LoaderGraphic from '../utils/LoaderGraphic';

const RssFeed = (props) => {
    let defaultDetails = {
        id: Math.random(5).toString(),
        type: 'rssFeed',
        loading: false,
        details: {
            id: 0,
            naics_id: '12321323123',
            header: 'Latest News',
            industry_name: 'Latest News',
            number_of_articles: 3,
            styles: {
                header: {
                    backgroundColor: '#69b144',
                    color: '#ffffff'
                }
            },
            width: 2,
            height: 2,
            chartType: null
        },
        error: ''
    };

    let articles = props.articles && props.articles.length > 0 ? props.articles : [];
    let rssFeedDetails = props['rssState'] ? props['rssState']['details'] : defaultDetails['details'];

    const renderArticles = (articles, linkStyle, number_of_articles = 3) => {
        let articlePreview = []
        for (let i = 0; i < number_of_articles; i++) {
            if (props.preview && articles.length === 0) {
                articlePreview.push(
                    <div key={`article-container-${i}`} className='article-container'>
                        <a style={linkStyle}>{`News Article ${i + 1}`}</a>
                        <p>{moment(Date.now()).format("MMMM Do, YYYY")}</p>
                        <p className='preview-paragraph'></p>
                        <p className='preview-paragraph'></p>
                        <p className='preview-paragraph'></p>
                    </div>
                )
            } else {
                let articleData = articles[i]
                if (articleData) {
                    let articleDescription = <div className='article-description'><p>{articleData['description']}</p></div>
                    let htmlRegEx = new RegExp(`<("[^"]*"|'[^']*'|[^'">])*>`);
                    let containsHTML = htmlRegEx.test(articleData['description'])
                    let containsUniCode = articleData['description'].match(/\&(.*?)\;/g)
    
                    if (containsHTML || containsUniCode) {
                        let descriptionHTMLString = articleData['description']
                        articleDescription = <div className='article-description' dangerouslySetInnerHTML={{ __html: descriptionHTMLString }} />
                    }
    
                    if (props && rssFeedDetails) {
                        articlePreview.push(
                            <div key={`article-container-${i}`} className='article-container'>
                                <a className='article-title-link' style={linkStyle} href={articleData['link']} target='_blank'>{articleData['title']} <FontAwesomeIcon icon={faArrowUpRightFromSquare} /></a>
                                <p className='article-date'>{moment(articleData['pubDate']).format("MMMM Do, YYYY")}</p>
                                {articleDescription}
                            </div>
                        )
                    }
                } else {
                    articlePreview.push(
                        <div key={`article-container-${i}`} className='article-container'>
                            Article could be retrieved at this time.
                        </div>
                    )
                }
            }
        }
        return articlePreview

    }

    let rssTitle = rssFeedDetails.industry_name || "Latest News"
    let linkStyle = { color: props.headerStyle["backgroundColor"] }

    return (
        <div>
            <h3 className='user-dash-item-header' style={props.headerStyle}>{rssTitle}</h3>
            <h3 className='hidden-space' style={props.headerStyle}>{rssTitle}</h3>
            {props.loading && articles.length === 0 ? <LoaderGraphic /> : renderArticles(articles, linkStyle, rssFeedDetails.number_of_articles)}
        </div>
    )
}

export default RssFeed;