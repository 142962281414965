import React, { useContext, useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';
import { utils, writeFile } from 'xlsx';
import { Filters } from '../contexts';
import { parseMoney } from '../../utils/index';
import mountNotifications from '../../utils/MountNotifications';
import ahoy from 'ahoy.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { toast } from 'react-toastify';
import Modal from 'react-modal';

const ExportDropdown = (props) => {
  const { currentNewFilters } = useContext(Filters)
  const componentRefs = useRef();
  const [printData, setPrintData] = useState([])
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const toastId = useRef('practice-xlsx-export');
  const toastManageClientsId = useRef('manage-clients-xlsx-export');
  const navbar = window.navbar.navbar;
  const pdfIcon = <FontAwesomeIcon icon={faFilePdf} />
  const excelIcon = <FontAwesomeIcon icon={faFileExcel} />

  const openVideoModal = () => {
    setVideoModalOpen(true);
  }

  const closeVideoModal = () => {
    setVideoModalOpen(false);
  }

  const VideoModal = () => {
    let videoLink = null
    let modalPic = require('../../../../assets/images/PremiumExcelPDF.png')

    const hrefBuilder = (subdomain, path) => {
      const domainSplit = document.location.host.split('.');
      domainSplit[0] = subdomain;
      return location.protocol + '//' + domainSplit.join('.') + path;
    };
    
    const manageSubscriptionsURL = hrefBuilder(navbar.subdomain, '/subscriptions')
    const unlockReportsURL = hrefBuilder(navbar.subdomain, navbar.unlock_reports_url)
    const linkText = navbar.report_credits ? "Unlock Reports" : "Buy More Report Credits"
    const purchaseText = "Buy more report credits."

    return (
      <Modal
        className={{
          base: 'video-modal-content hide-on-print',
          afterOpen: 'video-modal-content_after-open hide-on-print',
          beforeClose: 'video-modal-content_before-close hide-on-print',
        }}
        overlayClassName={{
          base: 'overlay-base hide-on-print',
          afterOpen: 'overlay-base_after-open hide-on-print',
          beforeClose: 'overlay-base_before-close hide-on-print',
        }}
        isOpen={videoModalOpen}
        onRequestClose={closeVideoModal}
        shouldCloseOnOverlayClick={true}
        closeTimeoutMS={0}
        contentLabel='Video About Report Features'
        ariaHideApp={false}
      >
        <div>
          <h1>Upgrade to Access These Reports</h1>
          <img className='premium-pic-popup' src={modalPic}></img>
          {(props.planAccess.print_and_pdf && !navbar.reports_unlocked || props.planAccess.export_to_excel && !navbar.reports_unlocked)
            ? (<div className='unlock-report-container'>
              <p>{`You have ${navbar.report_credits} Report Credits left.`} <a className='btn btn-primary unlock-report-btn' href={unlockReportsURL}>{linkText}</a></p>
            </div>)
            : null}
          <a className='btn btn-primary manage-subscription-btn' href={manageSubscriptionsURL}>Manage Your Subscription</a>
          <p>Click anywhere outside to close this pop-up.</p>
        </div>
      </Modal>
    )
  }

  // the useEffect, useState, and if statement are to hide the caption generated by MUI dataTables on the Report Summary section of the money report
  const manageClientsAOA = [['Client ID', 'Client Name', 'NAICS', 'NAICS Description', 'Reports Unlocked?', 'Partner', 'Office', 'State', 'Revenue', 'Top 5 Opps', 'Data Through', 'Balanced?', 'Type', 'Fiscal Year End', 'Status']]

  // useEffect(() => {
  //   props.page === 'manageClients' ? getAllDataForPrint() : null
  // },[])

  let pdfButtonId;
  switch (props.page) {
    case 'forecast':
      pdfButtonId = 'forecastPdfId'
      break;
    case 'money':
      pdfButtonId = 'moneyPdfId'
      break;
    case 'people':
    case 'customers':
      pdfButtonId = 'peopleCustomersPdfId'
      break;
    case 'industry':
      pdfButtonId = 'industryPdfId'
      break;
    case 'manageClients':
      pdfButtonId = 'manageClientsPdfId'
      break;
    case 'practice':
      pdfButtonId = 'practicePdfId'
      break;
    case 'audit':
      pdfButtonId = 'auditPdfId'
      break;
  }

  const xlsx = {
    audit: buildAuditXlsx,
    practice: buildPracticeXlsx,
    money: buildMoneyXlsx,
    forecast: buildForecastXlsx,
    manageClients: buildManageClientsXlsx
  }

  function create_empty_rows(ws, nrows) {
    var ref = utils.decode_range(ws['!ref']);// get original range
    ref.e.r += nrows;                             // add to ending row
    ws['!ref'] = utils.encode_range(ref);    // reassign row
  }

  function buildAuditXlsx() {
    const wpDetailsTable = document.getElementById('audit-wp-details-table')
    const wpMainTable = document.getElementById('audit-wp-main-table')
    const expenseDetailsTable = document.getElementById('audit-expense-details-table')
    const expenseMainTable = document.getElementById('audit-expense-main-table')
    const auditKfisTable = document.getElementById('annual-kfi-table')

    const wpDetails = utils.table_to_sheet(wpDetailsTable)
    create_empty_rows(wpDetails, 1)
    utils.sheet_add_dom(wpDetails, wpMainTable, { origin: 'A12' })

    for (let i = 0; wpDetails['!rows'].length > i; i++) {
      if (wpDetails['!rows'][i] && wpDetails['!rows'][i]['hidden']) {
        wpDetails['!rows'][i] = undefined
      }
    }

    for (const key in wpDetails) {
      if (!key.includes('!')) {
        if (wpDetails[key].hasOwnProperty('v') && wpDetails[key]['t'] === 's') {
          let splitVals = wpDetails[key]['v'].split('xlsxVal')
          wpDetails[key]['v'] = splitVals[0]
        }
      }
    }

    const expenseDetails = utils.table_to_sheet(expenseDetailsTable)
    create_empty_rows(expenseDetails, 1)
    utils.sheet_add_dom(expenseDetails, expenseMainTable, { origin: 'A10' })

    for (let i = 0; expenseDetails['!rows'].length > i; i++) {
      if (expenseDetails['!rows'][i] && expenseDetails['!rows'][i]['hidden']) {
        expenseDetails['!rows'][i] = undefined
      }
    }

    for (const key in expenseDetails) {
      if (!key.includes('!')) {
        if (expenseDetails[key].hasOwnProperty('v') && expenseDetails[key]['t'] === 's') {
          let splitVals = expenseDetails[key]['v'].split('xlsxVal')
          expenseDetails[key]['v'] = splitVals[0]
        }
      }
    }

    const kfis = utils.table_to_sheet(auditKfisTable)

    const wb = utils.book_new()
    utils.book_append_sheet(wb, wpDetails, 'WP Analytics')
    utils.book_append_sheet(wb, expenseDetails, 'Expense Analytics')
    utils.book_append_sheet(wb, kfis, `KFI's`)
    const name = props.name.replace(/[^A-Z0-9]/ig, "-");
    const realName = props.real_name ? props.real_name.replace(/[^A-Z0-9]/ig, "-") : 'client'
    writeFile(wb, `audit-report-${props.real_name}-${name}-${props.year}.xlsx`)
  }

  function buildForecastXlsx() {
    const name = props.name.replace(/[^A-Z0-9]/ig, "_");
    const all3Years = {
      pnlYear1: {},
      pnlYear2: {},
      pnlYear3: {},
      balYear1: {},
      balYear2: {},
      balYear3: {},
      cashFlowYear1: {},
      cashFlowYear2: {},
      cashFlowYear3: {},
      analysisYear1: {},
      analysisYear2: {},
      analysisYear3: {}
    }
    const wb = utils.book_new()
    const years = [props.year + 1, props.year + 2, props.year + 3]
    years.forEach((year, i) => {
      let pnlTableSheet = utils.table_to_sheet(document.getElementById(`pnl-sheet-${year}`))
      let balTableSheet = utils.table_to_sheet(document.getElementById(`balance_sheet-sheet-${year}`))
      let cashFlowTableSheet = utils.table_to_sheet(document.getElementById(`monthly-forecast-cash-flow-${year}`))
      let analysisTableSheet = utils.table_to_sheet(document.getElementById(`monthly-kfi-table-${year}`))
      all3Years['pnlYear' + i + 1] = pnlTableSheet
      all3Years['balYear' + i + 1] = balTableSheet
      all3Years['cashFlowYear' + i + 1] = cashFlowTableSheet
      all3Years['analysisYear' + i + 1] = analysisTableSheet
      if (all3Years.analysisYear3 !== undefined) {
        utils.book_append_sheet(wb, all3Years['pnlYear' + i + 1], `P and L ${year}`);
        utils.book_append_sheet(wb, all3Years['balYear' + i + 1], `Balance Sheet ${year}`);
        utils.book_append_sheet(wb, all3Years['cashFlowYear' + i + 1], `Cash Flow ${year}`)
        utils.book_append_sheet(wb, all3Years['analysisYear' + i + 1], `Analysis ${year}`)
      }
    })
    writeFile(wb, `${name}-Forecast.xlsx`)
  }

  function buildPracticeXlsx() {
    if (toastId.current === 'practice-xlsx-export') {
      toastId.current = toast.loading('Gathering all your clients...', {
        position: 'top-left',
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
      });
    }
    fetch(`/api/v1/practice_reportable?export_excel=true&naics_string=${props.filters.naics_string}&revenue_band=${props.filters.revenue_band}&region=${props.filters.region}&partner=${props.filters.partner}&office=${props.filters.office}&status=${props.filters.status === 'All' ? '' : props.filters.status}&year=${props.filters.year}&commit=filter`, {
      headers: {
        'X-CSRF-Token': window.token,
      }
    })
      .then((response) => {
        let data = response.json()
        return data;
      })
      .then(data => {
        const naicsAnalysisTable = document.getElementById('naics-analysis-data')
        const clientAvgTable = document.getElementById('clients-average')
        const pnlTable = document.getElementById('new-annual-table-pnl')
        const bsTable = document.getElementById('new-annual-table-balance_sheet')

        const naicsAnalysis = utils.table_to_sheet(naicsAnalysisTable)
        // Line up Peerview Metrics by adding table at cell E1
        const clientAvg = utils.table_to_sheet(clientAvgTable, { origin: 'E1' })
        create_empty_rows(clientAvg, 1)
        let unsubmittedClients = utils.json_to_sheet(data.unsubmitted_children,
          { header: ['focus', 'name', 'real_name', 'report_status', 'naics', 'partners', 'status'] }
        )
        const pnl = utils.table_to_sheet(pnlTable);
        const bs = utils.table_to_sheet(bsTable)

        const wb = utils.book_new()
        utils.book_append_sheet(wb, naicsAnalysis, `NAICS Analysis ${props.year}`)
        // Combine Client Average and Submitted Clients
        utils.book_append_sheet(wb, clientAvg, `Client Analysis ${props.year}`)
        utils.sheet_add_json(clientAvg, data.submitted_children,
          {
            header: ['focus', 'name', 'real_name', 'report_status', 'naics', 'naics_description', 'partners', 'status', 'total_revenue', 'total_revenue_growth_rate', 'total_costs_common_size_revenue', 'total_operating_expenses_common_size_revenue', 'operating_expenses_net_income_common_size_revenue', 'growth', 'profitability', 'liquidity', 'leverage', 'cash_flow', 'state', 'revenue_band'],
            origin: -1
          })
        utils.sheet_add_aoa(clientAvg, [
          ['Focus', 'Client ID', 'Client Name', 'Report', 'NAICS', 'NAICS Description', 'Partner', 'Status', 'Revenue', 'Growth%', 'COGS', 'Total OPEX', 'Net Income', 'Growth', 'Profitability', 'Liquidity', 'Leverage', 'Cash Flow', 'State', 'Revenue Band'],
        ], { origin: 'A4' });
        // Let Unsubmitted Clients be in its own sheet
        if (unsubmittedClients) {
          utils.book_append_sheet(wb, unsubmittedClients, `Unsubmitted Clients ${props.year}`)
          utils.sheet_add_aoa(unsubmittedClients, [
            ['Focus', 'Client ID', 'Client Name', 'Report', 'NAICS', 'Partner', 'Status', 'State'],
          ], { origin: 'A1' });
        }
        utils.book_append_sheet(wb, pnl, `Profit & Loss ${props.year}`)
        utils.book_append_sheet(wb, bs, `Balance Sheet ${props.year}`)
        writeFile(wb, `practice-report-${props.year}.xlsx`)
        toast.dismiss(toastId.current);
      })
      .catch((error) => {
        console.error('Error:', error);
        toast.dismiss(toastId.current);
        toast.error('Sorry, something went wrong please try again later.', {
          position: 'top-left',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
        });
      })
  }

  function buildMoneyXlsx() {
    let reportPeriod = currentNewFilters.report_period
    let displayBy = currentNewFilters.display_columns_by
    let idSelectors
    if (displayBy === 'Years') {
      switch (reportPeriod) {
        case '2 Years':
        case '3 Years':
        case '4 Years':
        case 'All Years':
          idSelectors = {
            pnl: 'new-annual-table-pnl',
            naics_focused: 'new-annual-table-naics_focused',
            balanceSheet: 'new-annual-table-balance_sheet',
            cashFlow: 'annual-money-cash-flow',
            kfi: 'annual-kfi-table'
          }
          break;
        case 'Year to Date':
          idSelectors = {
            pnl: 'pnl-ytd',
            naics_focused: 'naics_focused-ytd',
            balanceSheet: 'balance_sheet-ytd',
            cashFlow: 'cash-flow-ytd',
            kfi: 'kfi-table-ytd'
          }
      }
    } else {
      idSelectors = {
        pnl: 'pnl-monthly-money',
        naics_focused: 'naics_focused-monthy-money',
        balanceSheet: 'balance_sheet-monthly-money',
        cashFlow: 'monthly-cash-flow',
        kfi: `monthly-kfi-table-${props.year}`
      }
    }
    const name = props.name.replace(/[^a-zA-Z0-9]/g, ' ').split(' ').filter((v) => v).join('-')
    const real_name = props.real_name ? `-${props.real_name}` : '';
    const pnlTable = document.getElementById(idSelectors['pnl']);
    const naicsFocusedTable = document.getElementById(idSelectors['naics_focused']);
    const bsTable = document.getElementById(idSelectors['balanceSheet'])
    const cashFlowTable = document.getElementById(idSelectors['cashFlow'])
    const kfiTable = document.getElementById(idSelectors['kfi'])
    const pnl = utils.table_to_sheet(pnlTable);
    const naicsFocused = naicsFocusedTable ? utils.table_to_sheet(naicsFocusedTable) : null;
    const bs = utils.table_to_sheet(bsTable)
    const cashFlow = utils.table_to_sheet(cashFlowTable)
    const kfi = utils.table_to_sheet(kfiTable)
    const wb = utils.book_new()
    utils.book_append_sheet(wb, pnl, `P&L ${displayBy === 'Months' ? props.year : ''}`)
    if (naicsFocused) {
      utils.book_append_sheet(wb, naicsFocused, `NAICS Specific P&L ${displayBy === 'Months' ? props.year : ''}`)
    }
    utils.book_append_sheet(wb, bs, `Balance Sheet ${displayBy === 'Months' ? props.year : ''}`)
    utils.book_append_sheet(wb, cashFlow, `Cash Flow ${displayBy === 'Months' ? props.year : ''}`)
    utils.book_append_sheet(wb, kfi, `KFI's ${displayBy === 'Months' ? props.year : ''}`)
    writeFile(wb, `money-report-${name}${real_name}.xlsx`)
  }

  // build the all clients table for print to PDF for the Manage Clients page
  // because of the pagination, we need to make a new fetch call to get all the clients at once
  function buildManageClientsXlsx() {
    if (toastManageClientsId.current === 'manage-clients-xlsx-export') {
      toastManageClientsId.current = toast.loading('Gathering all your clients...', {
        position: 'top-left',
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
      });
    }
    fetch('/api/v1/companies?export=true&page=1&rows=10000', {
      headers: {
        'X-CSRF-Token': window.token,
      }
    })
      .then((response) => {
        let data = response.json();
        return data;
      })
      .then(data => {
        let formattedData = data.companies_list_paginated.clients.map(c => {
          const isMonthly = c.is_monthly;
          const monthlyMostRecentCalc = c.monthly_most_recent_calc
          let date = '-'
          if (isMonthly === true && monthlyMostRecentCalc) {
            date = monthlyMostRecentCalc;
          } else if (isMonthly === false && c.data_through_year !== null) {
            date = `12/31/${c.data_through_year}`
          } else {
            date;
          }

          let typeData = '-'
          if (isMonthly !== null) {
            typeData = isMonthly ? 'Monthly' : 'Annual';
          } else if (isMonthly === null || isMonthly === undefined) {
            typeData;
          }

          return {
            name: c.name,
            real_name: c.real_name,
            full_naics: c.full_naics,
            naics_description: c.naics_description,
            reports_unlocked: c.unlocked,
            partner: c.partners,
            office: c.office,
            state: c.state,
            revenue: c.total_revenue,
            total_impact: c.total_impact,
            data_through: date,
            balanced: c.balanced,
            type: typeData,
            fiscal_year_end: c.fiscal_year_end,
            status: c.status,
            binder_type: c.binder_type
          }
        })

        setPrintData(formattedData)
        let clients = utils.json_to_sheet(formattedData,
          {
            header: ['name', 'real_name', 'full_naics', 'naics_description', 'reports_unlocked', 'partner', 'office', 'state', 'revenue', 'total_impact', 'data_through', 'balanced', 'type', 'fiscal_year_end', 'status'],
            origin: -1
          })

        utils.sheet_add_aoa(clients, manageClientsAOA, { origin: 'A1' });
        const wb = utils.book_new()
        utils.book_append_sheet(wb, clients, 'Manage Clients')
        // Add Workbook metadata (Props) ensures Excel recognizes the file as a trusted document. (optional)
        wb.Props = {
          Title: 'Manage Clients Export',
          Author: 'Peerview Data',
          CreatedDate: new Date(),
        };

        writeFile(wb, `manage-clients.xlsx`, {
          bookType: 'xlsx',
          type: 'binary',
        });

        toast.dismiss(toastManageClientsId.current);
        ahoy.track('Printed/PDF Report', { report: `Company - ${props.name}, Report Type - ${props.page}` })
      })
      .catch((error) => {
        console.error('Error:', error);
        toast.dismiss(toastManageClientsId.current);
        toast.error('Sorry, something went wrong please try again later.', {
          position: 'top-left',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
        });
      })
  }

  function getStyleSheet() {
    let cssLink = '';
    const allStyleSheets = document.styleSheets;
    for (let i = 0; i < allStyleSheets.length; i++) {
      if (allStyleSheets[i].href) {
        if (allStyleSheets[i].href.includes('/assets/application-')) {
          cssLink = allStyleSheets[i].href
        }
      }
    }
    return cssLink
  }

  const HiddenManageClientsTableForPrint = React.forwardRef((props, ref) => {

    if (props.printOnlyVisibleData.length > 0) {

      let body = props.printOnlyVisibleData.map((row, i) => {

        let date = '-'
        if (row.is_monthly && row.monthly_most_recent_calc) {
          date = row.monthly_most_recent_calc;
        } else if (row.is_monthly === false && row.data_through_year !== null) {
          date = `${row.data_through_year}`
        }

        // while we're looping also push the rows into this array of arrays for Excel parsing
        // two birds one stone
        manageClientsAOA.push([row.name, row.real_name, row.full_naics, row.naics_description, row.unlocked, row.partners, row.office, row.state, parseMoney(row.total_revenue), parseMoney(row.total_impact), date, row.balanced, row.is_monthly ? 'Monthly' : 'Annual', row.fiscal_year_end, row.status])

        return (
          <tr key={Math.random(90)} className='manage-clients-print-row'>
            <td>{row.name}</td>
            <td>{row.real_name}</td>
            <td>{row.full_naics}</td>
            <td>{row.partner}</td>
            <td>{row.office}</td>
            <td>{row.state}</td>
            <td style={{ textAlign: 'right' }}>{parseMoney(row.total_revenue)}</td>
            <td>{date}</td>
            <td>{row.is_monthly ? 'Monthly' : 'Annual'}</td>
            <td>{row.status}</td>
          </tr>
        )
      })

      return (
        <div ref={ref}>
          <div className='manage-clients-pdf'>
            <h1 className='report-table-title hide-not-on-print'>Manage Clients - Peerview Data</h1>
            <table className='manage-clients-print-table'>
              <thead>
                <tr>
                  <th style={{ fontWeight: 'bold' }}>Client ID</th>
                  <th style={{ fontWeight: 'bold' }}>Client Name</th>
                  <th style={{ fontWeight: 'bold' }}>NAICS</th>
                  <th style={{ fontWeight: 'bold' }}>Partner</th>
                  <th style={{ fontWeight: 'bold' }}>Office</th>
                  <th style={{ fontWeight: 'bold' }}>State</th>
                  <th style={{ fontWeight: 'bold' }}>Revenue</th>
                  <th style={{ fontWeight: 'bold' }}>Data Through</th>
                  <th style={{ fontWeight: 'bold' }}>Type</th>
                  <th style={{ fontWeight: 'bold' }}>Status</th>
                </tr>
              </thead>
              <tbody>
                {body}
              </tbody>
            </table>
          </div>
        </div>
      )
    } else return <div></div>
  })

  const excelExport = () => {
    if ((props.page !== 'industry' && !props.planAccess.export_to_excel && props.xlsx && navbar.user_type !== 'Admin') || (props.page !== 'industry' && !navbar.reports_unlocked && props.planAccess.export_to_excel && navbar.user_type !== 'Admin')) {
      return (<div>
        <button id='excel-file-btn' aria-hidden='true' onClick={() => openVideoModal()}>{excelIcon}</button>
      </div>)
    }
    if (navbar.reports_unlocked && props.planAccess.export_to_excel && props.xlsx || navbar.user_type === 'Admin' && props.xlsx) {
      return (<div onClick={() => { xlsx[props.page]() }}>
        <button id='excel-file-btn' aria-hidden='true' onClick={() => ahoy.track('Exported Report', { report: `Company - ${props.name}, Report Type - ${props.page}` })}>{excelIcon}</button>
      </div>)
    }
  }

  const printPDFExport = () => {
    if (['money', 'people', 'customers', 'forecast'].includes(props.page)) { 
      return null
    }

    if ((props.page !== 'industry' && !props.planAccess.print_and_pdf && navbar.user_type !== 'Admin') || (props.page !== 'industry' && !navbar.reports_unlocked && props.planAccess.print_and_pdf && navbar.user_type !== 'Admin')) {
      return (<button id={pdfButtonId} className='print-pdf-btn' aria-hidden='true' onClick={() => openVideoModal()}>{pdfIcon}</button>)
    }
 
    if ((props.page === 'industry' && props.planAccess.print_and_pdf) || (navbar.reports_unlocked && props.planAccess.print_and_pdf) || navbar.user_type === 'Admin') {
      return (<ReactToPrint
        trigger={() => <button id={pdfButtonId} className='print-pdf-btn' aria-hidden='true' >{pdfIcon}</button>}
        content={() => props.page === 'manageClients' ? componentRefs.current : props.printRef.current}
      />)
    }
  }

  return (
    <>
      <VideoModal />
        {printPDFExport()}
        {excelExport()}
      <div>{props.page === 'manageClients' ? <HiddenManageClientsTableForPrint ref={componentRefs} printOnlyVisibleData={props.printOnlyVisibleData} /> : null}</div>
    </>
  )
};
export default ExportDropdown;
