import React, { useState, useEffect } from 'react';
import NavFormIndustryDropdown from '../NavFormIndustryDropdown';

const DropdownFilter = ({ report, calendarYearEnd, current, naicsCode = '', options, currentNewFilters, newFilterOptions, onClick, clas, isMonthlyAvailable, getMonthlyForecastScenario, monthlyCompareWithOptions, youAnnualOptions, youMonthlyOptions, peerAnnualOptions, peerMonthlyOptions }) => {
  // current is the selected options
  // options are the naics, size, region, and year
  // currentNewFiltes is selected compare_with, display_columns_by, report_period
  // newFilterOptions
  const [oldFilters, setOldFilters] = useState({
    code: '',
    region: '',
    revenue_band: '',
    year: '',
    number_of_pros: '',
    datatype: 'Private',
    dataset: 'Peers'
  })

  const [oldFilterOptions, setOldFilterOptions] = useState({
    code: [],
    region: [],
    revenue_band: [],
    year: [],
    number_of_pros: [],
    datatype: ['All', 'Private', 'Public'],
    dataset: ['Peers', 'NSCHBC']
  })

  const [newFilters, setNewFilters] = useState({
    compare_with: 'Peers',
    display_columns_by: 'Years',
    report_period: '2 Years',
  })

  const [newerFilterOptions, setNewerFilterOptions] = useState({
    compare_with: [],
    display_columns_by: [],
    report_period: []
  })

  useEffect(() => {
    if (current) {
      if (report == 'customreport' || report === 'money' || report === 'people' || report === 'customers' || report === 'audit') {
        let displayColumnsBy = currentNewFilters.display_columns_by;
        let reportPeriodOptions = [];
        let yearOptions = options.year;
        let compareWithOptions = [];

        if (report !== 'audit') {
          if (isMonthlyAvailable && displayColumnsBy === 'Years') {
            reportPeriodOptions = ['2 Years', '3 Years', '4 Years', 'All Years', 'Year to Date'];
            compareWithOptions = newFilterOptions['compare_with'];
          } else if (displayColumnsBy === 'Years') {
            reportPeriodOptions = ['2 Years', '3 Years', '4 Years', 'All Years'];
            compareWithOptions = newFilterOptions['compare_with'];
          }

          if (displayColumnsBy === 'Months') {
            reportPeriodOptions = ['Full Year', 'Trailing 12']
            if (report === 'money') {
              compareWithOptions = monthlyCompareWithOptions;
            } else {
              compareWithOptions = peerMonthlyOptions['year'].length === 0 ? ['None', 'YOY'] : ['None', 'YOY', 'Peers'];
            }
            yearOptions = ['Peers', 'Default:Peers'].includes(currentNewFilters.compare_with) ? peerMonthlyOptions['year'] : youMonthlyOptions['year'];
          }
        } else {
          compareWithOptions = newFilterOptions['compare_with']
          yearOptions = ['Peers', 'Default:Peers'].includes(currentNewFilters.compare_with) ? peerAnnualOptions['year'] : youAnnualOptions['year'];
        }

        setOldFilters((prevState) => {
          return {
            ...prevState,
            code: current.code || '',
            region: current.region || '',
            revenue_band: current.revenue_band || '',
            year: current.year || '',
            number_of_pros: current.number_of_pros || ''
          }
        })

        setOldFilterOptions((prevState) => {
          return {
            ...prevState,
            code: options.code || [],
            region: options.region || [],
            revenue_band: options.revenue_band || [],
            year: yearOptions || [],
            number_of_pros: options.number_of_pros || []
          }
        })

        setNewFilters((prevState) => {
          return {
            ...prevState,
            compare_with: currentNewFilters.compare_with || 'Peers',
            display_columns_by: displayColumnsBy || 'Years',
            report_period: currentNewFilters.report_period || '2 Years',
            monthly_forecast_scenarios: currentNewFilters.monthly_forecast_scenarios || ''
          }
        })

        setNewerFilterOptions((prevState) => {
          return {
            ...prevState,
            compare_with: compareWithOptions,
            display_columns_by: isMonthlyAvailable ? ['Years', 'Months'] : ['Years'],
            report_period: reportPeriodOptions,
            monthly_forecast_scenarios: newFilterOptions['monthly_forecast_scenarios'] || []
          }
        })

      } else {
        setOldFilters((prevState) => {
          return {
            ...prevState,
            code: current.code || '',
            region: current.region || '',
            revenue_band: current.revenue_band || '',
            year: current.year || ''
          }
        })

        setOldFilterOptions((prevState) => {
          return {
            ...prevState,
            code: options.code || [],
            region: options.region || [],
            revenue_band: options.revenue_band || [],
            year: options.year || []
          }
        })
      }
    }
  }, [current, options, currentNewFilters, newFilterOptions])


  const updateFilters = ({ target }) => {
    let updated = '';
    updated = target.name == 'year' ? parseInt(target.value) : target.value;
    let yearOptions = []

    switch (target.name) {
      case 'code':
        setOldFilters((prevState) => {
          return { ...prevState, code: updated }
        });
        break;
      case 'region':
        setOldFilters((prevState) => {
          return { ...prevState, region: updated }
        });
        break;
      case 'revenue_band':
        setOldFilters((prevState) => {
          return { ...prevState, revenue_band: updated }
        });
        break;
      case 'datatype':
        setOldFilters((prevState) => {
          return { ...prevState, datatype: updated }
        });
        break;
      case 'dataset':
        setOldFilters((prevState) => {
          return { ...prevState, dataset: updated }
        });
        break;
      case 'year':
        setOldFilters((prevState) => {
          return { ...prevState, year: updated }
        });
        break;
      case 'number_of_pros':
        setOldFilters((prevState) => {
          return { ...prevState, number_of_pros: updated }
        });
        break;
      case 'compare_with':
        if (newFilters.display_columns_by === 'Months') {
          yearOptions = ['Peers', 'Default:Peers'].includes(updated) ? peerMonthlyOptions['year'] : youMonthlyOptions['year']

          setOldFilterOptions((prevState) => {
            return { ...prevState, year: yearOptions }
          })

          setOldFilters((prevState) => {
            return { ...prevState, year: yearOptions[yearOptions.length - 1] }
          });
        }
        if (newFilters.display_columns_by === 'Years') {
          yearOptions = ['Peers', 'Default:Peers'].includes(updated) ? peerAnnualOptions['year'] : youAnnualOptions['year'];

          setOldFilterOptions((prevState) => {
            return { ...prevState, year: yearOptions }
          })

          setOldFilters((prevState) => {
            return { ...prevState, year: yearOptions[yearOptions.length - 1] }
          });
        }

        setNewFilters((prevState) => {
          return { ...prevState, compare_with: updated }
        });
        break;
      case 'display_columns_by':
        let reportPeriodOptions = [];
        let reportPeriodDefault = '';
        let compareWithOptions = [];
        let compareWith = '';

        if (isMonthlyAvailable && updated === 'Years') {
          reportPeriodOptions = ['2 Years', '3 Years', '4 Years', 'All Years', 'Year to Date']
          reportPeriodDefault = '2 Years';
          compareWithOptions = newFilterOptions['compare_with'];
          compareWith = 'Peers';
          yearOptions = ['Peers', 'Default:Peers'].includes(compareWith) ? peerAnnualOptions['year'] : youAnnualOptions['year'];

          setOldFilterOptions((prevState) => {
            return { ...prevState, year: yearOptions }
          })

          setOldFilters((prevState) => {
            return { ...prevState, year: yearOptions[yearOptions.length - 1] }
          })
        } else if (updated === 'Years') {
          reportPeriodOptions = ['2 Years', '3 Years', '4 Years', 'All Years'];
          reportPeriodDefault = '2 Years';
          compareWithOptions = newFilterOptions['compare_with'];
          compareWith = 'Peers';
          yearOptions = ['Peers', 'Default:Peers'].includes(compareWith) ? peerAnnualOptions['year'] : youAnnualOptions['year'];

          setOldFilterOptions((prevState) => {
            return { ...prevState, year: yearOptions }
          })

          setOldFilters((prevState) => {
            return { ...prevState, year: yearOptions[yearOptions.length - 1] }
          })
        } else if (updated === 'Months') {
          reportPeriodOptions = ['Full Year', 'Trailing 12']
          reportPeriodDefault = 'Full Year';

          if (report === 'money') {
            compareWithOptions = monthlyCompareWithOptions
            compareWith = 'Default:YOY';
          } else {
            compareWithOptions = peerMonthlyOptions['year'].length ? ['None', 'YOY'] : ['None', 'YOY', 'Peers'];
            compareWith = 'YOY';
          }

          yearOptions = ['Peers', 'Default:Peers'].includes(compareWith) ? peerMonthlyOptions['year'] : youMonthlyOptions['year']

          setOldFilterOptions((prevState) => {
            return { ...prevState, year: yearOptions }
          })

          setOldFilters((prevState) => {
            return { ...prevState, year: yearOptions[yearOptions.length - 1] }
          })
        }

        setNewFilters((prevState) => {
          return { ...prevState, compare_with: compareWith, display_columns_by: updated, report_period: reportPeriodDefault, monthly_forecast_scenarios: '' }
        });

        setNewerFilterOptions((prevState) => {
          return { ...prevState, compare_with: compareWithOptions, report_period: reportPeriodOptions }
        })
        break;
      case 'report_period':
        let selectedYear = current.year;
        compareWithOptions = newFilterOptions['compare_with'];

        if (updated === 'Year to Date') {
          compareWithOptions = ['None', 'Peers']
          compareWith = 'Peers'
          yearOptions = ['Peers', 'Default:Peers'].includes(compareWith) ? peerMonthlyOptions['year'] : youMonthlyOptions['year']
          selectedYear = yearOptions[yearOptions.length - 1]

          setOldFilterOptions((prevState) => {
            return { ...prevState, year: yearOptions }
          })

        } else if (newFilters.display_columns_by === 'Years' && updated !== 'Year to Date') {
          compareWithOptions = ['None', 'Peers', 'Practice']
          compareWith = 'Peers'
          yearOptions = ['Peers', 'Default:Peers'].includes(compareWith) ? peerAnnualOptions['year'] : youAnnualOptions['year']
          selectedYear = yearOptions[yearOptions.length - 1]

          setOldFilterOptions((prevState) => {
            return { ...prevState, year: yearOptions }
          })
        } else if (newFilters.display_columns_by === 'Months' && updated !== 'Year to Date') {
          if (report === 'money') {
            compareWithOptions = monthlyCompareWithOptions
            compareWith = updated === 'Full Year' || updated === 'Trailing 12' ? 'Default:YOY' : 'YOY';
            yearOptions = ['Peers', 'Default:Peers'].includes(compareWith) ? peerMonthlyOptions['year'] : youMonthlyOptions['year']
            selectedYear = yearOptions[yearOptions.length - 1]

            setOldFilterOptions((prevState) => {
              return { ...prevState, year: yearOptions }
            })
          }
        }

        setOldFilters((prevState) => {
          return { ...prevState, year: selectedYear }
        })

        setNewFilters((prevState) => {
          return { ...prevState, compare_with: compareWith, report_period: updated }
        });

        setNewerFilterOptions((prevState) => {
          return { ...prevState, compare_with: compareWithOptions }
        })
        break;
      case 'forecast_scenario':
        setNewFilters((prevState) => {
          return { ...prevState, monthly_forecast_scenarios: updated }
        });

        setNewFilters((prevState) => {
          return { ...prevState, compare_with: 'Forecast Scenario' }
        });
        break;
    }
  };

  const handleAuditCompareScenario = (value) => {
    setNewFilters((prevState) => {
      return { ...prevState, compare_with: value }
    });
    getMonthlyForecastScenario(value)
  }

  const buildFilter = ({ list, selected, key, label, styleName, optionCallback }) => {
    if (list) return (
      <div className={styleName} id={key + '-filter'}>
        <label htmlFor={key} className='text-left'>{label}</label>
        <select id={key} name={key} value={selected} onChange={updateFilters} className={`${styleName} ${key === 'code' ? 'industry' : ''}`}>
          {list.map((filter, i) => {
            if (optionCallback) return optionCallback(filter, i);
            let finalVal = filter
            if (key === 'number_of_pros') {
              if (filter == 0) {
                finalVal = 'All'
              }
              if (filter == 4) {
                finalVal = '4+'
              }
            }

            if (key === 'year') {
              if (!calendarYearEnd) { finalVal += 1 }
            }

            return (
              <option key={i} value={filter} className='option-dropdown-filter'>
                {finalVal}
              </option>
            );
          })}
        </select>
      </div>
    )
  }

  const buildFilterWGroups = ({ list, selected, key, label, styleName }) => {
    let defaultSelected = selected === 'YOY' ? 'Default:YOY' : selected === 'Peers' ? 'Default:Peers' : selected;

    const handleFilterGroups = (e) => {

      if (report === 'audit') {
        handleAuditCompareScenario(e.target.value)
      } else {
        updateFilters(e)
      }
    }

    if (list) {
      let arrList = list

      return (
        <div className={styleName} id={key + '-filter'}>
          <label htmlFor={key} className='text-left'>{label}</label>
          <select name={key} value={defaultSelected} onChange={(e) => handleFilterGroups(e)} className={`${styleName} ${key === 'code' ? 'industry' : ''}`}>
            {arrList.map((filter, i) => {
              let groupLabel = filter[0];

              return (
                <optgroup key={`optgroup-${i}`} label={groupLabel}>
                  {
                    filter[1].map((filterOption, j) => {
                      return (
                        <option key={`option-${i}-${j}`} value={`${groupLabel}:${filterOption}`} className='option-dropdown-filter'>
                          {filterOption}
                        </option>
                      )
                    })
                  }
                </optgroup>
              )
            })}
          </select>
        </div>
      )
    }
  }

  if (!current) return <div></div>;

  let reportBtnClass;
  if (report === 'money' || report === 'industry' || report === 'customreport') {
    reportBtnClass = 'run-report-btn'
  } else if (report === 'people') {
    reportBtnClass = 'run-report-btn-people'
  } else if (report === 'customers') {
    reportBtnClass = 'run-report-btn-customers'
  } else if (report === 'audit') {
    reportBtnClass = 'run-report-btn-audit'
  }

  clas = report == 'audit' ? 'audit-dropdown-filters' : clas
  let naics62Code = naicsCode.startsWith('621');

  return (
    <form id='dropdown-filter-id' onSubmit={(e) => onClick(e, oldFilters, newFilters)}>
      <div className={'pvd-filter ' + clas}>
        {oldFilters && <NavFormIndustryDropdown selectedCode={oldFilters.code} codeOptions={oldFilterOptions.code} setSelectedFilters={setOldFilters} />}
        {buildFilter({
          list: ['All', 'Private', 'Public'],
          selected: oldFilters.datatype,
          key: 'datatype',
          label: 'Data Type',
          styleName: 'datatype-filter filter-item-side-bar',
        })}
        {buildFilter({
          list: oldFilterOptions.revenue_band,
          selected: oldFilters.revenue_band,
          key: 'revenue_band',
          label: 'Revenue',
          styleName: 'revenue-band-filter filter-item-side-bar',
        })}
        {buildFilter({
          list: oldFilterOptions.region,
          selected: oldFilters.region,
          key: 'region',
          label: 'Region',
          styleName: 'region-filter filter-item-side-bar',
        })}
        {report === 'industry' ? buildFilter({
          list: ['Peers', 'NSCHBC'],
          selected: oldFilters.dataset,
          key: 'dataset',
          label: 'Dataset',
          styleName: 'dataset-filter filter-item-side-bar',
        }) : null}
        {report !== 'customreport' && naics62Code ? buildFilter({
          list: oldFilterOptions.number_of_pros,
          selected: oldFilters.number_of_pros,
          key: 'number_of_pros',
          label: '# Doctors',
          styleName: 'number-of-pros-filter filter-item-side-bar',
        }) : null}
        {report !== 'customreport' && report !== 'audit' && report !== 'forecast' && report !== 'industry' ?
          buildFilter({
            list: newerFilterOptions.display_columns_by,
            selected: newFilters.display_columns_by,
            key: 'display_columns_by',
            label: 'Display columns by',
            styleName: 'display-columns-filter filter-item-side-bar',
          }) : null}
        {report !== 'customreport' && report !== 'audit' && report !== 'forecast' && report !== 'industry' ?
          buildFilter({
            list: newerFilterOptions.report_period,
            selected: newFilters.report_period,
            key: 'report_period',
            label: 'Report Period',
            styleName: 'report-period-filter filter-item-side-bar',
          }) : null}
        {(newFilters.display_columns_by === 'Years' && report === 'money') || report === 'people' || report === 'customers' ?
          buildFilter({
            list: newerFilterOptions.compare_with,
            selected: newFilters.compare_with,
            key: 'compare_with',
            label: 'Compare with',
            styleName: 'compare-with-filter filter-item-side-bar',
          }) : null}
        {(newFilters.display_columns_by === 'Months' && report === 'money') ?
          buildFilterWGroups({
            list: monthlyCompareWithOptions,
            selected: newFilters.compare_with,
            key: 'compare_with',
            label: 'Compare with',
            styleName: 'compare-with-filter filter-item-side-bar',
          }) : null}
        {report === 'audit' ?
          buildFilterWGroups({
            list: newerFilterOptions.compare_with,
            selected: newFilters.compare_with,
            key: 'compare_with',
            label: 'Compare with',
            styleName: 'compare-with-filter filter-item-side-bar',
          }) : null}
        {report !== 'forecast' &&
          buildFilter({
            list: oldFilterOptions.year,
            selected: oldFilters.year,
            key: 'year',
            label: calendarYearEnd ? 'Calendar Year' : 'Fiscal Year',
            styleName: 'year-filter filter-item-side-bar',
          })}
        <button className={reportBtnClass} type='submit'>Run Report</button>
      </div>
    </form>
  );
};
export default DropdownFilter;