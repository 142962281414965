import React, { useEffect, useState, useRef } from 'react';
import { useTour } from '@reactour/tour';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey, faAnglesLeft } from "@fortawesome/free-solid-svg-icons";
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import MoneyReport from './MoneyReport';
import PeopleReport from './PeopleReport';
import CustomersReport from './CustomersReport';
import AuditReport from './AuditReport/AuditReport';
import Dropzone from './InsufficientReportAnnual/DropzoneComponentForInsufficientReportAnnual';
import InsufficientReport from './InsufficientReportAnnual/InsufficientReport';
import SurveyModal from './SurveyModal';
import mountNotifications from '../utils/MountNotifications';
import ExportDropdown from './utils/ExportDropdown';
import ExportPDFandPrint from '../ExportPDFandPrint';

const Report = (props) => {
  const componentRefs = useRef();
  const { isOpen, currentStep, steps, setIsOpen, setCurrentStep } = useTour();
  const [fdu, setFdu] = useState('');
  const [activePopover, setActivePopover] = useState(null)
  const subdomain = props.subdomain

  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
    if (props.tourData.tracker_id && !props.tourData.tour_taken) {
      setIsOpen(true)
    } else if (props.page === 'money' && props.tourData.tracker_id && !props.tourData.new_features_tour_taken) {
      setCurrentStep(22)
      setIsOpen(true)
    }
  }, []);

  const handleMouseEnter = (item) => {
    setActivePopover(item);
  };

  const handleMouseLeave = (item) => {
    if (item === 'leave') {
      setActivePopover(null);
    }
  };

  if (props.pending_calcs) {
    subscribe();
  }

  const notify = (message) => {
    mountNotifications()(message);
  };

  const subscribe = () => {
    if (modalData) {
      App[`job_${modalData.id}`] = App.cable.subscriptions.create(
        {
          channel: 'JobsChannel',
          job_type: 'CompanyCalculationWorker',
        },
        {
          received: (res) => {
            if (res.error) {
              console.log(res);
            } else if (res.finish) {
              mountNotifications(`Calculations Finished for ${modalData.name}`);
              updateStep(modalData.id);
            }
          },
        }
      );
    }
  };

  const hrefBuilder = (subdomain, path) => {
    const domainSplit = document.location.host.split('.');
    domainSplit[0] = subdomain;
    return location.protocol + '//' + domainSplit.join('.') + path;
  };

  const addFdu = (fdu) => {
    setFdu(fdu);
  };

  const updateStep = (id) => {
    fetch(`/companies/${id}/step`)
      .then((response) => {
        return response.json();
      })
  };

  const InsufficientData = () => {
    return (
      <div className='insufficient-data'>
        <h3 className='text-center' id={props.page + '-insufficient-data'}>
          You do not have sufficient data to generate a <strong>{props.year} {props.page} report</strong>.
          <br /><br />
        </h3>
        {props.userType !== 'Freemium'
          ? <h3 className='text-center'>Please prepare a <strong>{props.year} trial balance</strong> and upload or drag and drop the file below. Then you can map your file. You can also connect to QuickBooks Online.</h3>
          : <h3 className='text-center'>Please prepare a <strong>{props.year} trial balance</strong>. You can connect to QuickBooks Online and then map your file.</h3>
        }
        <div className='collapse in' id='collapseDropZone' role='region' aria-labelledby='Dropzone area to upload excel files, map files, or connect to quickbooks'>
          <div className='card card-body'>
            {
              props.userType !== 'Freemium'
                ? (
                  <Dropzone
                    name={props.name}
                    subdomain={subdomain}
                    id={props.id}
                    notify={notify}
                    addFdu={addFdu}
                    hrefBuilder={hrefBuilder}
                    fdu={fdu}
                  />
                )
                : null
            }
            <InsufficientReport {...props} fdu={fdu} />
          </div>
        </div>
      </div>
    )
  };

  function renderReport(page = props.page) {
    const Component = {
      money: MoneyReport,
      people: PeopleReport,
      customers: CustomersReport,
      audit: AuditReport
    }[page];

    if (props.insufficientData) return <InsufficientData />;

    return (
      <Component ref={componentRefs} {...props} year={props.year} yearRange={props.yearRange} page={page} />
    )
  };

  return (
    <div className='money-report-component'>
      <div id='notification-container-target'></div>
      <div id={`client-modal-${props.id}`}></div>
      <div id='sticky-topnav-left-btns'>
        {['money', 'people', 'customers'].includes(props.page)
          ? <ExportPDFandPrint
            {...props}
            planAccess={props.planAccess}
            year={props.year}
            yearRange={props.yearRange}
          />
          : null
        }
        <ExportDropdown
          {...props}
          xlsx={['money', 'audit', 'kfi', 'forecast'].includes(props.page)}
          printRef={componentRefs}
          planAccess={props.planAccess}
          year={props.year}
          yearRange={props.yearRange}
        />
      </div>
      <div id='sticky-topnav-right-btns'>
        <button className='hide-on-print' onClick={() => setIsOpen(true)}><FontAwesomeIcon icon={faQuestionCircle} /></button>
      </div>
      <div id='sticky-side-btns'>
        <span
          className='filters-btn-span hide-on-print'
          onMouseEnter={() => handleMouseEnter('filters')}
          onMouseLeave={() => handleMouseLeave('leave')}
        >
          <button className='filters-btn'><FontAwesomeIcon icon={faAnglesLeft} onClick={() => props.openFiltersModal()} /></button>
          {activePopover === 'filters' && (
            <div className='filters-popover'>
              Display Filters
            </div>)}
        </span>
        {props.page !== 'audit'
          ? <SurveyModal
            modalIsOpen={props.surveyModalIsOpen}
            naicsCode={props.naicsCode}
            surveyTaken={props.surveyTaken}
            handleSurveySubmit={props.handleSurveySubmit}
            handleSurveyModal={props.handleSurveyModal}
            surveyData={props.surveyData}
            pageTitle={props.pageTitle}
            page={props.page}
            year={props.year}
          /> : null}
      </div>
      {props.insufficientPeerData && props.currentNewFilters.compare_with === 'Trailing 12' ? <p style={{ 'textAlign': 'center' }}>Warning! Missing some monthly data in this report period for the Peers.</p> : null}
      {renderReport()}
    </div>
  );
};

export default Report;
