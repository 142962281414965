import React, { useContext } from 'react';
import { Data, Filters } from '../contexts';
import AccountTree from '../../utils/AccountTree';
import { CommonSizeArrows } from './MoneyHelpers';
import MoneyAnnualTable from './MoneyAnnualTable';
import AuditWPAnalytics from '../AuditReport/AuditWPAnalytics';
import AuditExpenseAnalytics from '../AuditReport/AuditExpenseAnalytics';

const compareWithRowKeys = {
  'None': null,
  'Practice': 'practice_avg',
  'Peers': 'avg',
  'NSCHBC': 'nschbc'
}
const doctorsNAICS = ['621', '6211', '6213'];

const MoneyReportSheets = (props) => {
  let calcs
  let allFilters
  let currentNewFilters, newFilterOptions, compareToAvg
  if (props.page === 'practice') {
    calcs = props.calcs
    compareToAvg = 'avg'
  } else {
    const useData = useContext(Data);
    calcs = useData.calcs
    allFilters = useContext(Filters);
    currentNewFilters = allFilters.currentNewFilters;
    newFilterOptions = allFilters.newFilterOptions;
    compareToAvg = compareWithRowKeys[currentNewFilters.compare_with]
  }

  let compareWithSplit, compareWith = null;
  if (props.page === 'audit') {
    compareWith = currentNewFilters.compare_with === 'YOY' ? 'Final PY' : currentNewFilters.compare_with
    if (currentNewFilters.compare_with === 'YOY' || currentNewFilters.compare_with === 'YOY:Default') {
      compareWith = 'Final PY'
    } else {
      let colonExists = currentNewFilters.compare_with.includes(':')
      compareWithSplit = colonExists ? currentNewFilters.compare_with.split(':') : currentNewFilters.compare_with
      compareWith = colonExists ? compareWithSplit[0] : currentNewFilters.compare_with
      compareWith = compareWith === 'Peers' ? 'Peers PY' : compareWith
    }
  }

  const mappedTotal = (row, years, you, avg, key) => {
    let totalObj = {}
    totalObj['key'] = key
    years.forEach(year => {
      let youTotalRevenue = you['total_revenue'][year]
      let compareTotalRevenue = avg['total_revenue'] ? avg['total_revenue'][year] : 0
      let youSum = 0;
      let avgSum = 0

      if (key === 'salaries_non_owners') {
        let youTotalCompensation = you['total_compensation'][year]
        let compareTotalCompensation = avg['total_compensation'] ? avg['total_compensation'][year] : 0
        let youSalariesDoctor = 0
        let compareSalariesDoctor = 0

        if (props.naicsCode && props.naicsCode.startsWith('6212')) {
          youSalariesDoctor = you['dentist'][year] ? you['dentist'][year] : 0
          compareSalariesDoctor = avg['dentist'][year] ? avg['dentist'][year] : 0
        } else if (props.naicsCode && doctorsNAICS.some(naics => props.naicsCode.startsWith(naics))) {
          youSalariesDoctor = you['total_compensation_doctor'] ? you['total_compensation_doctor'][year] : 0
          compareSalariesDoctor = avg['total_compensation_doctor'] ? avg['total_compensation_doctor'][year] : 0
        }

        youSum = youTotalCompensation - youSalariesDoctor
        avgSum = compareTotalCompensation - compareSalariesDoctor
      } else {
        row.map_keys.forEach((c) => {
          youSum += you[c] ? you[c][year] : 0
          avgSum += avg[c] ? avg[c][year] : 0
        })
      }

      totalObj[year] = youSum;
      totalObj['you' + year] = (youSum / youTotalRevenue) * 100;
      totalObj['avgVal' + year] = avgSum;
      totalObj['avg' + year] = (avgSum / compareTotalRevenue) * 100;
      let yearStatusKey = 'status' + year
      totalObj[yearStatusKey] = CommonSizeArrows(totalObj['you' + year], totalObj['avg' + year], key)
    })
    return totalObj
  }

  const totalNAICSFocused = (rows, years, you, avg, key) => {
    let childrenObjs = rows.filter(r => r.parentKey === key)
    let totalObj = {}
    let childrenValues = []
    childrenObjs.forEach((c) => {
      childrenValues.push(mappedTotal(c, years, you, avg, c.key))
    })

    years.forEach(year => {
      let youTotalRevenue = you['total_revenue'][year]
      let compareTotalRevenue = avg['total_revenue'] ? avg['total_revenue'][year] : 0
      let youSum = 0;
      let avgSum = 0
      childrenValues.forEach((c) => {
        youSum += c[year] ? c[year] : 0
        avgSum += c['avgVal' + year] ? c['avgVal' + year] : 0
      })
      totalObj[year] = youSum;
      totalObj['you' + year] = (youSum / youTotalRevenue) * 100;
      totalObj['avgVal' + year] = avgSum;
      totalObj['avg' + year] = (avgSum / compareTotalRevenue) * 100;
      let yearStatusKey = 'status' + year
      totalObj[yearStatusKey] = CommonSizeArrows(totalObj['you' + year], totalObj['avg' + year], key)
    })
    return totalObj
  }

  const DentistDoctorPNLVals = (rows, calcs, years, row) => {
    const you = calcs.you;
    const avg = currentNewFilters.compare_with === 'NSCHBC' ? calcs.nschbc : calcs.docs_avg;
    let rowObj = null;
    row.copyWithPadding = row.padding + row.copy
    let yearValues;

    if (row.total) {
      rowObj = totalNAICSFocused(rows, years, you, avg, row.key)
    } else if (row.key === 'gross_profit') {
      rowObj = {}
      let totalDirectCosts = totalNAICSFocused(rows, years, you, avg, 'total_direct_costs')
      years.forEach(year => {
        let youTotalRevenue = you['total_revenue'][year]
        let compareTotalRevenue = avg['total_revenue'] ? avg['total_revenue'][year] : 0
        rowObj[year] = youTotalRevenue - totalDirectCosts[year]
        rowObj['you' + year] = (rowObj[year] / youTotalRevenue) * 100
        rowObj['avgVal' + year] = compareTotalRevenue - totalDirectCosts['avgVal' + year]
        rowObj['avg' + year] = (rowObj['avgVal' + year] / compareTotalRevenue) * 100
        let yearStatusKey = 'status' + year
        rowObj[yearStatusKey] = CommonSizeArrows(rowObj['you' + year], rowObj['avg' + year], row.key)
      })
    } else if (row.key === 'operating_expenses_net_income') {
      rowObj = {}
      let totalDirectCosts = totalNAICSFocused(rows, years, you, avg, 'total_direct_costs')
      let totalAdminCosts = totalNAICSFocused(rows, years, you, avg, 'total_administrative_costs')
      years.forEach(year => {
        let youTotalRevenue = you['total_revenue'][year]
        let compareTotalRevenue = avg['total_revenue'] ? avg['total_revenue'][year] : 0
        rowObj[year] = youTotalRevenue - totalDirectCosts[year] - totalAdminCosts[year]
        rowObj['you' + year] = (rowObj[year] / youTotalRevenue) * 100
        rowObj['avgVal' + year] = compareTotalRevenue - totalDirectCosts['avgVal' + year] - totalAdminCosts['avgVal' + year]
        rowObj['avg' + year] = (rowObj['avgVal' + year] / compareTotalRevenue) * 100
        let yearStatusKey = 'status' + year
        rowObj[yearStatusKey] = CommonSizeArrows(rowObj['you' + year], rowObj['avg' + year], row.key)
      })
    } else if (row.key === 'salaries_non_owners') {
      rowObj = {}
      years.forEach(year => {
        let youTotalRevenue = you['total_revenue'][year]
        let compareTotalRevenue = avg['total_revenue'] ? avg['total_revenue'][year] : 0
        let youTotalCompensation = you['total_compensation'][year]
        let compareTotalCompensation = avg['total_compensation'] ? avg['total_compensation'][year] : 0
        let youSalariesDoctor = 0
        let compareSalariesDoctor = 0

        if (props.naicsCode && props.naicsCode.startsWith('6212')) {
          youSalariesDoctor = you['dentist'][year] ? you['dentist'][year] : 0
          compareSalariesDoctor = avg['dentist'][year] ? avg['dentist'][year] : 0
        } else if (props.naicsCode && doctorsNAICS.some(naics => props.naicsCode.startsWith(naics))) {
          youSalariesDoctor = you['total_compensation_doctor'] ? you['total_compensation_doctor'][year] : 0
          compareSalariesDoctor = avg['total_compensation_doctor'] ? avg['total_compensation_doctor'][year] : 0
        }

        rowObj[year] = youTotalCompensation - youSalariesDoctor
        rowObj['you' + year] = (rowObj[year] / youTotalRevenue) * 100

        rowObj['avgVal' + year] = compareTotalCompensation - compareSalariesDoctor
        rowObj['avg' + year] = (rowObj['avgVal' + year] / compareTotalRevenue) * 100

        let yearStatusKey = 'status' + year
        rowObj[yearStatusKey] = CommonSizeArrows(rowObj['you' + year], rowObj['avg' + year], row.key)
      })
    } else {
      if (row.key) {
        rowObj = mappedTotal(row, years, you, avg, row.key)
      } else {
        return row;
      }
    }

    return rowObj;
  }

  function buildRowData(sheet, commonKey) {
    let allRows = []
    if (props.page === 'audit' && props.reportType === 'wp-analytics') {
      let auditRows = [...props.balance_sheet_rows, { emptyRow: true }, ...props.pnl_rows]
      auditRows.forEach((row) => {
        let commonKey = row.sheet === 'pnl' ? 'revenue' : 'assets'
        row.emptyRow ? allRows.push(row) : allRows.push(buildRow({ ...row, commonKey }))
      })
    } else if (props.page === 'audit' && props.reportType === 'expense-analytics') {
      let fixedKeys = ['total_people_costs', 'total_compensation', 'total_taxes_and_benefits', 'payroll_taxes', 'health_insurance', 'retirement_plan', 'total_corporate_culture', 'subcontractor_expenses', 'people_misc_professional_fees', 'insurance_expenses', 'rent_and_facilities_costs', 'depreciation_and_amortization']
      let auditFixedRows = [{ header: true, copy: 'Fixed Expenses:', header_key: 'fixed_expenses_header', sheet: 'pnl' }]
      let auditVariableRows = [{ header: true, copy: 'Variable Expenses:', header_key: 'variable_expenses_header', sheet: 'pnl' }]
      let nonExpenseKeys = ['gross_profit', 'total_operating_expenses_header', 'total_operating_expenses', 'operating_profit', 'operating_expenses_net_income']
      props.pnl_rows.forEach((row) => {
        if (fixedKeys.includes(row.key) && !row.header || fixedKeys.includes(row.parentKey) && !row.header) {
          let expenseType = 'fixed'
          auditFixedRows.push(buildRow({ ...row, commonKey, expenseType }))
        } else if (!nonExpenseKeys.includes(row.key) && !row.header) {
          let expenseType = 'variable'
          auditVariableRows.push(buildRow({ ...row, commonKey, expenseType }))
        }
      })
      allRows = [...auditFixedRows, ...auditVariableRows, { header: false, copy: 'Total', key: 'total_all_expenses', sheet: 'pnl' }]

    } else if (props.page === 'naics_focused' || props.page === 'naics_focused-moneypdf') {
      props.rows.forEach((row) => {
        let rowValues = DentistDoctorPNLVals(props.rows, calcs, props.yearRange, row)
        let newRow = { ...row, ...rowValues }
        allRows.push(newRow)
      })
    } else {
      if (props.page === 'money' && sheet === 'pnl' && props.naicsCode && props.naicsCode.startsWith('621')) {
        const healthCareRevenueChildKeys = [
          { key: 'total_revenue_gross_charges', copy: 'Gross Charges' },
          { key: 'total_revenue_adjustments', copy: 'Adjustments' },
          { key: 'total_revenue_net_practice charges', copy: 'Net Practice Charges' },
          { key: 'total_revenue_collections', copy: 'Collections' }
        ]

        healthCareRevenueChildKeys.forEach(k => {
          allRows.push(buildRow({
            key: k.key,
            copy: k.copy,
            header: false,
            padding: '',
            parentKey: 'total_revenue',
            sheet: 'pnl',
            commonKey: 'revenue',
            alwaysDisplayRow: true
          }))
        })
        allRows.push(buildRow({
          key: 'space_before_revenue',
          copy: '',
          header: false,
          padding: '',
          sheet: 'pnl',
          emptyRow: true
        }))
      }

      if (props.rows) {
        props.rows.forEach((row) => {
          allRows.push(buildRow({ ...row, commonKey }))
        })
      } else {
        const accountTree = new AccountTree({
          tree: props.tree[sheet],
          page: props.page === 'practice' || props.page === 'moneypdf' ? 'money' : props.page,
          sheet: props.sheet
        })
        accountTree.forEach((row) => {
          allRows.push(buildRow({ ...row, commonKey }))
        })
      }
    }
    return allRows.filter(Boolean)
  };

  function buildRow({ key = '', relatedCheckedKey = '', copy = '', header = false, header_is_total = false, padding = '', parentKey = '', forecast = '', checked = '', displayChart = '', chartCalculationName = '', sheet = '', commonKey = '', expenseType = null, emptyRow = false, topline = null, alwaysDisplayRow = false }) {
    const row = {}

    if (emptyRow) {
      row.emptyRow = emptyRow
    } else {
      row.relatedCheckedKey = relatedCheckedKey
      row.copy = copy
      row.copyWithPadding = padding + copy
      row.chartCalculationName = chartCalculationName
      row.checked = checked
      row.displayChart = displayChart
      row.sheet = sheet
      row.expenseType = expenseType
      row.topline = topline
      row.alwaysDisplayRow = alwaysDisplayRow

      // forecast is from the tree and has line styles for certain keys that will go across the sheet
      if (forecast) {
        row.forecast = forecast
      }

      if (!header || header_is_total) {
        // table data
        props.yearRange.forEach((year, i) => {
          try {
            if (props.commonSizeTable) {
              row[year] = calcs[compareToAvg][key + '_common_size_' + commonKey][year] * 100
            } else {
              row['key'] = key
              row['commonKey'] = commonKey

              if (props.page === 'audit' && i === 0 && currentNewFilters['compare_with'] !== 'YOY' && currentNewFilters['compare_with'] !== 'YOY:Default' && currentNewFilters['compare_with'] !== 'Peers:Default' && currentNewFilters['compare_with'] !== 'Peers') {
                row[year] = props.forecastVals[key] ? props.forecastVals[key] : null;
              } else {
                row[year] = calcs.you[key] ? calcs.you[key][year] : 0;
              }

              ['you', compareToAvg].forEach(c => {
                if (c) {
                  let yearKey = ['practice_avg', 'nschbc'].includes(c) ? 'avg' + year : c + year;

                  if (key === 'operating_expenses_net_income' && sheet === 'balance_sheet') {
                    row[yearKey] = (calcs[c]['operating_expenses_net_income'][year] / calcs[c]['total_assets'][year]) * 100
                  } else {
                    if (calcs[c][key + '_common_size_' + row.commonKey] === undefined || calcs[c][key + '_common_size_' + row.commonKey][year] === null) {
                      row[yearKey] = 0
                    } else {
                      if (isNaN(calcs[c][key + '_common_size_' + row.commonKey][year])) {
                        row[yearKey] = 0
                      } else {
                        row[yearKey] = calcs[c][key + '_common_size_' + row.commonKey][year] * 100
                      }
                    }
                  }
                }
              })

              if (compareToAvg && props.page !== 'audit') {
                let yearStatusKey = 'status' + year
                row[yearStatusKey] = CommonSizeArrows(row['you' + year], row['avg' + year], key, parentKey)
              }

            }
          } catch (err) {
            console.log(err, 'err')
            row[year] = null
          }
        });
      } else {
        row.header_key = key + 'header'
      }
    }
    return row
  }
  // we (for yearly) are doing this:
  // we get the calcs in an object each you: avg: min: max:
  // this contain all of the 700 metrics and something for each year
  // it turns into a row that has spaces for each of the years
  // lets do the same thing but with months too

  const rows = buildRowData(props.sheet, props.commonKey)

  if (props.page !== 'audit' && props.page !== 'moneypdf' && props.page !== 'naics_focused-moneypdf') {
    return (
      <MoneyAnnualTable {...{ ...props, rows: rows }} key={Math.random()} />
    )
  } else if (props.page === 'moneypdf' || props.page === 'naics_focused-moneypdf') {
    if (props.yearRange.length === 2) {
      return (
        <div className='portrait-view'>
          <MoneyAnnualTable {...{ ...props, rows: rows }} key={Math.random()} pdf={true} />
        </div>
      )
    } else if (props.yearRange.length > 2 && rows.length > 35) {
      let first30 = rows.slice(0, 35)
      let second30 = rows.slice(35)
      return (
        <>
          <div className='landscape-view'>
            <MoneyAnnualTable {...{ ...props, rows: first30 }} key={Math.random()} pdf={true} />
          </div>
          <div className='landscape-view'>
            <MoneyAnnualTable {...{ ...props, rows: second30 }} key={Math.random()} pdf={true} continued={true} />
          </div>
        </>
      )
    } else if (props.yearRange.length > 2 && rows.length <= 35) {
      return (
        <div className='landscape-view'>
          <MoneyAnnualTable {...{ ...props, rows: rows }} key={Math.random()} pdf={true} />
        </div>
      )
    }
  } else if (props.page === 'audit' && props.reportType === 'wp-analytics') {
    return (
      <AuditWPAnalytics key='audit-wp-table' {...{ ...props, rows: rows, compareWithSplit: compareWithSplit, compareWith: compareWith }} />
    )
  } else if (props.page === 'audit' && props.reportType === 'expense-analytics') {
    return (
      <AuditExpenseAnalytics key='audit-expense-table' {...{ ...props, rows: rows, compareWithSplit: compareWithSplit, compareWith: compareWith }} />
    )
  }
}
export default MoneyReportSheets;
