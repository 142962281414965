import React, { useContext } from 'react';
import { Data, Filters } from '../contexts';
import { parseMoney } from '../../utils';
import { CommonSizeArrowsHelper, YTDNAICSFocusedVals, YTDPnlandBalCommonSizeValue, YTDPnlandBalValues } from '../report_components/MoneyHelpers';

const MoneyYTD = ({ clientName, editMetrics, handleEditMetrics, handleSelectAndLayout, n, rows, sheet, title, year, ...props }) => {
  const { calcs } = useContext(Data);
  const { currentNewFilters } = useContext(Filters);
  let compareWith = currentNewFilters.compare_with;
  // Only two years being used for YTD tables.
  // Each month with values should have total revenue. Use to check how many months with values are available and label the YTD columns with the month/year.
  const previousYearYTDYouTotalRevenue = calcs[year - 1] ? calcs[year - 1]['you']['total_revenue'] : undefined;
  const currentYearYTDYouTotalRevenue = calcs[year] ? calcs[year]['you']['total_revenue'] : undefined;
  let firstYearMonth = previousYearYTDYouTotalRevenue ? Object.values(previousYearYTDYouTotalRevenue).filter(value => _.isNumber(value)).length : '';
  let secondYearMonth = currentYearYTDYouTotalRevenue ? Object.values(currentYearYTDYouTotalRevenue).filter(value => _.isNumber(value)).length : '';
  let clientFirstYearMonth = firstYearMonth < 10 ? '0' + firstYearMonth.toString() : firstYearMonth;
  let clientSecondYearMonth = secondYearMonth < 10 ? '0' + secondYearMonth.toString() : secondYearMonth;

  function buildEmptyRow(i, key = '', copy = '', relatedCheckedKey, padding = '', checked, displayChart, copyCellStyle, firstStyle, secondStyle) {

    if (copy === 'Current Liabilities') {
      secondStyle['paddingTop'] = '20px'
    }
    if (props.page === 'naics_focused' || key === 'space_before_revenue') {
      firstStyle['height'] = '20px';
      secondStyle['height'] = '20px';
    }

    let rowChecked = displayChart && checked && editMetrics && props.page === 'money' ? <p style={{ 'margin': 0 }}>&#10004;</p> : ''

    return (
      <tr key={Math.random(89)} onClick={() => editMetrics ? handleSelectAndLayout('money', sheet, relatedCheckedKey) : null}>
        {props.page === 'money' && !props.pdf ? <td>{rowChecked}</td> : null}
        <td className={copyCellStyle} style={secondStyle}>{padding + copy}</td>
        <td className='money-table-cell' style={firstStyle}></td>
        <td className='money-table-cell' style={firstStyle}></td>
        {compareWith === 'Peers' ? <td className='money-table-cell' style={firstStyle}></td> : null}
        {compareWith === 'Peers' ? <td className='money-table-cell' style={firstStyle}></td> : null}
        <td style={secondStyle}></td>
        <td style={secondStyle}></td>
        <td style={secondStyle}></td>
        {compareWith === 'Peers' ? <td style={secondStyle}></td> : null}
        {compareWith === 'Peers' ? <td style={secondStyle}></td> : null}
        <td style={secondStyle}></td>
        <td className='money-table-cell' style={firstStyle}></td>
      </tr>
    )
  };

  function Total(props) {
    return (<td className={props.cssClass} style={props.style}>
      {parseMoney(props.value)}
    </td>)

  }

  function Delta(props) {
    return (<td className='money-table-cell' style={props.style}>
      {parseMoney(props.valueCurrentYear - props.valuePreviousYear)}
    </td>)
  }

  let totalLiabAndEqVals, netWorthVals;
  function buildRow({ i = '', header = '', key = '', header_key = '', relatedCheckedKey = '', copy = '', padding = '', bold = '', forecast = '', checked = false, displayChart = '', map_keys = [], emptyRow = false, topline = '', whiteSpace = '', alwaysDisplayRow = false }) {
    let underlineFormat;
    let boldFont = 'bold';
    let toplineFormat = topline ? 'solid 1px' : '';

    if (forecast) {
      if (forecast.total) {
        if (forecast.total.boldedUnderline) {
          underlineFormat = 'solid 2px';
        } else if (forecast.total.doubleUnderline) {
          underlineFormat = 'double 2px';
        } else if (forecast.total.underline) {
          underlineFormat = 'solid 1px';
        }

        toplineFormat = forecast.total.topline ? 'solid 1px' : '';
      } else {
        if (forecast.boldedUnderline) {
          underlineFormat = 'solid 2px';
        } else if (forecast.doubleUnderline) {
          underlineFormat = 'double 2px';
        } else if (forecast.underline) {
          underlineFormat = 'solid 1px';
        }

        toplineFormat = forecast.topline ? 'solid 1px' : '';
      }
    }

    const copyCellStyle = whiteSpace ? 'account-column wrap-copy-cell' : 'account-column';

    const firstEmptyStyle = {
      fontWeight: boldFont,
      textAlign: 'left',
      borderBottom: underlineFormat,
      borderTop: toplineFormat,
      backgroundColor: '#BFE0AE'
    }

    const secondEmptyStyle = {
      fontWeight: boldFont,
      textAlign: 'left',
      borderBottom: underlineFormat,
      borderTop: toplineFormat,
    }

    const firstValueStyle = {
      textAlign: 'right',
      borderBottom: underlineFormat,
      borderTop: toplineFormat ? 'solid 1px' : '',
      backgroundColor: '#BFE0AE'
    }

    const secondValueStyle = {
      textAlign: 'right',
      borderBottom: underlineFormat,
      borderTop: toplineFormat ? 'solid 1px' : '',
    }
    let passedCalcsPrevYear = props.page === 'naics_focused' ? calcs[year - 1]['docs_avg'] : calcs[year - 1]['avg'];
    let passedCalcsCurrentYear = props.page === 'naics_focused' ? calcs[year]['docs_avg'] : calcs[year]['avg'];
    let valuePreviousYear, valueCurrentYear, valuePreviousYearPeers, valueCurrentYearPeers;
    let valuePYMonthlyCommonSize, valuePYPeersMonthlyCommonSize, valueCYMonthlyCommonSize, valueCYPeersMonthlyCommonSize;

    if (header || header_key || emptyRow) return buildEmptyRow(i, key, copy, relatedCheckedKey, padding, checked, displayChart, copyCellStyle, firstEmptyStyle, secondEmptyStyle);
    if (props.page === 'naics_focused') {
      valuePreviousYear = YTDNAICSFocusedVals(props.naicsCode, key, map_keys, calcs[year - 1]['you'], rows)
      valueCurrentYear = YTDNAICSFocusedVals(props.naicsCode, key, map_keys, calcs[year]['you'], rows)
      valuePYMonthlyCommonSize = YTDPnlandBalCommonSizeValue(valuePreviousYear, sheet, calcs[year - 1]['you'])
      valueCYMonthlyCommonSize = YTDPnlandBalCommonSizeValue(valueCurrentYear, sheet, calcs[year]['you'])

      if (compareWith === 'Peers') {
        valuePreviousYearPeers = YTDNAICSFocusedVals(props.naicsCode, key, map_keys, calcs[year - 1]['docs_avg'], rows)
        valueCurrentYearPeers = YTDNAICSFocusedVals(props.naicsCode, key, map_keys, calcs[year]['docs_avg'], rows)
        valuePYPeersMonthlyCommonSize = YTDPnlandBalCommonSizeValue(valuePreviousYearPeers, sheet, calcs[year - 1]['docs_avg'])
        valueCYPeersMonthlyCommonSize = YTDPnlandBalCommonSizeValue(valueCurrentYearPeers, sheet, calcs[year]['docs_avg'])
      }
    } else {
      const previousYearNonMonthlyYTDYou = calcs[year - 1] ? calcs[year - 1]['you'][key] : undefined;
      const currentYearNonMonthlyYTDYou = calcs[year] ? calcs[year]['you'][key] : undefined;
      
      valuePreviousYear = YTDPnlandBalValues(sheet, key, previousYearNonMonthlyYTDYou, calcs[year - 1]['you'])
      valueCurrentYear = YTDPnlandBalValues(sheet, key, currentYearNonMonthlyYTDYou, calcs[year]['you'])
      valuePYMonthlyCommonSize = YTDPnlandBalCommonSizeValue(valuePreviousYear, sheet, calcs[year - 1]['you'])
      valueCYMonthlyCommonSize = YTDPnlandBalCommonSizeValue(valueCurrentYear, sheet, calcs[year]['you'])
      
      if (compareWith === 'Peers') {
        const previousYearNonMonthlyYTDPeers = calcs[year - 1] ? calcs[year - 1]['avg'][key] : undefined;
        const currentYearNonMonthlyYTDPeers = calcs[year] ? calcs[year]['avg'][key] : undefined;
        valuePreviousYearPeers = YTDPnlandBalValues(sheet, key, previousYearNonMonthlyYTDPeers, calcs[year - 1]['avg'])
        valueCurrentYearPeers = YTDPnlandBalValues(sheet, key, currentYearNonMonthlyYTDPeers, calcs[year]['avg'])
        valuePYPeersMonthlyCommonSize = YTDPnlandBalCommonSizeValue(valuePreviousYearPeers, sheet, calcs[year - 1]['avg'])
        valueCYPeersMonthlyCommonSize = YTDPnlandBalCommonSizeValue(valueCurrentYearPeers, sheet, calcs[year]['avg'])
      }
    }

    let comparisonPY = valuePYPeersMonthlyCommonSize === '0.00' || valuePYPeersMonthlyCommonSize === undefined ? true : null
    let comparisonCY = valueCYPeersMonthlyCommonSize === '0.00' || valueCYPeersMonthlyCommonSize === undefined ? true : null
    let hideRow = valuePreviousYear === 0 && comparisonPY && valueCurrentYear === 0 && comparisonCY && !alwaysDisplayRow ? copy : null
    let rowChecked = displayChart && checked && editMetrics ? <p style={{ 'margin': 0 }}>&#10004;</p> : '';

    return (
      <tr key={Math.random(89)} id={`${sheet}-report-row-${i}`} style={hideRow === copy ? { display: 'none' } : {}} onClick={() => editMetrics ? handleSelectAndLayout('money', sheet, relatedCheckedKey) : null}>
        {props.page === 'money' && !props.pdf ? <td>{rowChecked}</td> : null}
        {copy === 'Current Liabilities'
          ? <td className={copyCellStyle} style={{ paddingTop: '20px' }}>{padding + copy}</td>
          : <td className={copyCellStyle} style={secondEmptyStyle}>{padding + copy}</td>}
        <Total
          cssClass={'money-table-cell'}
          value={valuePreviousYear}
          style={firstValueStyle}
        />
        <td className='common-size-cell money-table-cell' style={firstValueStyle}>
          {valuePYMonthlyCommonSize ? (valuePYMonthlyCommonSize * 100).toFixed(2) + '%' : '-'}
        </td>
        { compareWith === 'Peers'
          ? <CommonSizeArrowsHelper
            cssClass={'money-table-cell'}
            display={'ytd'}
            youTotRev={calcs[year - 1]['you']['total_revenue']}
            youTotAssets={calcs[year - 1]['you']['total_assets']}
            valueYou={valuePreviousYear}
            valueCompareTo={valuePreviousYearPeers}
            style={firstValueStyle}
            sheet={sheet}
            keyArrBuilder={key}
            passedCalcs={passedCalcsPrevYear}
          />
          : null }
        { compareWith === 'Peers'
          ? <td className='common-size-cell money-table-cell' style={firstValueStyle}>
            {valuePYPeersMonthlyCommonSize ? (valuePYPeersMonthlyCommonSize * 100).toFixed(2) + '%' : '-'}
          </td>
          : null }
        <td style={secondValueStyle}></td>
        <Total
          cssClass={''}
          value={valueCurrentYear}
          style={secondValueStyle}
        />
        <td className='common-size-cell' style={secondValueStyle}>
          {valueCYMonthlyCommonSize ? (valueCYMonthlyCommonSize * 100).toFixed(2) + '%' : '-'}
        </td>
        { compareWith === 'Peers'
          ? <CommonSizeArrowsHelper
            cssClass={''}
            display={'ytd'}
            youTotRev={calcs[year]['you']['total_revenue']}
            youTotAssets={calcs[year]['you']['total_assets']}
            valueYou={valueCurrentYear}
            valueCompareTo={valueCurrentYearPeers}
            style={secondValueStyle}
            sheet={sheet}
            keyArrBuilder={key}
            passedCalcs={passedCalcsCurrentYear}
          />
          : null }
        { compareWith === 'Peers'
          ? <td className='common-size-cell' style={secondValueStyle}>
            {valueCYPeersMonthlyCommonSize ? (valueCYPeersMonthlyCommonSize * 100).toFixed(2) + '%' : '-'}
          </td>
          : null }
        <td style={secondValueStyle}></td>
        <Delta
          valuePreviousYear={valuePreviousYear}
          valueCurrentYear={valueCurrentYear}
          style={firstValueStyle}
        />
      </tr>
    );
  };

  let ytdRows = [];
  if (sheet === 'pnl' && props.naicsCode && props.naicsCode.startsWith('621')) {
    const healthCareRevenueChildKeys = [
      { key: 'total_revenue_gross_charges', copy: 'Gross Charges' }, 
      { key: 'total_revenue_adjustments', copy: 'Adjustments' }, 
      { key: 'total_revenue_net_practice charges', copy: 'Net Practice Charges' }, 
      { key: 'total_revenue_collections', copy: 'Collections'}
    ]

    healthCareRevenueChildKeys.forEach(k => {
      ytdRows.push(buildRow({ 
        key: k.key,
        copy: k.copy,
        header: false,
        padding: '',
        parentKey: 'total_revenue',
        sheet: 'pnl',
        commonKey: 'revenue',
        alwaysDisplayRow: true
       }))
    })
    ytdRows.push(buildRow({ 
      key: 'space_before_revenue',
      copy: '',
      header: false,
      padding: '',
      sheet: 'pnl',
      emptyRow: true
     }))
  }
  rows.forEach((row) => {
    ytdRows.push(buildRow(row))
  })

  let tableID = props.page !== 'naics_focused' ? `${sheet}-ytd` : 'naics_focused-ytd'

  const buildYTDTable = () => {
    if (props.pdf) {
      let pageViewClass = 'portrait-view'
      return (
        <div className={pageViewClass}>
          <div className='money-pdf-portrait'>
            <div>
              <h1 className='report-table-title'>{`${title} - ${clientName}`}</h1>
              <div className='money-pdf-table-div'>
                <table className='report-table sheets' id='naics_focused-ytd-pdf'>
                  <thead>
                    <tr>
                      <td></td>
                      <td className='money-table-cell' style={{ 'backgroundColor': '#BFE0AE' }}>{`YTD ${clientFirstYearMonth}/${year - 1}`}</td>
                      <td className='common-size-cell money-table-cell' style={{ 'backgroundColor': '#BFE0AE' }}>You</td>
                      {compareWith === 'Peers' ? <td className='common-size-cell money-table-cell' style={{ 'backgroundColor': '#BFE0AE' }}></td> : null}
                      {compareWith === 'Peers' ? <td className='common-size-cell money-table-cell' style={{ 'backgroundColor': '#BFE0AE' }}>Peers</td> : null}
                      <td></td>
                      <td>{`YTD ${clientSecondYearMonth}/${year}`}</td>
                      <td className='common-size-cell'>You</td>
                      {compareWith === 'Peers' ? <td className='common-size-cell'></td> : null}
                      {compareWith === 'Peers' ? <td className='common-size-cell'>Peers</td> : null}
                      <td></td>
                      <td className='money-table-cell' style={{ 'backgroundColor': '#BFE0AE' }}>Δ Delta</td>
                    </tr>
                  </thead>
                  <tbody>
                    {ytdRows}
                    <tr><td className='footer-cell'><i>n = {n ? n : 0}</i></td></tr>
                  </tbody>
                </table>
              </div>
            </div>
            <p className='pdf-disclaimer-text'>{`These financial statements have been prepared by ${props.firmName}, and have not been subjected to an audit or review or compilation engagement, and no assurance is provided on them. These financial statements are intended for management’s internal use only. Substantially all disclosures omitted.`}</p>
          </div>
        </div>
      )
    } else {
      return (
        <div className='money-report-card monthly-sheet'>
          <div className='money-report-container'>
            <table className='report-table sheets' id={tableID}>
              <thead>
                <tr>
                  {props.page === 'money' ? (<td className='edit-metrics-cell' onClick={() => handleEditMetrics()}>{editMetrics ? <i className='fa fa-save hide-on-print' style={{ 'fontSize': '2em' }}></i> : <i className='fa fa-pencil edit-icon hide-on-print' style={{ 'fontSize': '2em' }}></i>}</td>) : null}
                  <td></td>
                  <td className='money-table-cell' style={{ 'backgroundColor': '#BFE0AE' }}>{`YTD ${clientFirstYearMonth}/${year - 1}`}</td>
                  <td className='common-size-cell money-table-cell' style={{ 'backgroundColor': '#BFE0AE' }}>You</td>
                  {compareWith === 'Peers' ? <td className='common-size-cell money-table-cell' style={{ 'backgroundColor': '#BFE0AE' }}></td> : null}
                  {compareWith === 'Peers' ? <td className='common-size-cell money-table-cell' style={{ 'backgroundColor': '#BFE0AE' }}>Peers</td> : null}
                  <td></td>
                  <td>{`YTD ${clientSecondYearMonth}/${year}`}</td>
                  <td className='common-size-cell'>You</td>
                  {compareWith === 'Peers' ? <td className='common-size-cell'></td> : null}
                  {compareWith === 'Peers' ? <td className='common-size-cell'>Peers</td> : null}
                  <td></td>
                  <td className='money-table-cell' style={{ 'backgroundColor': '#BFE0AE' }}>Δ Delta</td>
                </tr>
              </thead>
              <tbody>
                {ytdRows}
              </tbody>
              <tfoot>
                <tr><td><i>n = {n ? n : 0}</i></td></tr>
              </tfoot>
            </table>
          </div>
        </div>
      )
    }
  }

  return buildYTDTable()
};

export default MoneyYTD;