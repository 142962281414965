import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import Filler from '../progress_bar/Filler';
import { SurveyRadioAnswers, MoneySurvey, PeopleSurvey, CustomerSurvey, FutureSurvey } from './SurveyHelper';
import { capitalize } from '../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-regular-svg-icons';

const SurveyModal = (props) => {
    const [surveyState, setSurveyState] = useState({
        progressBarPercentage: 0,
        sectionSelected: props.surveyData.sections[0] || null,
        surveyLayoutData: props.surveyData.surveyLayoutData || null,
        modalMessage: ''
    })

    useEffect(() => {
        setSurveyState(() => {
            return {
                progressBarPercentage: 0,
                sectionSelected: props.surveyData.sections[0],
                surveyLayoutData: props.surveyData.surveyLayoutData,
                modalMessage: ''
            }
        })
    }, [props.surveyData])

    let radioButtonClass = 'radio ';
    let radioAnswerOptionsHeaderClass = '';
    let inputBorderLeftClass = '';
    let surveyNavBtnClass = '';
    let surveyBtnBottomClass = '';

    if (props.page === 'money') {
        inputBorderLeftClass = 'survey-border-money';
        radioButtonClass += 'money-radio';
        radioAnswerOptionsHeaderClass = 'radio-answer-options-header-money'
        surveyNavBtnClass = 'btn-survey-money '
        surveyBtnBottomClass = 'btn-survey-bottom-money'
    } else if (props.page === 'people') {
        inputBorderLeftClass = 'survey-border-people';
        radioButtonClass += 'people-radio';
        radioAnswerOptionsHeaderClass = 'radio-answer-options-header-people'
        surveyNavBtnClass = 'btn-survey-people'
        surveyBtnBottomClass = 'btn-survey-bottom-people'
    } else if (props.page === 'customers') {
        inputBorderLeftClass = 'survey-border-customers';
        radioButtonClass += 'customers-radio';
        radioAnswerOptionsHeaderClass = 'radio-answer-options-header-customers'
        surveyNavBtnClass = 'btn-survey-customers'
        surveyBtnBottomClass = 'btn-survey-bottom-customers'
    } else if (props.page === 'forecast') {
        inputBorderLeftClass = 'survey-border-forecast';
        radioButtonClass += 'forecast-radio';
        radioAnswerOptionsHeaderClass = 'radio-answer-options-header-forecast'
        surveyNavBtnClass = 'btn-survey-forecast'
        surveyBtnBottomClass = 'btn-survey-bottom-forecast'
    }

    const handleNumberYearsInput = (e, section, key, index) => {
        e.preventDefault()
        let value = e.target.value;
        setSurveyState(prevState => {
            let newState = _.cloneDeep(prevState)
            let sectionDataChange = newState.surveyLayoutData[section]['sectionData']
            let newSection = sectionDataChange.find(s => s.key === key)
            newSection['answer'][index][1] = value;
            return {
                ...newState,
                modalMessage: ''
            }
        })
    }


    const handleTextInput = (e, section, key) => {
        e.preventDefault()
        let value = e.target.value;
        setSurveyState(prevState => {
            let newState = _.cloneDeep(prevState)
            let sectionDataChange = newState.surveyLayoutData[section]['sectionData']
            let newSection = sectionDataChange.find(s => s.key === key)
            newSection['answer'] = value;
            return {
                ...newState,
                modalMessage: ''
            }
        })
    }

    const handleRadioInput = (e, section, inputType, key, questionKey) => {
        let value = Number(e.target.value);

        if (props.surveyTaken) {
            setSurveyState(prevState => {
                return {
                    ...prevState,
                    modalMessage: 'Cannot change these answers when survey has been submitted once.'
                }
            })
        } else {
            if (inputType === 'radio') {
                setSurveyState(prevState => {
                    let newState = _.cloneDeep(prevState)
                    let sectionDataChange = newState.surveyLayoutData[section]['sectionData']
                    let newSection = sectionDataChange.find(s => s.key === key)
                    let newAnswers = newSection['questions'].find(q => q.key === questionKey)
                    newAnswers['answer'] = value;
                    return newState
                })
            }
            if (inputType === 'ranking') {
                setSurveyState(prevState => {
                    let newState = _.cloneDeep(prevState)
                    let sectionDataChange = newState.surveyLayoutData[section]['sectionData']
                    let newSection = sectionDataChange.find(s => s.key === key)
                    // Find question with same value and switch with the selected radio input's value
                    let radioWithSameValue = newSection['questions'].find(q => q.answer === value)
                    // Find selected radio input and replace with new value
                    let selectedRadio = newSection['questions'].find(q => q.key === questionKey)
                    radioWithSameValue['answer'] = selectedRadio['answer']
                    selectedRadio['answer'] = value;
                    return newState
                })
            }
        }

    }

    const handleSectionSelected = (section = null, index = 0, direction = null) => {
        let progressPercentPerSection = 100 / (props.surveyData.sections.length - 1);
        let newProgressBarPercentage = index !== 3 || props.surveyData.sections.length <= 4 ? index * progressPercentPerSection : 70;
        let newSection = section;
        if (!newSection) {
            let sectionIndex = props.surveyData.sections.indexOf(surveyState.sectionSelected)
            if (direction === 'next') {
                newProgressBarPercentage = sectionIndex + 1 !== 3 || props.surveyData.sections.length <= 4 ? (sectionIndex + 1) * progressPercentPerSection : 70;
                newSection = props.surveyData.sections[sectionIndex + 1]
            }
            if (direction === 'back') {
                newProgressBarPercentage = sectionIndex - 1 !== 3 || props.surveyData.sections.length <= 4 ? (sectionIndex - 1) * progressPercentPerSection : 70;
                newSection = props.surveyData.sections[sectionIndex - 1]
            }
        }
        setSurveyState(prevState => {
            return {
                ...prevState,
                progressBarPercentage: newProgressBarPercentage,
                sectionSelected: newSection,
                modalMessage: ''
            }
        })
    }

    const buildSurveyNavigation = () => {
        return (
            <>
                <Filler fillerClass={'survey-money-filler'} percentage={surveyState.progressBarPercentage} />
                <div className='survey-navigation-btns'>
                    {
                        props.surveyData.sections.map((s, i) => {
                            return (<button key={`nav-button-${i}`} className={surveyState.sectionSelected === s ? `${surveyNavBtnClass} btn-survey active` : surveyNavBtnClass} onClick={() => handleSectionSelected(s, i)}>{s}</button>)
                        })
                    }
                </div>
            </>
        )
    }

    const buildSurveySections = () => {
        return props.surveyData.sections.map((section, i) => {
            let headcountNote = null
            if (section === 'Headcount' && ['6211', '6212', '6213'].some(code => props.naicsCode.startsWith(code))) {
                headcountNote = "Enter the Full-Time Employee (FTE) equivalent figure based on a 2000 hour year. For example, 2 clerical workers who worked 3,000 hours would equal 1.5 FTEs."
            }
            if (surveyState.sectionSelected) {
                return (<div key={i} className={surveyState.sectionSelected === section ? 'show-section' : 'hide-section'}>
                    {headcountNote ? <div style={{ 'margin': '10px' }}>{headcountNote}</div> : null}
                    {surveyState.surveyLayoutData[section]['sectionData'].map(data => {
                        if (data.inputType === 'radio' || data.inputType === 'ranking') {
                            let answerValues = Object.values(data.answerData);
                            return (
                                <div key={data.key}>
                                    {data.questionTitle && <h3 className='survey-radio-table-title'>{data.questionTitle}</h3>}
                                    {data.subtitle && <h4 className='survey-radio-table-subtitle'>{data.subtitle}</h4>}
                                    <p className='survey-modal-message'>{surveyState.modalMessage}</p>
                                    <div className='survey-radio-table'>
                                        <div className={radioAnswerOptionsHeaderClass}>
                                            <div className='radio-header-option-blank'></div>
                                            {data.answerOptions.map(a => {
                                                return (<div key={`answerOption-${data.key}-${a}`} className='radio-header-option'>{a}</div>)
                                            })}
                                        </div>
                                        {data.questions.map(q => {
                                            return (
                                                <div key={q.key} className='form-survey-group'>
                                                    <label key={`${q.key}-control-label`} className='control-label'>{q.questionTitle}</label>
                                                    {data.answerOptions.map((_, i) => {
                                                        return (
                                                            <span key={`s-${q.key}-${i}`} className={radioButtonClass}>
                                                                <label key={`l-${q.key}-${i}`} htmlFor={`${q.key}-${i}`}>
                                                                    <input key={`i-${q.key}-${i}`} id={`${q.key}-${i}`} name={`${q.key}-${i}`} type='radio' value={answerValues[i]} checked={answerValues[i] === q.answer} onChange={(e) => handleRadioInput(e, section, data.inputType, data.key, q.key)} />
                                                                    <span></span>
                                                                </label>
                                                            </span>
                                                        )
                                                    })}
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            )
                        }

                        if (data.inputType === 'numberYears') {
                            return (
                                <div key={data.key} className={`survey-years-section ${inputBorderLeftClass}`}>
                                    <label key={`numberYears-${data.key}`}>{data.questionTitle}</label>
                                    <div>
                                        {
                                            data.answer.map((a, i) => {
                                                return (
                                                    <label key={`label-${data.key}-${a[0]}`} htmlFor={`${data.key}-${a[0]}`}>
                                                        {a[0]}
                                                        <input id={`${data.key}-${a[0]}`} className='survey-year-input' name={`${data.key}-${a[0]}`} type='number' value={a[1]} onInput={(e) => handleNumberYearsInput(e, section, data.key, i)} />
                                                    </label>
                                                )
                                            })
                                        }
                                    </div>
                                </div>)
                        }

                        if (data.inputType === 'text') {
                            return (<div key={data.key} className={`survey-text-input ${inputBorderLeftClass}`}><label>{data.questionTitle}</label> <input type='text' value={data.answer} onChange={(e) => handleTextInput(e, section, data.key)} /></div>)
                        }

                        if (data.inputType === 'textarea') {
                            return (<div key={data.key} className={`survey-textarea ${inputBorderLeftClass}`}><label>{data.questionTitle}</label> <textarea value={data.answer} onChange={(e) => handleTextInput(e, section, data.key)} /></div>)
                        }
                    })}
                </div>)
            }
        })
    }

    const bulidPrevAndNextButtons = () => {

        let sectionSelected = surveyState.sectionSelected;
        if (sectionSelected === props.surveyData.sections[0]) {
            return (<button className={`btn ${surveyBtnBottomClass}`} style={{ 'marginLeft': 'auto' }} onClick={() => handleSectionSelected(null, 0, 'next')}>Next</button>)
        } else if (sectionSelected === props.surveyData.sections[props.surveyData.sections.length - 1]) {
            return (<button className={`btn ${surveyBtnBottomClass}`} onClick={() => handleSectionSelected(null, 0, 'back')}>Back</button>)
        } else {
            return (
                <>
                    <button className={`btn ${surveyBtnBottomClass}`} onClick={() => handleSectionSelected(null, 0, 'back')}>Back</button>
                    <button className={`btn ${surveyBtnBottomClass}`} onClick={() => handleSectionSelected(null, 0, 'next')}>Next</button>
                </>
            )
        }
    }



    return (
        <>
            <Modal
                className={{
                    base: 'survey-modal-content hide-on-print',
                    afterOpen: 'survey-modal-content_after-open hide-on-print',
                    beforeClose: 'survey-modal-content_before-close hide-on-print',
                }}
                overlayClassName={{
                    base: 'survey-overlay-base hide-on-print',
                    afterOpen: 'survey-overlay-base_after-open hide-on-print',
                    beforeClose: 'survey-overlay-base_before-close hide-on-print',
                }}
                ariaHideApp={false}
                closeTimeoutMS={0}
                contentLabel='Survey Modal'
                isOpen={props.modalIsOpen}
                onRequestClose={() => props.handleSurveyModal(false)}
                shouldCloseOnOverlayClick={false}
            >
                <div>
                    <header className='survey-header'>
                        <h2>{props.pageTitle} Survey {props.year}</h2>
                        <button
                            type='button'
                            className='btn btn-dark'
                            onClick={() => props.handleSurveyModal(false)}
                        >
                            <i className='fa fa-times'></i>
                        </button>
                    </header>

                    <div className='survey-navigation'>
                        {buildSurveyNavigation()}
                    </div>
                    <form className='survey' onSubmit={(e) => props.handleSurveySubmit(e, surveyState)}>
                        {buildSurveySections()}
                        {surveyState.sectionSelected === props.surveyData.sections[props.surveyData.sections.length - 1] && <div><button className={`btn ${surveyBtnBottomClass} survey-submit-btn`} type='submit'>Submit Survey</button></div>}
                    </form>

                    <div className='survey-prev-next-btns'>
                        {bulidPrevAndNextButtons()}
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default SurveyModal;