const Adding = (obj) => {
  let sumNumber = 0
  Object.values(obj).forEach(value => {
    sumNumber += value
  })
  return sumNumber
}

// Create Initial Revenue and Operating Expenses Growth Rates
// startingGrowthRate is not in percent form, ex. .01 -> 10%
const GrowthRateIputKeys = (pnl, startingGrowthRate) => {
  const opexParentKeys = [
    'total_people_costs',
    'total_customer_costs',
    'total_operations',
    'total_operating_expenses_general_& administrative',
    'total_operating_expenses_marketing',
    'total_operating_expenses_product_development',
    'total_operating_expenses_sales'
  ]
  let grKeys = {
    'total_revenue': (startingGrowthRate * 100).toFixed(2)
  }

  opexParentKeys.forEach(parent => {
    if (pnl.children[parent]) {
      Object.keys(pnl.children[parent]).forEach(child => {
        grKeys[child] = (startingGrowthRate * 100).toFixed(2)
      })
    }
  })

  return grKeys
}

const TotalRevenueAndMonthlyRevPercents = (initialRevenue, forecasts, goalGR, blendMonth = null) => {
  goalGR = goalGR ? Number(goalGR) / 100 : 0
  let finalRevenue = initialRevenue * (1 + goalGR)
  let monthlyRevPercents = []
  let forecastRevenue = 0

  // Get forecast total revenue from the sum of the months
  forecasts.forEach(forecast => {
    forecastRevenue += forecast.state.pnl.parents.total_revenue
  })

  // Then each month's percent of the year / annual revenue
  forecasts.forEach(forecast => {
    // Handle Sync Live Data
    if (blendMonth && forecast.month >= blendMonth) {
      monthlyRevPercents.push(forecast.state.pnl.parents.total_revenue / forecastRevenue)
    } else if (blendMonth && forecast.month < blendMonth) {
      monthlyRevPercents.push(forecast.state.pnl.parents.total_revenue / finalRevenue)
    } else {
      monthlyRevPercents.push(forecast.state.pnl.parents.total_revenue / forecastRevenue)
    }
  })

  // Figure Out if the percents of revenue for each month add up to 1 (100%)
  let totalPercentage = monthlyRevPercents.reduce((a, b) => a + b)
  // If Sync Live Data (blendMonth) is in place, figure out the remaining percentage and divide it equally for the months without live data.
  if (totalPercentage < 1 && blendMonth) {
    let remainingPercentagePerMonth = (1 - totalPercentage) / (13 - blendMonth)
    monthlyRevPercents = monthlyRevPercents.map((m, i) => {
      if (i >= blendMonth - 1) {
        return m += remainingPercentagePerMonth
      } else {
        return m
      }
    })
  }

  return { finalRevenue: finalRevenue, monthlyRevPercents: monthlyRevPercents }
}

// Handle Sync Live Data and avoid changing the live data values Accounting APIs
const PnlValuesWithBlend = (forecast, blendMonth = false) => {
  let pnlObj = {}
  const pnlState = forecast.state.pnl

  Object.keys(pnlState.parents).forEach((parent) => {
    if (parent == 'total_costs' && blendMonth) {
      pnlObj[parent] = (parseFloat(pnlState.parents[parent]) / 100) * pnlObj.total_revenue;
    } else if (parent !== 'materials_costs') {
      pnlObj[parent] = pnlState.parents[parent];
    }
  })

  Object.keys(pnlState.children).forEach((parent) => {
    Object.keys(pnlState.children[parent]).forEach((child) => {
      if (parent == 'total_costs' && blendMonth) {
        pnlObj[child] = parseFloat(pnlState.children[parent][child] / 100) * pnlObj.total_revenue;
      } else if (parent !== 'materials_costs') {
        pnlObj[child] = pnlState.children[parent][child];
      }
    })
  })

  let deprAmort = pnlObj.hasOwnProperty('depreciation_and_amortization') ? pnlObj.depreciation_and_amortization : 0
  let otherTaxes = pnlObj.hasOwnProperty('total_other_costs_taxes_- federal & state') ? pnlObj['total_other_costs_taxes_- federal & state'] : 0
  let interestOtherExpense = pnlObj.hasOwnProperty('interest_and_other_expenses') ? pnlObj.interest_and_other_expenses : 0
  let otherIncomeExpenses = pnlObj.hasOwnProperty('other_income_expenses') ? pnlObj.other_income_expenses : 0
  let interestExpenseAmort = pnlObj.hasOwnProperty('interest_expense_including_amort_of_loan_fees') ? pnlObj['interest_expense_including_amort_of_loan_fees'] : 0
  let relatedPartyFees = pnlObj.hasOwnProperty('related_party_fees') ? pnlObj.related_party_fees : 0

  pnlObj.total_other_costs = interestOtherExpense + deprAmort + otherIncomeExpenses + otherTaxes + interestExpenseAmort + relatedPartyFees
  pnlObj.gross_profit = pnlObj.total_revenue - (pnlObj.total_costs || 0);
  pnlObj.total_operating_expenses = pnlObj.total_people_costs + pnlObj.total_customer_costs + pnlObj.total_operations + pnlObj['total_operating_expenses_general_& administrative'] + pnlObj['total_operating_expenses_marketing'] + pnlObj['total_operating_expenses_product_development'] + pnlObj['total_operating_expenses_sales'];
  pnlObj.operating_profit = pnlObj.gross_profit - pnlObj.total_operating_expenses;
  pnlObj.operating_expenses_net_income = pnlObj.operating_profit - pnlObj.total_other_costs;
  return pnlObj;
}

const BalanceSheetValuesWithBlend = (delta, netincome, base = {}, month) => {
  let balanceObj = {}
  Object.keys(delta.parents).forEach((parent) => {
    balanceObj[parent] = delta.parents[parent];
  })
  Object.keys(delta.children).forEach((parent) => {
    Object.keys(delta.children[parent]).forEach((child) => {
      if (month === 1 && child === "operating_expenses_net_income") {
        balanceObj.operating_expenses_net_income = netincome
      }
      else if (child === "operating_expenses_net_income") {
        balanceObj.operating_expenses_net_income = (base.operating_expenses_net_income || 0) + netincome;
      } else {
        balanceObj[child] = delta.children[parent][child]
      }
    })
  })
  balanceObj.total_assets = balanceObj.total_current_assets + balanceObj.total_fixed_assets;
  balanceObj.net_worth = balanceObj.capital_stock + balanceObj.owner_distributions + balanceObj.retained_earnings + balanceObj.operating_expenses_net_income;
  balanceObj.total_liabilities_and_equity = balanceObj.long_term_debt + balanceObj.total_current_liabilities + balanceObj.net_worth;

  return balanceObj;
}

const COGSConverter = (pnl) => {
  let finalCOGS = _.cloneDeep(pnl)
  let parents = finalCOGS['parents']
  let children = finalCOGS['children']
  if (parents['total_costs']) {
    finalCOGS['parents']['total_costs'] = (parseFloat((finalCOGS['parents']['total_costs'] / finalCOGS['parents']['total_revenue'])) * 100)
    Object.keys(children['total_costs']).forEach((child) => {
      if (finalCOGS['children']['total_costs'][child]) {
        finalCOGS['children']['total_costs'][child] = (parseFloat((finalCOGS['children']['total_costs'][child] / finalCOGS['parents']['total_revenue'])) * 100)
      } else {
        finalCOGS['children']['total_costs'][child] = 0.0
      }
    })
  }
  return finalCOGS
}

const PnlValues = (forecast, growthRate = 0, companyCalc, liveCogs = false, notBlendMonth = false) => {
  let pnlObj = {}
  const pnlState = forecast.state.pnl
  if (companyCalc) {
    pnlObj.total_revenue = (parseFloat(companyCalc.total_revenue / 12)) * (1 + growthRate);
  } else {
    pnlObj.total_revenue = (parseFloat(pnlState.parents.total_revenue)) * (1 + growthRate);
  }

  Object.keys(pnlState.parents).forEach((parent) => {
    if (parent === 'total_costs') {
      if (companyCalc) {
        pnlObj[parent] = (parseFloat((companyCalc[parent]) / 12)) * (1 + growthRate);
      } else if (liveCogs && notBlendMonth === false) {
        pnlObj[parent] = (parseFloat(pnlState.parents[parent]))
      } else {
        pnlObj[parent] = (parseFloat(pnlState.parents[parent]) / 100) * pnlObj.total_revenue;
      }
    } else if (parent !== 'total_revenue' && parent !== 'materials_costs') {
      if (companyCalc) {
        pnlObj[parent] = (parseFloat(companyCalc[parent] / 12)) * (1 + growthRate);
      } else {
        pnlObj[parent] = (parseFloat(Adding(pnlState.children[parent]))) * (1 + growthRate)
      }
    } else if (parent === 'total_revenue') {
      let actualRevenue = 0
      let revenueKeys = Object.keys(pnlState.children['total_revenue'])
      if (revenueKeys.length > 1) {
        Object.keys(pnlState.children[parent]).forEach(child => {
          if (child !== 'total_revenue') {
            pnlObj[child] = (parseFloat(pnlState.children[parent][child]));
            actualRevenue += pnlObj[child]
          }
        })

        if (actualRevenue != pnlState.parents.total_revenue) {
          pnlObj[parent] = parseFloat(actualRevenue) * (1 + growthRate)
          Object.keys(pnlState.children[parent]).forEach(child => {
            if (child !== 'total_revenue') {
              pnlObj[child] = (parseFloat(pnlState.children[parent][child])) * (1 + growthRate)
            }
          })
        } else {
          pnlObj[parent] = parseFloat(actualRevenue)
        }
      }
    }
  })
  Object.keys(pnlState.children).forEach((parent) => {
    if (parent === 'total_costs') {
      Object.keys(pnlState.children[parent]).forEach((child) => {
        if (companyCalc) {
          pnlObj[child] = parseFloat((companyCalc[child] / 12)) * (1 + growthRate);
        } else if (liveCogs && notBlendMonth === false) {
          pnlObj[child] = (parseFloat((pnlState.children[parent][child])))
        } else {
          pnlObj[child] = pnlObj.total_revenue * parseFloat(pnlState.children[parent][child] / 100);
        }
      });
    } else if (parent !== 'total_revenue' && parent !== 'materials_costs') {
      Object.keys(pnlState.children[parent]).forEach((child) => {
        if (companyCalc) {
          pnlObj[child] = (companyCalc[child] / 12) * (1 + growthRate);
        } else {
          pnlObj[child] = (pnlState.children[parent][child]) * (1 + growthRate);
        }
      });
    }
  })

  let deprAmort = pnlObj.hasOwnProperty('depreciation_and_amortization') ? pnlObj.depreciation_and_amortization : 0
  let otherTaxes = pnlObj.hasOwnProperty('total_other_costs_taxes_- federal & state') ? pnlObj['total_other_costs_taxes_- federal & state'] : 0
  let interestOtherExpense = pnlObj.hasOwnProperty('interest_and_other_expenses') ? pnlObj.interest_and_other_expenses : 0
  let otherIncomeExpenses = pnlObj.hasOwnProperty('other_income_expenses') ? pnlObj.other_income_expenses : 0
  let interestExpenseAmort = pnlObj.hasOwnProperty('interest_expense_including_amort_of_loan_fees') ? pnlObj['interest_expense_including_amort_of_loan_fees'] : 0
  let relatedPartyFees = pnlObj.hasOwnProperty('related_party_fees') ? pnlObj.related_party_fees : 0

  pnlObj.total_other_costs = interestOtherExpense + deprAmort + otherIncomeExpenses + otherTaxes + interestExpenseAmort + relatedPartyFees
  pnlObj.gross_profit = pnlObj.total_revenue - (pnlObj.total_costs || 0);
  pnlObj.total_operating_expenses = pnlObj.total_people_costs + pnlObj.total_customer_costs + pnlObj.total_operations + pnlObj['total_operating_expenses_general_& administrative'] + pnlObj['total_operating_expenses_marketing'] + pnlObj['total_operating_expenses_product_development'] + pnlObj['total_operating_expenses_sales'];
  pnlObj.operating_profit = pnlObj.gross_profit - pnlObj.total_operating_expenses;
  pnlObj.operating_expenses_net_income = pnlObj.operating_profit - pnlObj.total_other_costs;
  return pnlObj;
}

const BalanceSheetValues = (delta, netincome, depr, base = {}, month, originalDepreciation, blendMonth, year) => {
  let balanceObj = {}
  Object.keys(delta.parents).forEach((parent) => {
    balanceObj[parent] = delta.parents[parent];
  })
  Object.keys(delta.children).forEach((parent) => {
    Object.keys(delta.children[parent]).forEach((child) => {
      balanceObj[child] = delta.children[parent][child];
    })
  })

  if (month === 1 && year === 'year1') {
    balanceObj.less_accumulated_depreciation = originalDepreciation - depr
    balanceObj.operating_expenses_net_income = netincome
  } else if (month === 1) {
    balanceObj.less_accumulated_depreciation = balanceObj.less_accumulated_depreciation - (depr * month)
    balanceObj.operating_expenses_net_income = netincome
  } else if (blendMonth && month >= blendMonth && year === 'year1') {
    balanceObj.less_accumulated_depreciation = originalDepreciation - (depr * (month + 1 - blendMonth))
    balanceObj.operating_expenses_net_income = (base.operating_expenses_net_income || 0) + netincome
  } else {
    balanceObj.less_accumulated_depreciation = balanceObj.less_accumulated_depreciation - (depr * month)
    balanceObj.operating_expenses_net_income = (base.operating_expenses_net_income || 0) + netincome
  }

  balanceObj.total_fixed_assets = balanceObj.property_and_equipment + balanceObj.other_long_term_assets + balanceObj.less_accumulated_depreciation
  balanceObj.total_assets = balanceObj.total_current_assets + balanceObj.total_fixed_assets;
  balanceObj.net_worth = balanceObj.capital_stock + balanceObj.owner_distributions + balanceObj.retained_earnings + balanceObj.operating_expenses_net_income;
  balanceObj.total_liabilities_and_equity = balanceObj.long_term_debt + balanceObj.total_current_liabilities + balanceObj.net_worth;
  balanceObj.a_delta = balanceObj.total_liabilities_and_equity - balanceObj.total_assets || 0
  balanceObj.cash = balanceObj.cash + balanceObj.a_delta
  balanceObj.total_current_assets = balanceObj.total_current_assets + balanceObj.a_delta
  balanceObj.total_assets = balanceObj.total_assets + balanceObj.a_delta
  return balanceObj;
}

const CashFlowValues = (balVals, baseBalVals, previousYearBaseBalVals) => {
  // for years 2 and 3 january object
  if (previousYearBaseBalVals && previousYearBaseBalVals[11]) {
    baseBalVals = { ...baseBalVals, ...previousYearBaseBalVals[11] }
  }
  // we're getting the values from the balance sheet
  let finalValues = [];
  for (let i = 0; i < balVals.length; i++) {
    let previousMonth;
    let currentMonth;
    if (i === 0) {
      let janObject = {};
      let january = balVals[0];
      for (const prop in january) { // A different property name is assigned to variable 'prop' on each iteration.
        if (baseBalVals.hasOwnProperty(prop)) { // check if baseBalVal has this property or not
          let newVal = baseBalVals[prop] - january[prop]; // previous month - current month
          janObject[prop] = newVal;
        }
      }
      finalValues.push(janObject); // january need previous year data
    } else {
      let newValues = [];
      let newObject = {};
      previousMonth = balVals[i - 1];
      currentMonth = balVals[i];
      const previousValues = Object.values(previousMonth);
      const currentValues = Object.values(currentMonth);
      const monthKeys = Object.keys(currentMonth);
      for (let i = 0; i < previousValues.length; i++) {
        newValues.push(previousValues[i] - currentValues[i]); // previous month - current month
      }
      // need to make object with monthKeys array and newValues array
      // push object to finalValues array and return it
      for (let i = 0; i < monthKeys.length; i++) {
        newObject[monthKeys[i]] = newValues[i];
      }
      finalValues.push(newObject);
    }
  }
  return finalValues; // return [ 0: {a_delta: 10, accounts_payable: 100 ....}, 1: {a_delta: -2, accounts_payable: -50 ....}] with prevoius - current for the math
}
const Sum = (valueSet) => {
  let sumObj = {}
  Object.keys(valueSet[0]).forEach(key => {
    sumObj[key] = 0.0;
    valueSet.forEach(sheetVals => {
      sumObj[key] = sumObj[key] + sheetVals[key];
    });
  })
  return sumObj;
}

const AverageMonthVals = (forecastSet, growthRate = 0, companyCalc, blendMonth = false, notDefaultScenario = false) => {
  let yearValues = []
  let liveYearValues = []
  let liveSum
  let average = {}
  const forecasts = _.cloneDeep(forecastSet)
  const forecast = _.cloneDeep(forecasts[0].state)
  forecasts.forEach((forecast) => {
    let pnlVals
    if (companyCalc) {
      pnlVals = new PnlValues(forecast, growthRate, companyCalc)
    } else {
      pnlVals = new PnlValues(forecast)
    }
    yearValues.push(pnlVals);
  })

  if (blendMonth) {
    let pnlVals
    for (let i = 0; i < 12; i++) {
      if (i < blendMonth - 1) {
        pnlVals = new PnlValues(forecasts[i], 0, 0, true)
        liveYearValues.push(pnlVals)
      } else {
        if (notDefaultScenario) {
          pnlVals = new PnlValues(forecasts[i], 0, 0, true, true)
          liveYearValues.push(pnlVals)
        } else {
          pnlVals = new PnlValues(forecasts[i], growthRate, companyCalc, true, true)
          liveYearValues.push(pnlVals)
        }
      }
    }
    liveSum = new Sum(liveYearValues)
  }

  let sum = new Sum(yearValues)
  let sumKeys = Object.keys(sum)
  for (let key in sum) {
    average[key] = sum[key] / 12
  }

  const pnlParentKeys = Object.keys(forecast['pnl'].parents)
  if (pnlParentKeys.includes('materials_costs')) {
    let materialIndex = pnlParentKeys.indexOf('materials_costs')
    pnlParentKeys.splice(materialIndex, 1)
  }
  pnlParentKeys.forEach((mainKey) => {
    const pnlChildrenKeys = Object.keys(forecast['pnl'].children[mainKey])
    if (forecast['pnl'].parents && mainKey === "total_revenue" && forecast['pnl'].children['total_revenue']) {
      forecast['pnl'].parents['total_revenue'] = average['total_revenue']
      forecast['pnl'].children['total_revenue']['total_revenue'] = average['total_revenue']
    } else if (forecast['pnl'].parents && forecast['pnl'].parents[mainKey]) {
      if (mainKey == 'total_costs') {
        forecast['pnl'].parents['total_costs'] = (average['total_costs'] / average['total_revenue'] * 100)
      } else {
        forecast['pnl'].parents[mainKey] = average[mainKey]
      }
    }
    pnlChildrenKeys.forEach((k) => {
      if (forecast['pnl'].children && mainKey === 'total_costs') {
        forecast['pnl'].children['total_costs'][k] = (average[k] / average['total_revenue'] * 100)
      } else {
        forecast['pnl'].children[mainKey][k] = average[k]
      }
    })
  })

  return { forecast, sum, liveSum }
}

const Ratios = (baseKey, sumVal) => {
  let ratioObj = {}
  Object.keys(sumVal).forEach(key => {
    ratioObj[key] = (sumVal[key] / sumVal[baseKey]) * 100;
  })
  return ratioObj;
}
export { GrowthRateIputKeys, TotalRevenueAndMonthlyRevPercents, PnlValuesWithBlend, BalanceSheetValuesWithBlend, COGSConverter, PnlValues, BalanceSheetValues, CashFlowValues, Sum, AverageMonthVals, Ratios }