import React, { useState, useRef, useEffect } from 'react';
import peerviewLogo from '../../../assets/images/favicon.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faThumbsDown, faCheck } from '@fortawesome/free-solid-svg-icons';

const ChatTypewriter = ({ chat, message, text, speed = 40 }) => {
    const [displayedText, setDisplayedText] = useState('');
    const [completedEntries, setCompletedEntries] = useState([]);
    const [currentSection, setCurrentSection] = useState('analysis_metrics_title'); // Tracks the current section
    const [charIndex, setCharIndex] = useState(0); // Tracks the current character being typed
    const [currentMetricIndex, setCurrentMetricIndex] = useState(0); // Tracks the current metric within a section
    const [currentTextIndex, setCurrentTextIndex] = useState(0); // Tracks the current line within a metric
    const [isCompleted, setIsCompleted] = useState(false); // Tracks if all sections are completed
    const timeoutRef = useRef(null);
    const sections = ['analysis_metrics_title', 'analysis_metrics_details', 'analysis_metrics', 'top_5_metrics_title', 'top_5_metrics_details', 'top_5_metrics_total_impact', 'top_5_metrics', 'top_5_summary'];

    useEffect(() => {
        if (isCompleted) return; // Stop processing if all sections are completed

        if (message.typewriter && message.json_content) {
            const handleTyping = () => {
                const content = message.json_content;
                let textToType = '';
                let currentClass = ''; // Tracks the class to apply

                // Determine the text to type based on the current section
                if (currentSection === 'analysis_metrics_title') {
                    textToType = content['analysis_metrics_title'] || '';
                    currentClass = 'title-class'; // Larger font for titles
                } else if (currentSection === 'analysis_metrics_details') {
                    textToType = content['analysis_metrics_details'] || '';
                    currentClass = 'analysis-style'
                } else if (currentSection === 'analysis_metrics') {
                    const metrics = content['analysis_metrics'] || [];
                    if (currentMetricIndex < metrics.length) {
                        const currentMetric = metrics[currentMetricIndex];
                        const lines = [
                            { text: `${currentMetric.metric || ''}`, status: currentMetric.status || null, className: 'metric-style' }, // Bold metric
                            { text: `${currentMetric.analysis || ''}`, className: 'analysis-style' } // Margin for analysis
                        ];
                        const currentLine = lines[currentTextIndex] || {};
                        textToType = currentLine.text || '';
                        currentClass = currentLine.className || '';
                    }
                } else if (currentSection === 'top_5_metrics_title') {
                    textToType = content['top_5_metrics_title'] || '';
                    currentClass = 'title-class'; // Larger font for titles
                } else if (currentSection === 'top_5_metrics_details') {
                    textToType = content['top_5_metrics_details'] || '';
                    currentClass = 'top5-style'
                } else if (currentSection === 'top_5_metrics_total_impact') {
                    textToType = content['top_5_metrics_total_impact'] || '';
                    currentClass = 'top5-total-impact-style'
                } else if (currentSection === 'top_5_metrics') {
                    const metrics = content['top_5_metrics'] || [];
                    if (currentMetricIndex < metrics.length) {
                        const currentMetric = metrics[currentMetricIndex];
                        let rank = currentMetricIndex + 1
                        const lines = [
                            { text: `${rank}. ${currentMetric.metric || ''}`, className: 'metric-style' }, // Bold metric
                            { text: `Opportunity: ${currentMetric.opportunity || ''}`, className: '' },
                            { text: `Recommendation: ${currentMetric.recommendation || ''}`, className: 'recommendation-style' } // Margin for recommendation
                        ];
                        const currentLine = lines[currentTextIndex] || {};
                        textToType = currentLine.text || '';
                        currentClass = currentLine.className || '';
                    }
                } else if (currentSection === 'top_5_summary') {
                    textToType = content['top_5_summary'] || '';
                    currentClass = 'summary';
                }

                // Type the text
                if (charIndex < textToType.length) {
                    setDisplayedText((prev) => prev + textToType.slice(charIndex, charIndex + 20));
                    setCharIndex((prev) => prev + 20);
                } else {
                    // Text for the current part is complete
                    setCompletedEntries((prev) => [
                        ...prev,
                        {
                            text: displayedText,
                            status: currentSection === 'analysis_metrics' && currentClass === 'metric-style' ? (content['analysis_metrics'] || [])[currentMetricIndex]?.status : null,
                            className: currentClass
                        }
                    ]);
                    setDisplayedText('');
                    setCharIndex(0);

                    // Determine the next step
                    if (currentSection === 'analysis_metrics' || currentSection === 'top_5_metrics') {
                        const metrics = content[currentSection] || [];
                        if (currentTextIndex < 2) {
                            // Move to the next line within the metric
                            setCurrentTextIndex((prev) => prev + 1);
                        } else if (currentMetricIndex < metrics.length - 1) {
                            // Move to the next metric
                            setCurrentTextIndex(0);
                            setCurrentMetricIndex((prev) => prev + 1);
                        } else {
                            // Move to the next section
                            moveToNextSection();
                        }
                    } else {
                        // Move to the next section for other keys
                        moveToNextSection();
                    }
                }
            };

            const moveToNextSection = () => {
                const nextSectionIndex = sections.indexOf(currentSection) + 1;
                if (nextSectionIndex < sections.length) {
                    setCurrentSection(sections[nextSectionIndex]);
                    setCurrentMetricIndex(0);
                    setCurrentTextIndex(0);
                } else {
                    setIsCompleted(true);
                    clearTimeout(timeoutRef.current); // Stop processing when all sections are done
                }
            };

            timeoutRef.current = setTimeout(handleTyping, speed);

            return () => clearTimeout(timeoutRef.current);
        }
    }, [message, currentMetricIndex, currentTextIndex, charIndex, speed]);

    let filtersUsedText = ""

    if (chat && chat.filters) {
        filtersUsedText = `Filters Used: Industry - ${chat.filters.code}, Data Type - ${chat.filters.datatype}, Revenue Band - ${chat.filters.revenue_band}, Region - ${chat.filters.region}, Year - ${chat.filters.year}`
    }

    return (
        <div className='message-container'>
            <div className='message-items'>
                <div className='message-filters'>{filtersUsedText}</div>
                <div className='messsage-text-and-logo'>
                    <img className='peerview-logo-message' src={peerviewLogo} alt="Peerview Data Logo" />
                    <div className='message-text'>
                        {completedEntries.map((entry, index) => (
                            <div key={index} className={`typewriter-entry ${entry.className || ''}`}>
                                {entry.text}
                                {entry.status && (
                                    <span className="status-icon">
                                        {entry.status === 'good' && <FontAwesomeIcon icon={faThumbsUp} color="green" />}
                                        {entry.status === 'bad' && <FontAwesomeIcon icon={faThumbsDown} color="red" />}
                                        {entry.status === 'same' && <FontAwesomeIcon icon={faCheck} />}
                                    </span>
                                )}
                            </div>
                        ))}
                        {displayedText && (
                            <div
                                className={`typewriter-entry`}>
                                {displayedText}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

const StaticAnalysisMessage = ({ chat, message }) => {
    let filtersUsedText = ""
    let content = message ? message.json_content : null

    if (chat && chat.filters) {
        filtersUsedText = `Filters Used: Industry - ${chat.filters.code}, Data Type - ${chat.filters.datatype}, Revenue Band - ${chat.filters.revenue_band}, Region - ${chat.filters.region}, Year - ${chat.filters.year}`
    }

    return (
        <div className='message-container'>
            <div className='message-items'>
                <div className='message-filters'>{filtersUsedText}</div>
                <div className='messsage-text-and-logo'>
                    <img className='peerview-logo-message' src={peerviewLogo} alt="Peerview Data Logo" />
                    <div className='message-text'>
                        <div className={`typewriter-entry title-class`}>{content && content.analysis_metrics_title}</div>
                        <div className={`typewriter-entry analysis-style`}>{content && content.analysis_metrics_details}</div>
                        {content && content.analysis_metrics.map((data, index) => (
                            <React.Fragment key={`analysis-${index}`}>
                                <div key={`${index}-ametric`} className={`typewriter-entry metric-style`}>
                                    {data.metric}
                                    {data.status && (
                                        <span className="status-icon">
                                            {data.status === 'good' && <FontAwesomeIcon icon={faThumbsUp} color="green" />}
                                            {data.status === 'bad' && <FontAwesomeIcon icon={faThumbsDown} color="red" />}
                                            {data.status === 'same' && <FontAwesomeIcon icon={faCheck} />}
                                        </span>
                                    )}
                                </div>
                                <div key={`${index}-analysis`} className={`typewriter-entry analysis-style`}>{data.analysis}</div>
                            </React.Fragment>
                        ))}
                    </div>
                </div>
                <div className='messsage-text-and-logo'>
                    <img className='peerview-logo-message' src={peerviewLogo} alt="Peerview Data Logo" />
                    <div className='message-text'>
                        <div className={`typewriter-entry title-class`}>{content && content.top_5_metrics_title}</div>
                        <div className={`typewriter-entry top5-style`}>{content && content.top_5_metrics_details}</div>
                        <div className={`typewriter-entry top5-total-impact-style`}>{content && content.top_5_metrics_total_impact}</div>
                        {content && content.top_5_metrics.map((data, index) => (
                            <React.Fragment key={`top-metrics-${index}`}>
                                <div key={`${index}-tmetric`} className={`typewriter-entry metric-style`}>{index + 1}. {data.metric}</div>
                                <div key={`${index}-op`} className={`typewriter-entry`}>Opportunity: {data.opportunity}</div>
                                <div key={`${index}-recom`} className={`typewriter-entry recommendation-style`}>Recommendation: {data.recommendation}</div>
                            </React.Fragment>
                        ))}
                        <div className={`typewriter-entry summary-style`}>{content && content.top_5_summary}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const StaticMessage = ({ text }) => {
    return (
        <div className='message-container'>
            <div className='user-question-text-container'>
                <div className='user-question-text'>{text}</div>
            </div>
        </div>
    );
}

export { ChatTypewriter, StaticAnalysisMessage, StaticMessage };
